<template>
  <div class="order-base__warp">
    <div class="erp-form-bar bar-sticky">
      <div class="erp-primary__btn" @click="saveFormData">保存</div>
      <div class="erp-default__btn" @click="back">返回</div>
    </div>
    <!-- 55等分布局 -->
    <el-form :model="formData" ref="form" :rules="rules" class="erp-order__form">
      <el-row :gutter="80" justify="space-between" type="flex" class="op-16">
        <el-col :span="12">
          <div class="erp-form__title f16">订单信息</div>
          <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
            <el-col :span="12">
              <erp-view label="销售合同号" type="edit">
                <el-input v-model="formData.businessContractNo" :disabled="true"></el-input>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="客户" type="edit">
                <el-input v-model="formData.customerName" :disabled="true"></el-input>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="签约主体" type="edit">
                <!-- <el-input v-model="formData.contractPrincipalName"></el-input> -->
                <el-select v-model="contractPrincipal" value-key="contractPrincipalId" @change="getContractPrincipal" placeholder="请选择">
                  <el-option v-for="item in principalNameOption" :key="item.contractPrincipalId" :label="item.contractPrincipalName" :value="item"></el-option>
                </el-select>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="订单状态" type="edit">
                <el-select v-model="formData.status">
                  <el-option label="进行中" :value="1"></el-option>
                  <el-option label="已取消" :value="4"></el-option>
                </el-select>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="供应商合同号" type="edit">
                <el-input
                  clearable
                  v-if="!limtEdit"
                  v-model="formData.supplierContractNo"
                  :maxlength="25"
                  placeholder="请输入供应商合同号"
                  :disabled="limtEdit"
                ></el-input>
                <el-input :model-value="formData.supplierContractNo ? formData.supplierContractNo : '-'" :disabled="true" v-else></el-input>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <el-form-item label prop="invoiceNo">
                <erp-view label="发票号" type="edit">
                  <el-input v-if="!limtEdit" clearable placeholder="请输入发票号" v-model="formData.invoiceNo" maxlength="25" :disabled="limtEdit"></el-input>
                  <el-input :model-value="formData.invoiceNo ? formData.invoiceNo : '-'" :disabled="true" v-else></el-input>
                </erp-view>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <erp-view label="供应商" type="edit">
                <!-- <el-input v-model="formData.supplierId" :disabled="false"></el-input> -->
                <el-select
                  v-model="formData.supplierName"
                  filterable
                  remote
                  reserve-keyword
                  value-key="id"
                  placeholder="请输入"
                  :remote-method="querySupplier"
                  :loading="supplierLoading"
                  @change="getSupplier"
                >
                  <el-option v-for="item in supplierList" :key="item.id" :label="item.supplierName" :value="item" />
                </el-select>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="大类" type="edit">
                <el-input v-model="formData.categoryDisplay" :disabled="true"></el-input>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="国家" type="edit">
                <!-- <el-input v-model="formData.countryDisplay" :disabled="true"></el-input> -->
                <el-select v-model="formData.country" placeholder="请选择" :disabled="limtEdit" @change="countryChange">
                  <el-option v-for="item in countryList" :key="item.country" :label="item.countryName" :value="item.country"></el-option>
                </el-select>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="条款" type="edit">
                <!-- <el-input v-model="formData.incotermDisplay" :disabled="false"></el-input> -->
                <el-select v-model="formData.incoterm" @change="changeIcoterm" placeholder="请选择" clearable>
                  <el-option v-for="item in Options.Incoterm" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="保证金比例(%)" type="edit">
                <el-input
                  clearable
                  v-model="formData.depositRateDisplay"
                  @blur="changePaymentRatio($event)"
                  :maxlength="5"
                  placeholder="请输入保证金比例"
                  :disabled="!(hasPermission('erp:order:saveOrderQhdcBaseInfoAll') && !limtEdit)"
                ></el-input>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="口岸" type="edit">
                <el-input v-model="formData.portName" maxlength="10" :disabled="false"></el-input>
                <!-- <el-select
                  v-model="formData.portName"
                  filterable
                  remote
                  :remote-method="getPortList"
                  @blur="initPortList"
                  clearable
                  class="full-input"
                  @change="setChangeConsigneeList"
                  :disabled="!(hasPermission('erp:order:saveOrderQhdcBaseInfoAll') && formData.consigneeShortName == '' && !limtEdit)"
                  placeholder="请选择口岸"
                >
                  <el-option v-for="item in portNameList" :key="item" :value="item" :label="item"></el-option>
                </el-select> -->
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="资金方" type="edit">
                <!-- 需要判断是否有金融服务 -->
                <el-select
                  placeholder="请选择资金方"
                  v-model="fundingData"
                  value-key="id"
                  filterable
                  remote
                  clearable
                  :remote-method="getFudingList"
                  @blur="initFudingList"
                  @change="changeFudingList"
                  class="full-input"
                  @clear="clearFundingData"
                  v-if="formData.isNeedJr"
                  :disabled="!(hasPermission('erp:order:saveOrderQhdcBaseInfoAll') && !limtEdit)"
                >
                  <el-option v-for="item in fundingList" :key="item.id" :value="item" :label="item.shortName"></el-option>
                </el-select>
                <el-input :model-value="formData.fundingShortName ? formData.fundingShortName : '-'" :disabled="true" v-else></el-input>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="抬头" type="edit">
                <!-- 需要判断是否有金融服务 -->
                <el-select
                  placeholder="请选择抬头"
                  v-model="consigneeData"
                  filterable
                  remote
                  clearable
                  value-key="consigneeConcat"
                  :remote-method="getConsigneeList"
                  @blur="initConsigneeList"
                  class="full-input"
                  @clear="clearConsigneeData"
                  v-if="formData.isNeedJr"
                  :disabled="!(hasPermission('erp:order:saveOrderQhdcBaseInfoAll') && !limtEdit)"
                >
                  <el-option v-for="(item, index) in consigneeList" :key="index" :value="item" :label="item.consigneeShortName"></el-option>
                </el-select>
                <el-select
                  placeholder="请选择抬头"
                  v-model="formData.consigneeShortName"
                  filterable
                  remote
                  clearable
                  @clear="clearNoShortName"
                  :remote-method="getConsigneeList"
                  @blur="initConsigneeList"
                  class="full-input"
                  :disabled="!(hasPermission('erp:order:saveOrderQhdcBaseInfoAll') && !limtEdit)"
                  v-else
                >
                  <el-option v-for="item in consigneeList" :key="item.id" :value="item.shortName" :label="item.shortName"></el-option>
                </el-select>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="是否代采" type="edit">
                <!-- <el-input v-model="formData.isNeedCgDesc" :disabled="true"></el-input> -->
                <el-select v-model="formData.isNeedCg" placeholder="请选择">
                  <el-option label="是" :value="true"></el-option>
                  <el-option label="否" :value="false"></el-option>
                </el-select>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="柜型" type="edit">
                <!-- <el-input v-model="formData.containerTypeDisplay" :disabled="true"></el-input> -->
                <el-select v-model="formData.containerType" clearable placeholder="请选择">
                  <el-option v-for="item in Options.container_type" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </erp-view>
            </el-col>

            <el-col :span="12">
              <erp-view label="是否买保险" type="edit">
                <!-- <el-input v-model="formData.isNeedInsurance" :disabled="true"></el-input> -->
                <el-select v-model="formData.isNeedInsurance" :disabled="true" clearable placeholder="请选择">
                  <el-option label="是" :value="true"></el-option>
                  <el-option label="否" :value="false"></el-option>
                </el-select>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="付款方式" type="edit">
                <!-- <el-input v-model="formData.payTypeDesc" :disabled="true"></el-input> -->
                <el-select v-model="formData.payType" clearable placeholder="请选择">
                  <el-option :label="item.label" :value="item.value" v-for="(item, index) in Options.pay_type" :key="index"></el-option>
                </el-select>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="客户指定汇率" type="edit">
                <el-input v-model="formData.prescribedRate" :disabled="true"></el-input>
              </erp-view>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <div class="erp-form__title f16">物流信息</div>
          <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
            <el-col :span="12">
              <erp-view label="启运日" type="edit">
                <el-date-picker placeholder="请选择启运日" v-model="formData.shippingStartDate" style="width: 100%" prefix-icon="null"></el-date-picker>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="到港日" type="edit">
                <el-date-picker placeholder="请选择到港日" v-model="formData.shippingEndDate" style="width: 100%" prefix-icon="null"></el-date-picker>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="起运港" type="edit">
                <el-input clearable placeholder="请输入起运港" v-model="formData.portLoading" :maxlength="50" class="input-pdr-30"></el-input>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="投保日" type="edit">
                <el-date-picker placeholder="请选择投保日" v-model="formData.insureTime" style="width: 100%" prefix-icon="null"></el-date-picker>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="船公司" type="edit">
                <el-select
                  placeholder="请选择船公司"
                  v-model="company"
                  filterable
                  clearable
                  class="full-input"
                  value-key="id"
                  @change="getCompanyData"
                  v-if="companyLoad"
                >
                  <el-option v-for="item in companyList" :key="item.id" :label="item.shipName" :value="item"></el-option>
                </el-select>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="船名航次" type="edit">
                <el-input class="input-pdr-30" clearable v-model="formData.shippingInfo" :maxlength="45" placeholder="请输入船名航次"></el-input>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <el-form-item label prop="containerNo">
                <erp-view label="柜号" type="edit">
                  <el-input
                    clearable
                    placeholder="请输入柜号"
                    v-model="formData.containerNo"
                    :maxlength="11"
                    @blur="changeLadingBillNo($event, 'containerNo')"
                  ></el-input>
                </erp-view>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <erp-view label="提单号" type="edit">
                <el-input
                  clearable
                  placeholder="请输入提单号"
                  v-model="formData.ladingBillNo"
                  :maxlength="25"
                  @blur="changeLadingBillNo($event, 'ladingBillNo')"
                ></el-input>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="生产日期" type="edit">
                <el-input clearable v-model="formData.produceDate" :maxlength="15" placeholder="请输入生产日期"></el-input>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="代理" type="edit">
                <el-select placeholder="请选择代理" v-model="formData.agencyName" filterable clearable class="full-input">
                  <el-option v-for="item in agencyList" :key="item.id" :value="item.shortName" :label="item.shortName"></el-option>
                </el-select>
              </erp-view>
            </el-col>
            <el-col :span="24">
              <erp-view label="物流描述" type="edit">
                <el-input show-word-limit v-model="formData.logisticsDesc" type="textarea" :maxlength="500" placeholder="请输入物流描述"></el-input>
              </erp-view>
            </el-col>
          </el-row>
          <div class="erp-form__title f16" style="margin-top: 50px">负责人</div>
          <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
            <el-col :span="12">
              <erp-view label="单证跟单员" type="edit" v-if="hasPermission('erp:order:saveOrderQhdcBaseInfoAll')">
                <FormChooseMan
                  ref="relevant"
                  class="no-arrow"
                  :defaultCreated="false"
                  v-model="formData.userIdDocList"
                  v-model:name="formData.userNameDocMerged"
                  clearable
                  :selectAll="true"
                  :iserp="false"
                  :limt="10"
                  placeholder="请选择单证跟单员"
                >
                </FormChooseMan>
              </erp-view>
              <erp-view label="单证跟单员" type="edit" v-else>
                <el-input v-model="formData.userNameDocMerged" :disabled="true"></el-input>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="资管跟单员" type="edit">
                <el-input :model-value="formData.userNameFundMerged ? formData.userNameFundMerged : '-'" :disabled="true"></el-input>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="客户经理" type="edit" v-if="hasPermission('erp:order:saveOrderQhdcBaseInfoAll')">
                <FormChooseMan
                  ref="clientManager"
                  class="no-arrow"
                  :defaultCreated="false"
                  v-model="formData.userIdManagerList"
                  v-model:name="formData.clientManager"
                  @inputValue="getClientManager"
                  clearable
                  :selectAll="true"
                  :iserp="false"
                  :limt="5"
                  placeholder="请选择客户经理"
                >
                </FormChooseMan>
              </erp-view>
              <erp-view label="客户经理" type="edit" v-else>
                <el-input v-model="formData.clientManager" :disabled="true"></el-input>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="采购" type="edit">
                <el-input v-model="formData.purchase" :disabled="true"></el-input>
              </erp-view>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-form>
    <!--订单产品信息展示-->
    <div style="margin-top: 50px" class="order-title__bar op-16">
      <div class="erp-form__title f16">产品信息</div>
      <div class="erp-product__count">
        <span class="count-name">件数合计：</span>
        <span class="count-value" style="margin-right: 16px">{{ totalCount }}</span>
        <span class="count-name">实际净重合计(kg)：</span>
        <span class="count-value" style="margin-right: 16px">{{ totalWeight }}</span>
        <span class="count-name">实际货值合计：</span>
        <span class="count-value" style="margin-right: 16px">{{ totalAmount }}</span>
        <span class="count-name">产品数：</span>
        <span class="count-value">{{ _thousandBitSeparator(formData.productList.length) }}</span>
      </div>
    </div>
    <!-- 部分编辑权限 -->
    <el-table :data="formData.productList" border class="egrid erp-table__list erp-view__table">
      <el-table-column label="厂号" prop="plantNo" align="center" class-name="no-pd" show-overflow-tooltip minWidth="120">
        <template #default="scope">
          <el-select
            v-model="scope.row.plantNo"
            placeholder
            v-if="hasPermission('erp:order:saveOrderQhdcBaseInfo') && !limtEdit"
            @change="changePlantNo(scope.row)"
            clearable
          >
            <el-option v-for="(item, index) in plantNoArr" :key="index" :label="item" :value="item"></el-option>
          </el-select>
          <div v-else>{{ scope.row.plantNo }}</div>
        </template>
      </el-table-column>
      <el-table-column label="中文品名" prop="nameCn" align="center" class-name="no-pd table-visible" minWidth="200">
        <template #default="scope">
          <div v-if="scope.row.plantNo && scope.row.plantNo !== '' && hasPermission('erp:order:saveOrderQhdcBaseInfo') && !limtEdit">
            <el-autocomplete
              v-model="scope.row.nameCn"
              :fetch-suggestions="queryProductLabelCn"
              :popper-append-to-body="true"
              @select="handleName($event, scope.row)"
              @blur="nameFocus(scope.row)"
              @focus="getPlantNo(scope.row)"
              class="offer-name nameCn-list"
              placement="bottom"
              popper-class="nameCn-list"
              value-key="plantNoNameCn"
            >
              <template v-slot="{ item }">
                <el-tooltip :content="item.plantNoNameCn" :disabled="item.plantNoNameCn.length < 30">
                  <div class="name-tips">{{ item.plantNoNameCn }}</div>
                </el-tooltip>
              </template>
            </el-autocomplete>
            <span class="el-icon-circle-close name-close" @click="clearNameLabel(scope.row)" v-if="scope.row.labelId && scope.row.labelId !== ''"></span>
          </div>
          <div v-else>{{ filterName(scope.row.nameCn) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="英文品名" prop="nameEn" align="center" class-name="no-pd table-visible" minWidth="200">
        <template #default="scope">
          <div v-if="scope.row.plantNo && scope.row.plantNo !== '' && hasPermission('erp:order:saveOrderQhdcBaseInfo') && !limtEdit">
            <el-autocomplete
              v-model="scope.row.nameEn"
              :fetch-suggestions="queryProductLabelEn"
              :popper-append-to-body="true"
              :disabled="scope.row.labelId && scope.row.labelId !== ''"
              @select="handleName($event, scope.row)"
              @blur="nameFocus(scope.row)"
              @focus="getPlantNo(scope.row)"
              class="offer-name nameEn-list"
              value-key="plantNoNameEn"
              popper-class="nameEn-list"
            >
              <template v-slot="{ item }">
                <el-tooltip :content="item.plantNoNameEn" :disabled="item.plantNoNameEn.length < 30">
                  <div class="name-tips">{{ item.plantNoNameEn }}</div>
                </el-tooltip>
              </template>
            </el-autocomplete>
            <span class="el-icon-circle-close name-close" @click="clearNameLabel(scope.row)" v-if="scope.row.labelId && scope.row.labelId !== ''"></span>
          </div>
          <div v-else>{{ filterName(scope.row.nameCn) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="标签" prop="plantNo" align="center" class-name="no-pd" show-overflow-tooltip minWidth="78">
        <template #default="scope" class="img-center">
          <img
            src="../../../../../assets/images/img_icon_biaoqian_s.png"
            v-if="scope.row.labelId && scope.row.labelUrl && scope.row.labelUrl !== ''"
            @click="clickTag(scope.row.labelUrl)"
          />
          <img src="../../../../../assets/images/img_icon_biaoqian.png" alt v-if="scope.row.labelId && !scope.row.labelUrl" />
        </template>
      </el-table-column>
      <el-table-column label="品种" prop="varietyName" align="center" class-name="no-pd" show-overflow-tooltip minWidth="180">
        <template #default="scope">
          <!-- <div>{{ filterName(scope.row.varietyName) }}</div> -->
          <el-input v-model="scope.row.varietyName"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="单价" prop="unitPriceDisplay" align="center" class-name="no-pd" show-overflow-tooltip minWidth="100">
        <template #default="scope">
          <el-input v-model="scope.row.unitPrice" placeholder :maxlength="10" @blur="countPrice($event, scope, 'unitPrice')"></el-input>
          <!-- <el-input
            v-model="scope.row.unitPrice"
            placeholder
            :maxlength="10"
            @blur="countPrice($event, scope, 'unitPrice')"
            v-if="hasPermission('erp:order:saveOrderQhdcBaseInfo') && scope.row.contractWeight == null && !limtEdit"
          ></el-input>
          <div v-else>{{ filterName(scope.row.unitPrice) }}</div> -->
        </template>
      </el-table-column>
      <el-table-column label="合同重量(kg)" prop="contractWeightDisplay" class-name="no-pd" align="center" show-overflow-tooltip minWidth="130">
        <template #default="scope">
          <div>{{ filterName(scope.row.contractWeightDisplay) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="合同货值" prop="contractAmountDisplay" class-name="no-pd" align="center" show-overflow-tooltip minWidth="100">
        <template #default="scope">
          <div>{{ filterName(scope.row.contractAmountDisplay) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="交易币种" prop="tradeCurrencyDisplay" class-name="no-pd" align="center" show-overflow-tooltip minWidth="100">
        <template #default="scope">
          <div>{{ filterName(scope.row.tradeCurrencyDisplay) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="件数" class-name="no-pd" align="center" show-overflow-tooltip minWidth="100">
        <template #default="scope">
          <el-input v-model="scope.row.packageCount" placeholder :maxlength="4" @blur="changePackage($event, scope.row)" v-if="!limtEdit"></el-input>
          <div v-else>{{ filterName(scope.row.packageCountDisplay) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="实际净重(kg)" class-name="no-pd" align="center" show-overflow-tooltip minWidth="130">
        <template #default="scope">
          <el-input v-model="scope.row.actualWeight" placeholder :maxlength="10" v-if="!limtEdit" @blur="countPrice($event, scope, 'actualWeight')"></el-input>
          <div v-else>{{ filterName(scope.row.actualWeightDisplay) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="实际货值" prop="actualAmount" align="center" show-overflow-tooltip minWidth="140">
        <template #default="scope">
          <div>
            {{ showActualAmountDisplay(scope.row.unitPrice, scope.row.actualWeight) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="卫生证号" class-name="no-pd" align="center" show-overflow-tooltip minWidth="140">
        <template #default="scope">
          <el-input v-model="scope.row.healthCertNo" placeholder :maxlength="40" v-if="!limtEdit"></el-input>
          <div v-else>{{ filterName(scope.row.healthCertNo) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="电子信息" class-name="no-pd" align="center" show-overflow-tooltip minWidth="150">
        <template #default="scope">
          <DictSelect v-model="scope.row.elecInfoStatus" name="elec_info_status" v-if="!limtEdit"></DictSelect>
          <div v-else>{{ filterName(scope.row.elecInfoStatusDisplay) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="电子信息ok日期" class-name="no-pd" align="center" show-overflow-tooltip minWidth="140">
        <template #default="scope">
          <el-date-picker
            clearable
            v-if="!limtEdit"
            v-model="scope.row.digitalInfoDate"
            type="date"
            placeholder=""
            prefix-icon=" "
            style="width: 100%"
            @change="transDate(scope.row, $event)"
          >
          </el-date-picker>
          <div v-else>{{ filterName(scope.row.digitalInfoDate) }}</div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" width="48" class-name="no-pd" align="center" v-if="hasPermission('erp:order:saveOrderQhdcBaseInfo') && !limtEdit">
        <template v-slot:header>
          <span class="el-icon-circle-plus" style="font-size: 18px; position: relative; top: 2px" @click="addProduct"></span>
        </template>
        <template #default="scope">
          <span class="list-del" @click="deleteProduct(scope.$index)" v-if="!scope.row.contractWeight">
            <img src="../../../../../assets/images/list-del.png" alt />
          </span>
        </template>
      </el-table-column>
    </el-table>
    <!--申报要素  -->
    <div class="op-16">
      <div class="erp-form__title f16" style="margin-top: 30px">申报要素</div>
      <el-input
        v-model="formData.declarationKeyElements"
        type="textarea"
        :maxlength="800"
        placeholder="请输入申报要素"
        :autosize="{ minRows: 3 }"
        style="margin-top: 16px"
      ></el-input>
      <div class="erp-form__title f16" style="margin-top: 30px">订单备注</div>
      <el-input
        v-model="formData.remark"
        type="textarea"
        placeholder="请输入订单备注"
        :maxlength="2000"
        style="margin-top: 16px; margin-bottom: 30px"
        :autosize="{ minRows: 3 }"
      ></el-input>
    </div>
  </div>
</template>

<script>
import erpView from '@/components/ErpView/ErpView.vue';
import FormChooseMan from '@/components/ChooseMan/ChooseMan.vue';
import DictSelect from '@/components/DictSelect/DictSelect.vue';
import { errorTip, filterName, formatTime, hasPermission, _formatFloat, _thousandBitSeparator } from '@/utils/publicMethods';
import { httpPost, httpGet } from '@/api';
import { store } from '@/store';
export default {
  components: {
    erpView,
    FormChooseMan,
    DictSelect,
  },
  props: {
    limtEdit: [Boolean],
    data: {
      // 获取传递过来的详情
      type: [Object],
      default: function () {
        return {
          agencyName: null,
          businessContractNo: null,
          category: null,
          categoryDisplay: null,
          invoiceNo: null,
          portLoading: null,
          clearanceProgress: {
            description: null,
            id: 0,
            isSettled: false,
            isSettledDisplay: null,
            lastOperator: null,
            performDate: null,
            updateTime: null,
            version: 0,
          },
          consigneeShortName: null,
          containerNo: null,
          containerType: null,
          containerTypeDisplay: null,
          copyDocProgress: {
            description: null,
            id: 0,
            isSettled: false,
            isSettledDisplay: null,
            lastOperator: null,
            performDate: null,
            updateTime: null,
            version: 0,
          },
          country: null,
          countryDisplay: null,
          customerId: 0,
          customerName: null,
          contractPrincipalName: null,
          declarationKeyElements: null,
          declarationProgress: {
            description: null,
            id: 0,
            isSettled: false,
            isSettledDisplay: null,
            lastOperator: null,
            performDate: null,
            updateTime: null,
            version: 0,
          },
          depositRate: 0,
          depositRateDisplay: null,
          id: 0,
          incoterm: null,
          incotermDisplay: null,
          isNeedJr: '', // 是否提供供应链金融服务
          ladingBillNo: null,
          orderId: 0,
          orderVersion: 0,
          originalDocProgress: {
            description: null,
            id: 0,
            isSettled: false,
            isSettledDisplay: null,
            lastOperator: null,
            performDate: null,
            updateTime: null,
            version: 0,
          },
          portName: '',
          produceDate: null,
          productList: [
            {
              actualAmount: 0,
              actualAmountDisplay: null,
              actualWeight: 0,
              actualWeightDisplay: null,
              category: null,
              contractAmount: 0,
              contractAmountDisplay: null,
              contractWeight: 0,
              contractWeightDisplay: null,
              country: null,
              elecInfoStatus: null,
              elecInfoStatusDisplay: null,
              healthCertNo: null,
              labelId: 0,
              nameCn: null,
              nameEn: null,
              packageCount: 0,
              packageCountDisplay: null,
              plantNo: null,
              tradeCurrency: null,
              tradeCurrencyDisplay: null,
              unitPrice: 0,
              unitPriceDisplay: null,
              varietyName: null,
            },
          ],
          remark: null,
          returnBoxProgress: {
            description: null,
            id: 0,
            isSettled: false,
            isSettledDisplay: null,
            lastOperator: null,
            performDate: null,
            updateTime: null,
            version: 0,
          },
          shippingEndDate: null,
          shippingInfo: null,
          shippingStartDate: null,
          status: null,
          statusDisplay: null,
          supplierContractNo: null,
          supplierId: 0,
          supplierName: null,
          userIdDocList: [],
          userIdFundList: [],
          userIdMerged: null,
          userNameDocMerged: null,
          version: 0,
        };
      },
    },
  },
  data() {
    return {
      isAjaxLoading: false,
      rules: {
        containerNo: [
          {
            pattern: /([a-zA-Z]){4}(\d){7}/,
            message: '固定11个字符,4位英文+7位数字',
            trigger: 'blur',
          },
        ],
      },
      agencyList: [], // 代理接口列表
      totalWeight: 0, // 实际净重合计
      totalAmount: 0, // 实际货值
      totalCount: 0,
      formData: {},
      Options: {
        // 字典下拉数据初始化
        container_type: store.state.dict.options.container_type, //柜型
        pay_type: store.state.dict.options.pay_type, //付款方式
        Incoterm: store.state.dict.options.Incoterm, //条款
      },
      portNameList: null, // 口岸列表
      consigneeList: null,
      fundingList: null, // 资金方列表
      consigneeData: {
        // 抬头对象
        fundingQuotaId: null,
        consigneeShortName: null,
        consigneeConcat: null,
      },
      fundingData: {
        // 资金方对象
        id: null,
        shortName: null,
      },
      plantNoArr: [], // 下拉厂号条件
      productPlantNo: [], // 临时存储厂号
      companyList: [], // 船公司列表
      company: null,
      companyUrl: null,
      companyLoad: false,
      principalNameOption: [], //签约主体列表
      countryList: [], //国家列表
      supplierLoading: false, //供应商搜索状态
      supplierList: [], //供应商列表
      contractPrincipal: {}, //签约主体选中值
    };
  },
  methods: {
    // 获取厂号
    async getPlantNoList() {
      const res = await httpPost('/malicrm/approval/queryPlantNo', {
        category: this.formData.category,
        country: this.formData.country,
        isAddChina: true,
        plantNo: '',
      });
      if (res.code == 200) {
        this.plantNoArr = res.result;
        console.log('🚀 ~ file: BaseInfoForm.vue ~ line 738 ~ getPlantNoList ~ this.plantNoArr', this.plantNoArr);
      }
    },
    // 获取签约主体列表
    async getPrincipalName() {
      const res = await httpPost('/malicrm/customer/queryContractPrincipalByCustomerId', {
        category: this.formData.orderCategory,
        customerId: this.formData.customerId,
      });
      if (res.code === 200) {
        this.principalNameOption = res.result;
      }
    },
    // 签约主体改变
    getContractPrincipal(val) {
      console.log(val);
      console.log(this.contractPrincipal);
      this.formData.contractPrincipalId = val.contractPrincipalId;
      this.formData.contractPrincipalName = val.contractPrincipalName;
    },
    // 根据大类获取国家
    async getCountryList() {
      const res = await httpPost('/malicrm/approval/queryCountry', { category: 1 });
      if (res.code === 200) {
        console.log(res);
        this.countryList = res.result;
      }
    },
    // 国家改变   清空商品
    countryChange() {
      this.formData.productList = [];
      this.totalCount = 0;
      this.totalWeight = 0;
      this.totalAmount = 0;
      this.$forceUpdate();
      this.getPlantNoList();
    },
    // 条款改变  更改是否买保险的值
    changeIcoterm(value) {
      if (value === 3 || value === 2 || value === 4) {
        this.formData.isNeedInsurance = true;
        this.formData.isNeedInsuranceDesc = '是';
      } else {
        this.formData.isNeedInsurance = false;
        this.formData.isNeedInsuranceDesc = '否';
      }
    },
    // 供应商搜索
    async querySupplier(query) {
      if (query) {
        this.supplierLoading = true;
        const res = await httpPost('/malicrm/offer/querySupplierBySupplierName', { supplierName: query });
        if (res.code === 200) {
          this.supplierLoading = false;
          this.supplierList = res.result;
        } else {
          this.supplierList = [];
        }
      } else {
        this.supplierList = [];
      }
    },
    // 获取选中供应商
    getSupplier(val) {
      this.formData.supplierName = val.supplierName;
      this.formData.supplierId = val.id;
    },
    /** 转化时间用 */
    transDate(item, value) {
      item.digitalInfoDate = formatTime(value, 'YYYY-MM-DD');
    },
    filterName(val) {
      return filterName(val);
    },
    _thousandBitSeparator(str) {
      return _thousandBitSeparator(str);
    },
    hasPermission(bindPermissions) {
      return hasPermission(bindPermissions);
    },
    getCompanyData(value) {
      this.formData.company = value.id;
      this.companyUrl = value.url;
    },
    async getCompanyList() {
      const res = await httpPost('/malicrm/ship/shipList', {
        shipName: '',
        listType: 1,
        pageNum: 1,
        pageSize: 9999,
      });
      this.companyLoad = true;
      if (res.code === 200) {
        this.companyList = res.result.list;
      }
    },
    async getAgencyList() {
      // 获取代理列表
      const res = await httpPost('/malicrm/agency/queryAgencyByShortName', {
        shortName: '',
      });
      if (res.code === 200) {
        this.agencyList = res.result;
      }
    },
    showActualAmountDisplay(price, weight) {
      if (price === null || price === '' || weight === null || weight === '') {
        return null;
      } else {
        let total = Number(price) * Number(weight);
        total = _formatFloat(total, 2);
        return filterName(_thousandBitSeparator(total));
      }
    },
    changePackage(e, row) {
      let value = e.target.value;
      value = value.replace(/[^\d]/g, ''); // 清除“数字”和“.”以外的字符
      row.packageCount = value;
    },
    getAmount() {
      const productList = this.data.productList;
      const totalWeight = productList.reduce((total, item) => {
        let value = item.actualWeight;
        if (item.actualWeight === null || item.actualWeight === '') {
          value = 0;
        }
        return _formatFloat(total + Number(value), 3);
      }, 0);
      const totalAmount = productList.reduce((total, item) => {
        let value = Number(item.actualWeight) * Number(item.unitPrice);
        if (item.actualAmount === null || item.actualAmount === '' || item.unitPrice === null || item.unitPrice === '') {
          value = 0;
        }
        return _formatFloat(total + Number(value), 2);
      }, 0);

      const totalCount = productList.reduce((total, item) => {
        let value = item.packageCount;
        if (item.packageCount === null || item.packageCount === '') {
          value = 0;
        }
        return total + Number(value);
      }, 0);
      this.totalWeight = _thousandBitSeparator(totalWeight);
      this.totalAmount = _thousandBitSeparator(totalAmount);
      this.totalCount = _thousandBitSeparator(totalCount);
    },
    changePlantNo(item) {
      item.nameCn = null;
      item.nameEn = null;
      item.labelUrl = null;
      item.labelId = null;
      item.varietyName = null;
      if (!item.contractWeight) {
        item.unitPrice = null;
      }
      this.getAmount();
      // 其他是否清除待定
    },
    addProduct() {
      // 添加产品
      const data = {
        actualAmount: null,
        actualAmountDisplay: null,
        actualWeight: null,
        actualWeightDisplay: null,
        category: this.formData.category,
        country: this.formData.country,
        contractAmount: null,
        contractAmountDisplay: null,
        contractWeight: null,
        contractWeightDisplay: null,
        elecInfoStatus: null,
        elecInfoStatusDisplay: null,
        healthCertNo: null,
        labelId: null,
        labelUrl: null,
        nameCn: null,
        nameEn: null,
        packageCount: null,
        packageCountDisplay: null,
        plantNo: this.plantNoArr[0],
        tradeCurrency: this.data.tradeCurrency,
        tradeCurrencyDisplay: this.data.tradeCurrencyDisplay,
        unitPrice: null,
        unitPriceDisplay: null,
        varietyName: null,
      };
      this.formData.productList.push(data);
    },
    getPlantNo(row) {
      // 获取当前点击厂号信息
      this.productPlantNo = [row.plantNo];
    },
    async queryProductLabelCn(value, cb) {
      // 搜索中文品名
      const res = await httpPost('/malicrm/offer/queryOfferProductLabel', {
        nameCn: value,
        category: this.formData.category,
        country: this.formData.country,
        plantNo: this.productPlantNo,
      });
      if (res.code === 200) {
        if (res.result !== null) {
          cb(res.result);
        } else {
          const arr = [];
          cb(arr);
        }
      }
    },
    async queryProductLabelEn(value, cb) {
      // 搜索英文品名
      const res = await httpPost('/malicrm/offer/queryOfferProductLabel', {
        nameEn: value,
        category: this.formData.category,
        country: this.formData.country,
        plantNo: this.productPlantNo,
      });
      if (res.code === 200) {
        if (res.result !== null) {
          cb(res.result);
        } else {
          const arr = [];
          cb(arr);
        }
      }
    },
    handleName(item) {
      // 获取英文品名
      // eslint-disable-next-line prefer-rest-params
      const data = arguments[1];
      data.nameCn = item.nameCn;
      data.nameEn = item.nameEn;
      data.category = item.category;
      data.country = item.country;
      data.plantNo = item.plantNo;
      data.labelId = item.id;
      data.labelUrl = item.url;
      data.varietyName = item.varietyName;
    },
    nameFocus(item) {
      setTimeout(() => {
        if (!item.labelId) {
          item.nameCn = null;
          item.nameEn = null;
        } else {
          return false;
        }
      }, 200);
    },
    clearNameLabel(item) {
      item.labelUrl = null;
      item.labelId = null;
      item.nameCn = null;
      item.nameEn = null;
      item.varietyName = null;
    },

    clickTag(url) {
      // 查看标签
      if (url) {
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.click();
      }
    },
    deleteProduct(index) {
      this.formData.productList.splice(index, 1);
    },
    countPrice(e, scope, name) {
      // 自动计算价格
      let value = e.target.value;
      value = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      if (name === 'unitPrice') {
        value = value.replace(/^(-)*(\d+)\.(\d\d\d\d\d).*$/, '$1$2.$3'); // eslint-disable-line
        if (value >= 10000) {
          const index = value.indexOf('.');
          if (index > -1) {
            const intStr = value.substr(0, index - 1);
            const dotStr = value.substr(index);
            value = intStr + dotStr;
          } else {
            value = value.substr(0, 4);
          }
        }
      } else {
        value = value.replace(/^(-)*(\d+)\.(\d\d\d).*$/, '$1$2.$3'); // eslint-disable-line
      }

      if (value.indexOf('.') < 0 && value !== '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (value.substr(0, 1) === 0) {
          value = value.substr(1);
        }
      }
      if (value.lastIndexOf('.') === value.length - 1) {
        value = value.replace('.', '');
      }
      if (value >= 100000) {
        const index = value.indexOf('.');
        if (index > -1) {
          const intStr = value.substr(0, index - 1);
          const dotStr = value.substr(index);
          value = intStr + dotStr;
        } else {
          value = value.substr(0, 5);
        }
      }
      if (Number(value) === 0 && name === 'actualWeight') {
        value = null;
      }
      scope.row[name] = value;
      const price = scope.row.unitPrice;
      const actualWeight = scope.row.actualWeight;
      if (actualWeight === '') {
        scope.row.actualAmount = null;
        scope.row.actualAmountDisplay = null;
      } else {
        let actualAmount = Number(price) * Number(actualWeight);
        actualAmount = _formatFloat(actualAmount, 2);
        scope.row.actualAmount = actualAmount;
        scope.row.actualAmountDisplay = _thousandBitSeparator(actualAmount);
      }
      this.getAmount(); // 自动更新货值
    },
    getUserNamePrincipal(ids, labelName) {
      // 设置负责人
      this.formData.userIdDocList = ids;
      this.formData.userNameDocMerged = labelName;
    },

    getUserFundPrincipal(ids, labelName) {
      // 设置负责人
      this.formData.userIdFundList = ids;
      this.formData.userNameFundMerged = labelName;
    },
    getClientManager(ids, labelName) {
      // 获取客户经理
      this.formData.userIdManagerList = ids;
      this.formData.clientManager = labelName;
    },
    initPortList() {
      if ((this.portNameList && this.portNameList.length <= 0) || !this.portNameList) {
        this.getPortList('');
      }
    },
    async getPortList(name) {
      // 获取口岸列表
      const res = await httpPost('/malicrm/port/queryByName', {
        portName: name,
      });
      if (res.code === 200) {
        this.portNameList = res.result;
      }
    },
    changeFudingList(data) {
      if (data === '') {
        this.getFudingList('');
      }
      this.clearConsigneeData();
      this.formData.fundingShortName = data.shortName;
      this.formData.fundingId = data.id;
      this.formData.consigneeShortName = null;
      this.getConsigneeList('');
    },
    initFudingList() {
      if ((this.fundingList && this.fundingList.length <= 0) || !this.fundingList) {
        this.getFudingList('');
      }
    },
    async getFudingList(name) {
      // 获取资金方列表
      const res = await httpPost('/malicrm/funding/queryByShortName', {
        fundingShortName: name === null ? '' : name,
      });
      if (res.code === 200) {
        this.fundingList = res.result;
      }
    },
    initConsigneeList() {
      if ((this.consigneeList && this.consigneeList.length <= 0) || !this.consigneeList) {
        this.getConsigneeList('');
      }
    },
    async getConsigneeList(name) {
      // 获取抬头列表
      if (this.formData.isNeedJr) {
        // 有供应链金融
        if (this.formData.fundingId) {
          const res = await httpPost('/malicrm/business/queryConsigneeFroErp', {
            fundingId: this.formData.fundingId,
            name: name,
            portName: this.formData.portName,
          });
          if (res.code === 200) {
            this.consigneeList = res.result;
          }
        } else {
          this.consigneeList = [];
        }
      } else {
        const res = await httpPost('/malicrm/consignee/queryByName', {
          consigneeType: this.formData.consigneeType,
          portName: name,
        });
        if (res.code === 200) {
          this.consigneeList = res.result;
        }
      }
    },
    changePaymentRatio(e) {
      let value = e.target.value;
      value = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // eslint-disable-line
      if (value.indexOf('.') < 0 && value !== '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (value.substr(0, 1) === 0) {
          value = value.substr(1);
        }
      }
      if (value.lastIndexOf('.') === value.length - 1) {
        value = value.replace('.', '');
      }
      if (value >= 100) {
        const index = value.indexOf('.');
        if (index > -1) {
          const intStr = value.substr(0, index - 1);
          const dotStr = value.substr(index);
          value = intStr + dotStr;
        } else {
          value = value.substr(0, 2);
        }
      }
      this.formData.depositRate = value;
    },
    changeLadingBillNo(e, name) {
      let value = e.target.value;
      value = value.replace(/[^a-zA-Z\d]/g, ''); // 清除“数字以外的字符
      this.formData[name] = value;
    },
    validateProduct(data) {
      let status = true;
      for (let i = 0; i < data.length; i++) {
        if (!data[i].plantNo || !data[i].nameCn || !data[i].nameEn || !data[i].unitPrice) {
          status = false;
          break;
        }
      }
      return status;
    },
    clearNoShortName() {
      this.formData.consigneeShortName = '';
    },
    clearFundingData() {
      this.formData.fundingShortName = null;
      this.formData.fundingId = null;
      this.clearConsigneeData();
    },
    setChangeConsigneeList() {
      // 点击口岸获取 抬头信息
      this.getConsigneeList('');
    },
    clearConsigneeData() {
      this.formData.consigneeShortName = '';
      this.consigneeData = {
        fundingQuotaId: null,
        consigneeShortName: null,
        consigneeConcat: null,
      };
    },
    validForm() {
      const data = this.formData;
      let status = true;
      if (!(data.userIdDocList && data.userIdDocList.length > 0)) {
        status = false;
        errorTip('请选择单证跟单员');
      }
      if (!(data.userIdManagerList && data.userIdManagerList.length > 0)) {
        status = false;
        errorTip('请选择客户经理');
      }

      return status;
    },
    saveFormData() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.validForm()) {
            if (!this.formData.productList || !(this.formData.productList.length > 0)) {
              return errorTip('产品信息必须填写');
            } else if (this.validateProduct(this.formData.productList)) {
              const data = {
                agencyName: this.formData.agencyName,
                consigneeShortName: this.formData.isNeedJr ? this.consigneeData.consigneeShortName : this.formData.consigneeShortName,
                invoiceNo: this.formData.invoiceNo,
                portLoading: this.formData.portLoading,
                containerNo: this.formData.containerNo,
                declarationKeyElements: this.formData.declarationKeyElements,
                depositRate: this.formData.depositRate,
                logisticsDesc: this.formData.logisticsDesc,
                fundingQuotaId: this.consigneeData.fundingQuotaId,
                id: this.formData.id,
                ladingBillNo: this.formData.ladingBillNo,
                portName: this.formData.portName,
                produceDate: this.formData.produceDate,
                productList: this.formData.productList,
                remark: this.formData.remark,
                shippingEndDate: formatTime(this.formData.shippingEndDate, 'YYYY-MM-DD'),
                shippingInfo: this.formData.shippingInfo,
                shippingStartDate: formatTime(this.formData.shippingStartDate, 'YYYY-MM-DD'),
                supplierContractNo: this.formData.supplierContractNo,
                userIdDocList: this.formData.userIdDocList,
                userIdFundList: this.formData.userIdFundList,
                isNeedJr: this.formData.isNeedJr,
                status: this.formData.status,
                version: this.formData.version,
                userIdManagerList: this.formData.userIdManagerList,
                company: this.formData.company,
                insureTime: formatTime(this.formData.insureTime, 'YYYY-MM-DD'),
                contractPrincipalId: this.formData.contractPrincipalId,
                contractPrincipalName: this.formData.contractPrincipalName,
                country: this.formData.country,
                isNeedCg: this.formData.isNeedCg,
                incoterm: this.formData.incoterm,
                containerType: this.formData.containerType,
                payType: this.formData.payType,
                supplierName: this.formData.supplierName,
                isNeedInsurance: this.formData.isNeedInsurance,
                supplierId: this.formData.supplierId,
              };
              if (data.isNeedJr === true) {
                if (data.fundingQuotaId === '' || data.fundingQuotaId === null || !data.portName) {
                  errorTip('资金方、抬头简称、口岸必须同时填写');
                  return false;
                }
              } else {
                if (!data.portName || !data.consigneeShortName) {
                  errorTip('抬头口岸必须同时填写');
                  return false;
                }
              }
              if (!this.isAjaxLoading) {
                this.isAjaxLoading = true;
                httpPost('/malicrm/order/saveOrderQhdcBaseInfo', data).then((res) => {
                  if (res.code === 200) {
                    this.$emit('back', 1, true);
                    this.isAjaxLoading = false;
                  } else {
                    this.isAjaxLoading = false;
                    errorTip(res.message);
                  }
                });
              }
            } else {
              errorTip('产品的厂号,中英文品名,单价,必须填写');
            }
          } else {
            return false;
          }
        }
      });
    },
    editBaseInfo() {
      // 通知tab页切换显示 编辑页面
      this.$emit('edit', 1);
    },
    back() {
      // 返回详情页
      this.$emit('back', 1);
    },
  },
  created() {
    if (this.data.company) {
      this.company = {
        id: this.data.company.toString(),
        shipName: this.companyDesc,
      };
    }
    this.formData = Object.assign({}, this.data);

    // 赋值   供应商选中显示
    this.contractPrincipal = {
      contractPrincipalId: this.formData.contractPrincipalId,
      contractPrincipalName: this.formData.contractPrincipalName,
    };
    this.companyUrl = this.data.url;
    this.getAmount();
    // this.plantNoArr = this.formData.plantNo.split('、');
    this.fundingData = {
      id: this.data.fundingId,
      shortName: this.data.shortName,
    };
    this.consigneeData = {
      fundingQuotaId: this.data.fundingQuotaId,
      consigneeShortName: this.data.consigneeShortName,
      consigneeConcat: this.data.consigneeConcat,
    };
    this.getCompanyList(); // 获取船公司信息
    this.getAgencyList();
    this.getPortList('');
    this.getConsigneeList('');
    this.getFudingList(''); // 通过名字获取资金方
    this.getPlantNoList();
    this.getPrincipalName();
    this.getCountryList();
  },
};
</script>

<style lang="scss" scoped>
.order-title__bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 11px;
}
.count-value {
  font-size: 14px;
  color: #303133;
}
.count-name {
  font-size: 14px;
  color: #606266;
}
.name-close {
  position: absolute;
  cursor: pointer;
  right: 16px;
  top: 13px;
  color: #c0c4cc;
}
</style>
