<template>
  <div class="order-base__warp">
    <div class="erp-form-bar bar-sticky">
      <div class="erp-primary__btn" @click="editBaseInfo" v-if="(data.status == 1 || data.status === 4) && hasPermission('erp:order:saveOrderQhdcBaseInfoAll')">
        编辑
      </div>
      <div
        class="erp-primary__btn"
        @click="viewEditLog"
        v-if="
          data.settleTypeVo.settleLogList &&
          data.settleTypeVo.settleLogList.length > 0 &&
          (data.settleTypeVo.settleType === 1 || data.settleTypeVo.settleType === 3)
        "
      >
        查看修改记录
      </div>
      <div class="erp-primary__btn plain" @click="finishOrder" v-if="data.status == 1 && hasPermission('erp:order:closeOpenOrderQhdc')">完结订单</div>
      <div class="erp-primary__btn plain" @click="closeOrder(1)" v-if="data.status == 4 && hasPermission('erp:order:closeOpenOrderQhdc')">关闭订单</div>
      <div
        class="erp-primary__btn plain"
        @click="reStoreOrder(data.status)"
        v-if="(data.status == 2 || data.status == 3) && hasPermission('erp:order:closeOpenOrderQhdc')"
      >
        还原
      </div>
      <div class="erp-default__btn" @click="back">返回</div>
    </div>
    <!-- 计费方式 -->
    <div class="billing-method__title">
      <img src="../../../../../assets/images/icon-tips.png" alt />
      <div class="billing-method__name">计费方式：{{ detailData.settleTypeDesc }}</div>
    </div>
    <!-- 按天计费数据展示 -->
    <billing-type type="view" :billData="detailData" v-if="detailData.settleType === 1 || detailData.settleType === 3"></billing-type>
    <div v-else style="padding-left: 16px">
      <div class="erp-form__title f16">计费信息</div>
      <el-table :data="lumpDtos" border class="egrid erp-table__list erp-view__table margin__top" style="margin-top: 11px; width: 782px">
        <el-table-column label="自支付尾款之日起至甲方付款提货之日止" prop="configMinDate" align="center">
          <template v-slot="scope">
            {{ scope.row.label }}
          </template>
        </el-table-column>
        <el-table-column label="服务费(元/公斤)" prop="feeDesc" align="center">
          <template v-slot="scope">
            {{ filterName(scope.row.fee) }}
          </template>
        </el-table-column>
      </el-table>
      <template v-if="detailData.bagOtherFeeList && detailData.bagOtherFeeList.length > 0">
        <div class="erp-form__title f16" style="margin-top: 30px">其他费用 (包干价）</div>
        <el-table class="egrid erp-table__list erp-view__table margin__top" style="margin-top: 11px; width: 782px" :data="detailData.bagOtherFeeList" border>
          <el-table-column label="费用名称" align="center">
            <!-- <div>操作费<span class="feeNameTip">（垫资的情况下才收取此费用）</span></div> -->
            <template v-slot="scope">
              <span>{{ filterName(scope.row.feeName) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="收费标准" prop="feeStandard" align="center">
            <template v-slot="scope">
              <span>{{ filterName(scope.row.feeStandard) }}元/柜</span>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <!-- 侧边栏弹窗展示新增、编辑 -->
    <erp-drawer :title="dialog.title" :visible="dialog.visible" @close="closeDialog" erp class="fix-top-header">
      <billWayEditLog v-if="dialog.visible" :settleLogList="detailData.settleLogList" @cancel="cancel" :id="data.id"></billWayEditLog>
    </erp-drawer>
    <InputDialog ref="inputDialog" width="360px"></InputDialog>
  </div>
</template>

<script>
import { httpGet, httpPost } from '@/api';
import billingType from './billingType';
import billWayEditLog from './billWayEditLog.vue';
import InputDialog from '@/components/InputDialog/InputDialog.vue';

import { erpConfirm, errorTip, getRouterParams, successTip } from '@/utils/publicMethods';
export default {
  components: {
    billingType,
    billWayEditLog,
    InputDialog,
  },
  props: {
    data: {
      // 获取传递过来的详情
      type: [Object],
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      detailData: {},
      lumpDtos: [],
      dialog: {
        title: '',
        visible: false,
      },
    };
  },
  created() {
    console.log(this.data);
    this.detailData = this.data.settleTypeVo;
    const lumpVos = [
      {
        label: '1-30天',
        configMin: 1,
        configMax: 30,
        fee: null,
        contractId: null,
        orderId: null,
      },
      {
        label: '1-45天',
        configMin: 31,
        configMax: 45,
        fee: null,
        contractId: null,
        orderId: null,
      },
      {
        label: '1-60天',
        configMin: 46,
        configMax: 60,
        fee: null,
        contractId: null,
        orderId: null,
      },
      {
        label: '1-75天',
        configMin: 61,
        configMax: 75,
        fee: null,
        contractId: null,
        orderId: null,
      },
      {
        label: '1-90天',
        configMin: 76,
        configMax: 90,
        fee: null,
        contractId: null,
        orderId: null,
      },
    ];
    if (this.detailData.lumpVos) {
      this.detailData.lumpVos.forEach((v) => {
        if (v.configMin >= 1 && v.configMax <= 30) {
          lumpVos[0].fee = v.fee;
        }
        if (v.configMin >= 31 && v.configMax <= 45) {
          lumpVos[1].fee = v.fee;
        }
        if (v.configMin >= 46 && v.configMax <= 60) {
          lumpVos[2].fee = v.fee;
        }
        if (v.configMin >= 61 && v.configMax <= 75) {
          lumpVos[3].fee = v.fee;
        }
        if (v.configMin >= 76 && v.configMax <= 90) {
          lumpVos[4].fee = v.fee;
        }
      });
    }
    this.lumpDtos = lumpVos;
  },
  methods: {
    cancel() {
      this.dialog.visible = false;
    },
    closeDialog() {
      // 返回
      this.dialog.visible = false;
    },
    // 去修改记录页
    viewEditLog() {
      this.dialog.visible = true;
    },
    finishOrder() {
      this.$refs.inputDialog.init('提示', '确定需要完结当前订单吗？完结后的订单不可再做修改。').then(async (val) => {
        const res = await httpPost('/malicrm/order/finishOrder', {
          orderId: this.data.orderId,
          finishDate: val,
        });
        if (res.code === 200) {
          this.$emit('back', 1);
          successTip('已完结订单');
        } else {
          errorTip(res.message);
        }
      });
    },
    reOpenOrder() {
      erpConfirm('确定要恢复当前订单状态为进行中吗？').then(async () => {
        const res = await httpPost('/malicrm/order/reOpenOrder', {
          orderId: this.data.orderId,
        });
        if (res.code === 200) {
          this.$emit('back', 1);
          successTip('已恢复订单');
        } else {
          errorTip(res.message);
        }
      });
    },
    reStoreOrder(status) {
      // 还原判断
      erpConfirm(status === 2 ? '确定要将当前订单还原为“已取消”的状态吗？' : '确定要将当前订单还原为“进行中”的状态吗？')
        .then(async () => {
          const url = status === 2 ? '/malicrm/order/closeOpenOrderQhdc' : '/malicrm/order/reOpenOrder';
          const data = {
            id: this.data.id,
            operType: status,
            orderId: this.data.orderId,
            orderVersion: this.data.orderVersion,
            version: this.data.version,
          };
          const res = await httpPost(url, data);
          if (res.code === 200) {
            successTip('已还原订单');
            this.$emit('back', 1);
          } else {
            errorTip(res.message);
          }
        })
        .catch(() => {
          return false;
        });
    },
    closeOrder(status) {
      // 关闭还原订单
      erpConfirm('确定需要关闭当前订单吗？关闭后的订单不可再做修改。')
        .then(async () => {
          const res = await httpPost('/malicrm/order/closeOpenOrderQhdc', {
            id: this.data.id,
            operType: status,
            orderId: this.data.orderId,
            orderVersion: this.data.orderVersion,
            version: this.data.version,
          });
          if (res.code === 200) {
            successTip('已关闭订单');
            this.$emit('back', 1);
          } else {
            errorTip(res.message);
          }
        })
        .catch(() => {
          return false;
        });
    },
    back() {
      const { orderId, name, version } = getRouterParams('OrderDetail');
      this.$router.push({
        name: name,
        params: {
          id: orderId,
          version: version,
        },
      });
    },
    async editBaseInfo() {
      const res = await httpGet(`/malicrm/order/checkOrderHasValidStmt/${this.data.orderId}`);
      if (res.code === 200) {
        // 通知tab页切换显示 编辑页面
        this.$emit('edit', 6);
      } else {
        this.errorTip(res.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.order-title__bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 11px;
}
.count-value {
  font-size: 14px;
  color: #303133;
}
.count-name {
  font-size: 14px;
  color: #606266;
}
</style>
