<template>
  <div>
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">查看修改记录</div>
      <div class="erp-form-bar">
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <el-table
      :data="settleLogList"
      border
      class="egrid erp-table__list erp-view__table"
    >
      <el-table-column
        label="销售合同号"
        prop="businessContractNo"
        class-name="no-pd"
        align="center"
        show-overflow-tooltip
        width="210"
      >
        <template v-slot="scope">
          {{ filterName(scope.row.businessContractNo) }}
        </template>
      </el-table-column>
      <el-table-column
        label="修改项目"
        prop="changeTitle"
        align="center"
        class-name="no-pd"
        show-overflow-tooltip
        width="120"
      >
        <template v-slot="scope">
          {{ filterName(scope.row.changeTitle) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="changeDetail"
        label="项目明细"
        class-name="no-pd"
        align="center"
        width="140"
        show-overflow-tooltip
      >
        <template v-slot="scope">
          {{ filterName(scope.row.changeDetail) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="changeBefore"
        label="修改前"
        class-name="no-pd"
        align="center"
        width="100"
        show-overflow-tooltip
      >
        <template v-slot="scope">
          <div>
            {{ filterName(scope.row.changeBefore) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="changeAfter"
        label="修改后"
        class-name="no-pd"
        align="center"
        width="100"
        show-overflow-tooltip
      >
        <template v-slot="scope">
          <div>
            {{ filterName(scope.row.changeAfter) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="creator"
        label="创建人"
        class-name="no-pd"
        align="center"
        width="100"
        show-overflow-tooltip
      >
        <template v-slot="scope">
          {{ filterName(scope.row.creator) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="createdTime"
        label="创建时间"
        class-name="no-pd"
        align="center"
        show-overflow-tooltip
      >
        <template v-slot="scope">
          {{ filterName(scope.row.createdTime) }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  props: {
    settleLogList: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {};
  },
  methods: {
    back() {
      // 返回操作
      this.$emit('cancel', false);
    },
  },
};
</script>
<style lang="scss" scoped>
.mt20 {
  margin-top: 20px;
}
</style>
