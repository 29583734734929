<template>
  <div class="order-base__warp">
    <div class="erp-form-bar bar-sticky">
      <div class="erp-primary__btn" @click="editBaseInfo">保存</div>
      <div class="erp-default__btn" @click="back">返回</div>
    </div>
    <!-- 55等分布局 -->
    <el-form :model="formData" ref="form" :rules="rules" class="erp-order__form">
      <el-row :gutter="80" type="flex" class="op-16">
        <el-col :span="12">
          <div class="erp-form__title f16">订单信息</div>
          <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
            <el-col :span="12">
              <erp-view label="供应商合同号" type="edit">
                <el-input :model-value="!copydata.supplierContractNo ? '-' : copydata.supplierContractNo" :disabled="true"> </el-input>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="客户" type="edit">
                <el-input v-model="copydata.customerName" :disabled="true"></el-input>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="抬头" type="edit">
                <el-input :model-value="!copydata.consigneeShortName ? '-' : copydata.consigneeShortName" :disabled="true"> </el-input>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="到港日" type="edit">
                <el-input :model-value="!copydata.shippingEndDate ? '-' : copydata.shippingEndDate" :disabled="true"></el-input>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="柜号" type="edit">
                <el-input :model-value="!copydata.containerNo ? '-' : copydata.containerNo" :disabled="true"></el-input>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="提单号" type="edit">
                <el-input :model-value="!copydata.ladingBillNo ? '-' : copydata.ladingBillNo" :disabled="true"></el-input>
              </erp-view>
            </el-col>
          </el-row>
          <div class="erp-form__title f16" style="margin-top: 50px">正本文件</div>
          <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
            <el-col :span="12">
              <erp-view label="日期" type="edit">
                <el-date-picker
                  clearable
                  v-model="formData.originalDocProgress.performDate"
                  style="width: 100%"
                  placeholder="请选择日期"
                  prefix-icon="null"
                  @change="changeDate(formData.originalDocProgress, 'performDate', $event)"
                >
                </el-date-picker>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="状态" type="edit">
                <el-select placeholder="请选择状态" clearable v-model="formData.originalDocProgress.isSettled" class="full-input">
                  <el-option :value="null" label="请选择状态" v-if="!formData.originalDocProgress.id"></el-option>
                  <el-option
                    v-for="item in filterOption.origin_progress_type_status"
                    :key="item.label"
                    :value="item.value"
                    :label="item.label"
                    :disabled="item.value == 0 || item.value == 1"
                  ></el-option>
                </el-select>
              </erp-view>
            </el-col>
            <!-- 默认值为“正本提单” 放货方式 -->
            <el-col :span="12">
              <erp-view label="放货方式" type="edit">
                <DictSelect
                  placeholder="请选择放货方式"
                  clearable
                  v-model="formData.originalDocProgress.releaseType"
                  name="order_qhdc_release_type"
                ></DictSelect>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="快递公司" type="edit">
                <el-select
                  placeholder="请选择快递公司"
                  v-model="formData.originalDocProgress.trackingCompanyCode"
                  clearable
                  filterable
                  remote
                  @change="expressCompanyChange($event, 'zhengBen')"
                  :remote-method="
                    (query) => {
                      getCompanyOption(query, 'zhengBen');
                    }
                  "
                  @focus="focusExpress('zhengBen')"
                  @clear="clearExpress('zhengBen')"
                  class="full-input pdl-30"
                >
                  <el-option v-for="item in zhengBenOption" :key="item.companyCode" :value="item.companyCode" :label="item.companyName"></el-option>
                </el-select>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="正本快递单号" type="edit">
                <el-input
                  class="input-pdr-30"
                  placeholder="请输入正本快递单号"
                  clearable
                  v-model="formData.originalDocProgress.trackingNumber"
                  :maxlength="30"
                ></el-input>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <el-form-item label prop="originalDocProgress.trackingPhone">
                <erp-view label="寄件人/收件人手机号后四位" type="edit">
                  <el-input v-if="disablePhone(formData.originalDocProgress.trackingCompanyName)" :disabled="true" clearable value="-"></el-input>
                  <el-input
                    v-else
                    placeholder="请输入寄件人/收件人手机号后四位"
                    clearable
                    v-model="formData.originalDocProgress.trackingPhone"
                    :maxlength="4"
                  ></el-input>
                </erp-view>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <erp-view label="描述" type="edit">
                <el-input
                  maxlength="500"
                  :autosize="{ minRows: 3 }"
                  v-model="formData.originalDocProgress.description"
                  type="textarea"
                  placeholder="请输入描述"
                ></el-input>
              </erp-view>
            </el-col>
          </el-row>

          <!-- 新增提箱中转 -->
          <div class="erp-form__title f16" style="margin-top: 50px">提箱中转</div>
          <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
            <el-col :span="12">
              <erp-view label="提箱日期" type="edit">
                <el-date-picker
                  clearable
                  v-model="formData.packingContainerInfo.packingContainerDate"
                  style="width: 100%"
                  placeholder="请选择提箱日期"
                  prefix-icon="null"
                  @change="changeDate(formData.packingContainerInfo, 'packingContainerDate', $event)"
                ></el-date-picker>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="消杀日期" type="edit">
                <el-date-picker
                  clearable
                  v-model="formData.packingContainerInfo.xsDate"
                  style="width: 100%"
                  placeholder="请选择消杀日期"
                  prefix-icon="null"
                  @change="changeDate(formData.packingContainerInfo, 'xsDate', $event)"
                ></el-date-picker>
              </erp-view>
            </el-col>

            <el-col :span="24">
              <erp-view label="提箱描述" type="edit">
                <el-input
                  clearable
                  maxlength="200"
                  v-model="formData.packingContainerInfo.packingContainerDescription"
                  type="textarea"
                  placeholder="请输入提箱描述"
                  :autosize="{ minRows: 3 }"
                ></el-input>
              </erp-view>
            </el-col>

            <el-col :span="12">
              <erp-view label="中转日期" type="edit">
                <el-date-picker
                  clearable
                  v-model="formData.packingContainerInfo.transferDate"
                  style="width: 100%"
                  placeholder="请选择提箱日期"
                  prefix-icon="null"
                  @change="changeDate(formData.packingContainerInfo, 'transferDate', $event)"
                ></el-date-picker>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="中转库" type="edit">
                <el-input
                  clearable
                  v-model="formData.packingContainerInfo.transferStorage"
                  :maxlength="40"
                  style="width: 100%"
                  placeholder="请输入中转库"
                ></el-input>
              </erp-view>
            </el-col>

            <el-col :span="24">
              <erp-view label="中转描述" type="edit">
                <el-input
                  clearable
                  maxlength="200"
                  v-model="formData.packingContainerInfo.transferDescription"
                  type="textarea"
                  placeholder="请输入中转描述"
                  :autosize="{ minRows: 3 }"
                ></el-input>
              </erp-view>
            </el-col>
          </el-row>

          <div class="erp-form__title f16" style="margin-top: 50px">送货</div>
          <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
            <el-col :span="12">
              <erp-view label="送货日期" type="edit">
                <el-input :model-value="!copydata.clearanceProgress.deliverDate ? '-' : copydata.clearanceProgress.deliverDate" :disabled="true"></el-input>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="送货方式" type="edit">
                <el-input
                  :model-value="!copydata.clearanceProgress.deliverModeDisplay ? '-' : copydata.clearanceProgress.deliverModeDisplay"
                  :disabled="true"
                ></el-input>
              </erp-view>
            </el-col>
            <el-col :span="24">
              <erp-view label="送货问题" type="edit">
                <el-input :model-value="!copydata.clearanceProgress.deliverIssue ? '-' : copydata.clearanceProgress.deliverIssue" :disabled="true"></el-input>
              </erp-view>
            </el-col>
          </el-row>

          <div style="margin-bottom: 30px" v-if="formData.isCustomerGoodsPayment">
            <div class="erp-form__title f16" style="margin-top: 50px">贸易商付工厂货款</div>
            <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
              <el-col :span="12">
                <erp-view label="贸易商付预付款日期" type="edit">
                  <el-date-picker
                    clearable
                    v-model="formData.foreignRatioProgressInfo.performDate"
                    style="width: 100%"
                    @change="changeDate(formData.foreignRatioProgressInfo, 'performDate', $event)"
                    placeholder="请选择贸易商付预付款日期"
                    prefix-icon="null"
                  ></el-date-picker>
                </erp-view>
              </el-col>
              <el-col :span="12">
                <erp-view label="贸易商付尾款日期" type="edit">
                  <el-date-picker
                    clearable
                    v-model="formData.foreignTailProgressInfo.performDate"
                    style="width: 100%"
                    placeholder="请选择贸易商付尾款日期"
                    @change="changeDate(formData.foreignTailProgressInfo, 'performDate', $event)"
                    prefix-icon="null"
                  ></el-date-picker>
                </erp-view>
              </el-col>
              <el-col :span="12">
                <erp-view label="预付款状态" type="edit">
                  <el-select clearable placeholder="请选择" v-model="formData.foreignRatioProgressInfo.isSettled" class="full-input">
                    <el-option :value="0" label="待支付"></el-option>
                    <el-option :value="1" label="已支付"></el-option>
                  </el-select>
                </erp-view>
              </el-col>
              <el-col :span="12">
                <erp-view label="尾款状态" type="edit">
                  <el-select clearable v-model="formData.foreignTailProgressInfo.isSettled" class="full-input" placeholder="请选择">
                    <el-option :value="0" label="待支付"></el-option>
                    <el-option :value="1" label="已支付"></el-option>
                  </el-select>
                </erp-view>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="erp-form__title f16">副本文件</div>
          <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
            <el-col :span="12">
              <erp-view label="日期" type="edit">
                <el-date-picker
                  clearable
                  v-model="formData.copyDocProgress.performDate"
                  style="width: 100%"
                  placeholder="请选择日期"
                  @change="changeDate(formData.copyDocProgress, 'performDate', $event)"
                  prefix-icon="null"
                >
                </el-date-picker>
              </erp-view>
            </el-col>

            <el-col :span="12">
              <erp-view label="状态" type="edit">
                <el-select placeholder="请选择状态" clearable v-model="formData.copyDocProgress.isSettled" class="full-input">
                  <el-option :value="null" label="请选择" v-if="!formData.copyDocProgress.id"></el-option>
                  <el-option
                    v-for="item in filterOption.copy_progress_type_status"
                    :key="item.label"
                    :value="item.value"
                    :label="item.label"
                    :disabled="item.value == 0 || item.value == 1"
                  ></el-option>
                </el-select>
              </erp-view>
            </el-col>

            <el-col :span="24">
              <erp-view label="描述" type="edit">
                <el-input
                  clearable
                  maxlength="500"
                  :autosize="{ minRows: 3 }"
                  v-model="formData.copyDocProgress.description"
                  type="textarea"
                  placeholder="请输入描述"
                ></el-input>
              </erp-view>
            </el-col>
          </el-row>
          <div class="erp-form__title f16" style="margin-top: 50px">交报关行</div>
          <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
            <el-col :span="12">
              <erp-view label="日期" type="edit">
                <el-date-picker
                  clearable
                  v-model="formData.declarationProgress.performDate"
                  style="width: 100%"
                  placeholder="请选择日期"
                  prefix-icon="null"
                  @change="changeDate(formData.declarationProgress, 'performDate', $event)"
                ></el-date-picker>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="状态" type="edit">
                <el-select placeholder="请选择状态" clearable v-model="formData.declarationProgress.isSettled" class="full-input">
                  <el-option :value="null" label="请选择" v-if="!formData.declarationProgress.id"></el-option>
                  <el-option
                    v-for="item in filterOption.clearance_progress_type_status"
                    :key="item.label"
                    :value="item.value"
                    :label="item.label"
                    :disabled="item.value == 0 || item.value == 1"
                  ></el-option>
                </el-select>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="快递公司" type="edit">
                <el-select
                  v-model="formData.declarationProgress.trackingCompanyCode"
                  clearable
                  filterable
                  remote
                  @change="expressCompanyChange($event, 'jiaoBaoGuan')"
                  :remote-method="
                    (query) => {
                      getCompanyOption(query, 'jiaoBaoGuan');
                    }
                  "
                  @focus="focusExpress('jiaoBaoGuan')"
                  @clear="clearExpress('jiaoBaoGuan')"
                  placeholder="请选择快递公司"
                  class="full-input pdl-30"
                >
                  <el-option v-for="item in jiaoBaoGuanOption" :key="item.companyCode" :value="item.companyCode" :label="item.companyName"></el-option>
                </el-select>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="报关资料快递单号" type="edit">
                <el-input clearable class="input-pdr-30" v-model="formData.declarationProgress.trackingNumber" :maxlength="30"></el-input>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <el-form-item label prop="declarationProgress.trackingPhone">
                <erp-view label="寄件人/收件人手机号后四位" type="edit">
                  <el-input v-if="disablePhone(formData.declarationProgress.trackingCompanyName, 'jiaoBaoGuan')" :disabled="true" value="-"></el-input>
                  <el-input
                    v-else
                    placeholder="请输入寄件人/收件人手机号后四位"
                    clearable
                    v-model="formData.declarationProgress.trackingPhone"
                    :maxlength="4"
                  ></el-input>
                </erp-view>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <erp-view label="描述" type="edit">
                <el-input
                  clearable
                  maxlength="500"
                  :autosize="{ minRows: 3 }"
                  v-model="formData.declarationProgress.description"
                  placeholder="请输入描述"
                  type="textarea"
                ></el-input>
              </erp-view>
            </el-col>
          </el-row>

          <div class="erp-form__title f16" style="margin-top: 50px">还箱信息</div>
          <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
            <el-col :span="12">
              <erp-view label="还箱日期" type="edit">
                <el-date-picker
                  clearable
                  v-model="formData.returnBoxProgressInfo.performDate"
                  style="width: 100%"
                  placeholder="请选择还箱日期"
                  prefix-icon="null"
                  @change="changeDate(formData.returnBoxProgressInfo, 'performDate', $event)"
                ></el-date-picker>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="免箱期" type="edit">
                <el-input clearable maxlength="10" v-model="formData.returnBoxProgressInfo.boxFreePeriod" placeholder="请输入免箱期"> </el-input>
              </erp-view>
            </el-col>
            <el-col :span="24">
              <erp-view label="还箱地址" type="edit">
                <el-input
                  class="input-pdr-30"
                  clearable
                  maxlength="100"
                  v-model="formData.returnBoxProgressInfo.boxRetunAddress"
                  placeholder="请输入还箱地址"
                  type="input"
                ></el-input>
              </erp-view>
            </el-col>
            <el-col :span="24">
              <erp-view label="描述" type="edit">
                <el-input
                  clearable
                  maxlength="500"
                  v-model="formData.returnBoxProgressInfo.description"
                  type="textarea"
                  placeholder="请输入描述"
                  :autosize="{ minRows: 3 }"
                ></el-input>
              </erp-view>
            </el-col>
          </el-row>

          <div class="erp-form__title f16" style="margin-top: 50px">结算开票</div>
          <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
            <el-col :span="12">
              <erp-view label="客户是否结清" type="edit" style="align-items: baseline">
                <div>
                  <el-select clearable v-model="formData.clearanceProgressInfo.isSettled" class="full-input" :disabled="!canEditSettled" placeholder=" ">
                    <el-option :value="0" label="待结清"></el-option>
                    <el-option :value="1" label="已结清"></el-option>
                  </el-select>
                  <div class="ticket-info">
                    <i class="el-icon-warning"></i>
                    指订单针对客户的所有费用是否已结清
                  </div>
                </div>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="开票状态" type="edit" style="align-items: baseline">
                <div>
                  <el-select clearable v-model="formData.invoiceProgressInfo.isSettled" class="full-input" :disabled="!copydata.canEdit" placeholder=" ">
                    <el-option :value="0" label="待开票"></el-option>
                    <el-option :value="1" label="已开票"></el-option>
                  </el-select>
                  <div class="ticket-info">
                    <i class="el-icon-warning"></i>
                    指订单针对客户的所有发票是否已开票
                  </div>
                </div>
              </erp-view>
            </el-col>
          </el-row>

          <div v-if="!formData.isNeedJr && formData.isCustomerConsignee" style="margin-bottom: 30px">
            <div class="erp-form__title f16" style="margin-top: 50px">付外商货款</div>
            <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
              <el-col :span="12">
                <erp-view label="付预付款日期" type="edit">
                  <el-date-picker
                    clearable
                    v-model="formData.ratioProgressInfo.performDate"
                    style="width: 100%"
                    placeholder="请选择付预付款日期"
                    @change="changeDate(formData.ratioProgressInfo, 'performDate', $event)"
                    prefix-icon="null"
                  ></el-date-picker>
                </erp-view>
              </el-col>
              <el-col :span="12">
                <erp-view label="付尾款日期" type="edit">
                  <el-date-picker
                    clearable
                    v-model="formData.tailProgressInfo.performDate"
                    style="width: 100%"
                    placeholder="请选择付尾款日期"
                    prefix-icon="null"
                    @change="changeDate(formData.tailProgressInfo, 'performDate', $event)"
                  >
                  </el-date-picker>
                </erp-view>
              </el-col>
              <el-col :span="12">
                <erp-view label="预付款状态" type="edit">
                  <el-select clearable v-model="formData.ratioProgressInfo.isSettled" class="full-input" placeholder="请选择">
                    <el-option :value="0" label="待支付"></el-option>
                    <el-option label="已支付" :value="1"></el-option>
                  </el-select>
                </erp-view>
              </el-col>
              <el-col :span="12">
                <erp-view label="尾款状态" type="edit">
                  <el-select clearable v-model="formData.tailProgressInfo.isSettled" class="full-input" placeholder="请选择">
                    <el-option :value="0" label="待支付"></el-option>
                    <el-option label="已支付" :value="1"></el-option>
                  </el-select>
                </erp-view>
              </el-col>

              <el-col :span="12">
                <erp-view label="预付凭据" type="edit" class="cell" style="align-items: flex-start">
                  <tableFileContent
                    class="margin-upload"
                    width="970px"
                    fileType="noZip"
                    :maxImg="3"
                    :size="10"
                    @getFileUrls="getPrePayFile"
                    setKeyName="name"
                    setKeyUrl="url"
                    keyName="name"
                    keyUrl="url"
                    :fileUrlsArray="
                      formData.ratioProgressInfo.attachmentDtos && formData.ratioProgressInfo.attachmentDtos.length > 0
                        ? formData.ratioProgressInfo.attachmentDtos
                        : null
                    "
                  >
                  </tableFileContent>
                </erp-view>
              </el-col>
              <el-col :span="12">
                <erp-view label="尾款凭据" type="edit" class="cell" style="align-items: flex-start">
                  <tableFileContent
                    class="margin-upload"
                    width="970px"
                    fileType="noZip"
                    :maxImg="3"
                    :size="10"
                    @getFileUrls="getFinalPayFile"
                    setKeyName="name"
                    setKeyUrl="url"
                    keyName="name"
                    keyUrl="url"
                    :fileUrlsArray="
                      formData.tailProgressInfo.attachmentDtos && formData.tailProgressInfo.attachmentDtos.length > 0
                        ? formData.tailProgressInfo.attachmentDtos
                        : null
                    "
                  >
                  </tableFileContent>
                </erp-view>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import tableFileContent from '@/components/TableFileContent/TableFileContent.vue';
import DictSelect from '@/components/DictSelect/DictSelect.vue';
import { store } from '@/store';
import { httpGet, httpPost } from '@/api';
import { formatTime } from '@/utils/publicMethods';
export default {
  components: {
    tableFileContent,
    DictSelect,
  },
  props: {
    data: {
      // 获取传递过来的详情
      type: [Object],
      default: function () {
        return {};
      },
    },
  },
  computed: {},
  data() {
    return {
      canEditSettled: false,
      isAjaxLoading: false,
      filterOption: {
        copy_progress_type_status: store.state.dict.options.copy_progress_type_status,

        origin_progress_type_status: store.state.dict.options.origin_progress_type_status,

        clearance_progress_type_status: store.state.dict.options.clearance_progress_type_status,

        order_qhdc_release_type: store.state.dict.options.order_qhdc_release_type,
      },
      copydata: {},
      formData: {
        isCustomerGoodsPayment: false,
        isNeedJr: '', // 是否提供供应链金融服务
        // 清关送货状态栏 ,
        clearanceProgress: {
          boxFreePeriod: null,
          boxRetunAddress: null,
          description: null,
          id: null,
          isSettled: null,
          performDate: null,
          version: 0,
        },
        // 副本文件状态栏 ,
        copyDocProgress: {
          boxFreePeriod: null,
          boxRetunAddress: null,
          description: null,
          id: null,
          isSettled: '',
          performDate: null,
          version: 0,
          trackingNumber: null,
          fileUrl: null,
          fileName: null,
        },
        // 交报关行状态栏 ,
        declarationProgress: {
          boxFreePeriod: null,
          boxRetunAddress: null,
          description: null,
          id: null,
          isSettled: null,
          performDate: null,
          version: null,
          trackingNumber: null,
          fileUrl: null,
          fileName: null,
        },
        orderId: 0,
        // 正本文件状态栏
        originalDocProgress: {
          boxFreePeriod: null,
          boxRetunAddress: null,
          description: null,
          id: null,
          isSettled: null,
          performDate: null,
          version: null,
          trackingNumber: null,
          fileUrl: null,
          fileName: null,
          releaseType: 1,
        },
        invoiceProgressInfo: {
          isSettled: null,
        },
        clearanceProgressInfo: {
          isSettled: null,
        },
        // 提箱中转信息
        packingContainerInfo: {
          packingContainerDate: null,
          packingContainerDescription: null,
          transferDate: null,
          transferStorage: null,
          xsDate: null,
          transferDescription: null,
        },
        // 还箱信息状态栏
        returnBoxProgressInfo: {
          boxFreePeriod: null,
          boxRetunAddress: null,
          description: null,
          id: null,
          isSettled: 0,
          performDate: null,
          version: null,
        },
        ratioProgressInfo: {
          performDate: null,
          isSettled: null,
          attachmentDtos: [],
          version: null,
        },
        tailProgressInfo: {
          performDate: null,
          isSettled: null,
          attachmentDtos: [],
          version: null,
        },
        foreignRatioProgressInfo: {
          performDate: null, // 贸易商付预付款日期
          isSettled: null,
          attachmentDtos: [],
          version: null,
        },
        foreignTailProgressInfo: {
          performDate: null, // 贸易商付尾款日期
          isSettled: null,
          attachmentDtos: [],
          version: null,
        },
      },
      rules: {
        'originalDocProgress.trackingPhone': [
          {
            pattern: /(\d){4}/,
            message: '固定4位数字',
            trigger: 'blur',
          },
        ],
        'declarationProgress.trackingPhone': [
          {
            pattern: /(\d){4}/,
            message: '固定4位数字',
            trigger: 'blur',
          },
        ],
      },
      zhengBenOption: [], // 正本快递下拉选项
      jiaoBaoGuanOption: [], // 交报关行快递下拉选项
    };
  },
  created() {
    this.load = false;
    this.copydata = JSON.parse(JSON.stringify(this.data));
    this.formData.isCustomerGoodsPayment = this.copydata.isCustomerGoodsPayment;
    if (this.copydata.declarationProgress) {
      this.formData.declarationProgress = this.copydata.declarationProgress;
    }
    if (this.copydata.clearanceProgress) {
      this.formData.clearanceProgress = this.copydata.clearanceProgress;
      if (this.copydata.clearanceProgress.isSettled === true) {
        this.formData.clearanceProgress.isSettled = 1;
      } else if (this.copydata.clearanceProgress.isSettled === false) {
        this.formData.clearanceProgress.isSettled = 0;
      } else {
        this.formData.clearanceProgress.isSettled = null;
      }
    }
    if (this.copydata.foreignRatioProgress) {
      this.formData.foreignRatioProgressInfo = this.copydata.foreignRatioProgress;
      if (this.copydata.foreignRatioProgress.isSettled === true || this.copydata.foreignRatioProgress.isSettled === 1) {
        this.formData.foreignRatioProgressInfo.isSettled = 1;
      } else if (this.copydata.foreignRatioProgress.isSettled === false || this.copydata.foreignRatioProgress.isSettled === 0) {
        this.formData.foreignRatioProgressInfo.isSettled = 0;
      } else {
        this.formData.foreignRatioProgressInfo.isSettled = null;
      }
      this.formData.foreignRatioProgressInfo.attachmentDtos = this.copydata.foreignRatioProgress.attachmentVos;
    }
    if (this.copydata.foreignTailProgress) {
      this.formData.foreignTailProgressInfo = this.copydata.foreignTailProgress;
      if (this.copydata.foreignTailProgress.isSettled === true || this.copydata.foreignTailProgress.isSettled === 1) {
        this.formData.foreignTailProgressInfo.isSettled = 1;
      } else if (this.copydata.foreignTailProgress.isSettled === false || this.copydata.foreignTailProgress.isSettled === 0) {
        this.formData.foreignTailProgressInfo.isSettled = 0;
      } else {
        this.formData.foreignTailProgressInfo.isSettled = null;
      }
      this.formData.foreignTailProgressInfo.attachmentDtos = this.copydata.foreignTailProgress.attachmentVos;
    }
    if (this.copydata.copyDocProgress) {
      this.formData.copyDocProgress = this.copydata.copyDocProgress;
    }
    if (this.copydata.originalDocProgress) {
      this.formData.originalDocProgress = this.copydata.originalDocProgress;
    }
    if (this.copydata.returnBoxProgress) {
      this.formData.returnBoxProgressInfo = this.copydata.returnBoxProgress;
    }
    if (this.copydata.packingContainerInfo) {
      this.formData.packingContainerInfo = this.copydata.packingContainerInfo;
    }
    if (this.copydata.settleProgress) {
      this.formData.clearanceProgressInfo = this.copydata.settleProgress;
    }
    if (this.copydata.invoiceProgress) {
      this.formData.invoiceProgressInfo = this.copydata.invoiceProgress;
    }
    if (this.copydata.ratioProgress) {
      this.formData.ratioProgressInfo = this.copydata.ratioProgress;
      this.formData.ratioProgressInfo.attachmentDtos = this.copydata.ratioProgress.attachmentVos;
    }
    if (this.copydata.tailProgress) {
      this.formData.tailProgressInfo = this.copydata.tailProgress;
      this.formData.tailProgressInfo.attachmentDtos = this.copydata.tailProgress.attachmentVos;
    }
    this.formData.orderId = this.copydata.orderId;
    this.formData.isNeedJr = this.copydata.isNeedJr;
    this.formData.isCustomerConsignee = this.copydata.isCustomerConsignee;
    this.formData.version = this.copydata.version;
    this.getCompanyOption('', 'zhengBen');
    this.getCompanyOption('', 'jiaoBaoGuan');
    this.isCanEditSettled();
    this.load = true;
  },
  methods: {
    async isCanEditSettled() {
      let orderId = this.formData.orderId;
      const res = await httpGet(`/malicrm/invoice/output/checkSettleStatusByOrderId/${orderId}`);
      if (res.code == 200) {
        this.canEditSettled = res.result?.isSure ? res.result.isSure : false;
      }
    },
    disablePhone(name) {
      const res = true;
      if (name && name.indexOf('顺丰') > -1) {
        return false;
      }
      return res;
    },
    /** hack 处理时间 */
    changeDate(item, name, value) {
      item[name] = formatTime(value, 'YYYY-MM-DD');
    },
    getPrePayFile({ data }) {
      const array = [];
      data.forEach((v) => {
        const obj = {
          url: v.url,
          name: v.name,
        };
        array.push(obj);
      });
      this.formData.ratioProgressInfo.attachmentDtos = array;
    },
    getFinalPayFile({ data }) {
      const array = [];
      data.forEach((v) => {
        const obj = {
          url: v.url,
          name: v.name,
        };
        array.push(obj);
      });
      this.formData.tailProgressInfo.attachmentDtos = array;
    },
    getForeignRatioProgressFile({ data }) {
      const array = [];
      data.forEach((v) => {
        const obj = {
          url: v.url,
          name: v.name,
        };
        array.push(obj);
      });
      this.formData.foreignRatioProgressInfo.attachmentDtos = array;
    },
    getForeignTailProgressFile({ data }) {
      const array = [];
      data.forEach((v) => {
        const obj = {
          url: v.url,
          name: v.name,
        };
        array.push(obj);
      });
      this.formData.foreignTailProgressInfo.attachmentDtos = array;
    },
    editBaseInfo() {
      // 通知tab页切换显示 编辑页面
      // 判断正本日期，副本日期是否必填
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (!this.isAjaxLoading) {
            this.isAjaxLoading = true;
            const res = await httpPost('/malicrm/order/saveOrderQhdcProgressInfo', this.formData);
            this.isAjaxLoading = false;
            if (res.code === 200) {
              this.isAjaxLoading = false;
              this.$emit('back', 2, true);
            } else {
              this.isAjaxLoading = false;
            }
          }
        }
      });
    },
    /**
     * 快递公司选择 change
     * @param val 快递公司
     * @param index 当前行索引
     * @param type 'zhengBen'正本文件, 'jiaoBaoGuan'交报关行
     */
    expressCompanyChange(val, type) {
      let propOption = '';
      let formProp = '';
      if (type === 'zhengBen') {
        propOption = 'zhengBenOption';
        formProp = 'originalDocProgress';
        this.$refs.form.clearValidate(['originalDocProgress.trackingPhone']);
      }
      if (type === 'jiaoBaoGuan') {
        propOption = 'jiaoBaoGuanOption';
        formProp = 'declarationProgress';
        this.$refs.form.clearValidate(['declarationProgress.trackingPhone']);
      }
      const itemArr = this[propOption].filter((item) => {
        return item.companyCode === val;
      });
      if (itemArr && itemArr.length > 0) {
        this.formData[formProp].trackingCompanyName = itemArr[0].companyName;
        if (itemArr[0].companyName.indexOf('顺丰') === -1) {
          this.formData[formProp].trackingPhone = null;
        }
      }
    },
    /**
     * 远程获取快递公司下拉选项
     * @param name 搜索关键字
     * @param type 'zhengBen'正本文件, 'jiaoBaoGuan'交报关行
     */
    async getCompanyOption(name, type) {
      let prop = '';
      let searchType = 1; // 1.快递公司名称没有传的情况 2.快递公司传了情况
      let source = 1; // 1.正本 2.交报关行
      if (type === 'zhengBen') {
        prop = 'zhengBenOption';
      }
      if (type === 'jiaoBaoGuan') {
        prop = 'jiaoBaoGuanOption';
        source = 2;
      }
      if (name) {
        searchType = 2;
      }
      const res = await httpPost('/malicrm/order/queryTrackingCompany', {
        companyName: name,
        type: searchType,
        source,
      });
      if (res.code === 200) {
        this[prop] = res.result;
      }
    },
    /**
     * 快递公司下拉选项获取焦点事件
     * @param type 'zhengBen'正本文件, 'jiaoBaoGuan'交报关行
     */
    focusExpress(type) {
      let len;
      let prop;
      if (type === 'zhengBen') {
        prop = 'zhengBenOption';
      }
      if (type === 'jiaoBaoGuan') {
        prop = 'jiaoBaoGuanOption';
      }
      len = this[prop].length;
      if (!len) {
        this.getCompanyOption('', type);
      }
    },
    /**
     * 其他费用input清除事件
     * @param type 'zhengBen'正本文件, 'jiaoBaoGuan'交报关行
     */
    clearExpress(type) {
      this.getCompanyOption('', type);
    },
    back() {
      // 返回详情页
      this.$emit('back', 2);
    },
  },
};
</script>

<style lang="scss" scoped>
.username-div-active {
  width: 100%;
  height: 30px;
  line-height: 30px;
  background: rgba(247, 247, 247, 1);
  border: 1px solid rgba(227, 228, 230, 1);
  border-radius: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 10px;

  color: #1a1a1a;
}
.order-title__bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 11px;
}
.count-value {
  font-size: 14px;
  color: #303133;
}
.count-name {
  font-size: 14px;
  color: #606266;
}
.ticket-info {
  margin-top: 11px;
  color: #a3aec3;
  font-size: 14px;
}
</style>
