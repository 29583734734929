<template>
  <div>
    <!-- 基础信息是tab页切换 -->
    <div class="order-tabs__warp" v-if="loadStatus">
      <el-tabs v-model="activeName" class="order-tabs">
        <el-tab-pane label="基本信息" name="1" v-if="status.includes(1)">
          <!-- 基础信息查看 -->
          <base-info :data="detailsData" @edit="tabEdit" v-if="!isEdit" @back="editBack"></base-info>
          <base-info-form :data="detailsData" @back="editBack" v-else :limtEdit="limtEdit"></base-info-form>
        </el-tab-pane>
        <el-tab-pane label="状态栏" name="2" v-if="status.includes(2)" lazy>
          <status-info :data="detailsData" @edit="tabEdit" v-if="!isEdit" @back="editBack"></status-info>
          <status-info-from :data="detailsData" @back="editBack" v-else></status-info-from>
        </el-tab-pane>
        <el-tab-pane label="收付栏" name="3" v-if="status.includes(3) && detailsData.isShowArap && hasPermission('erp:order:queryOrderArap')" lazy>
          <payment-column :data="detailsData" @edit="tabEdit" @back="editBack"></payment-column>
        </el-tab-pane>
        <el-tab-pane label="收款栏" name="5" v-if="status.includes(5) && hasPermission('erp:order:queryOrderAr')" lazy>
          <gathering-bar :data="detailsData" @edit="tabEdit" @back="editBack"> </gathering-bar>
        </el-tab-pane>
        <el-tab-pane label="证件栏" name="4" v-if="status.includes(4)" lazy>
          <card-info :data="detailsData" @edit="tabEdit" v-if="!isEdit" @back="editBack"></card-info>
          <card-info-from :data="detailsData" @back="editBack" v-else></card-info-from>
        </el-tab-pane>
        <el-tab-pane label="计费方式" name="6" v-if="status.includes(6)">
          <billing-way :data="detailsData" @edit="tabEdit" v-if="!isEdit" @back="editBack"></billing-way>
          <billing-way-form :data="detailsData" @back="editBack" v-else></billing-way-form>
        </el-tab-pane>
        <el-tab-pane label="冻品安全码" name="7" v-if="status.includes(7) && detailsData.isShowCodePic" lazy>
          <inspect-view :data="detailsData" @back="editBack"></inspect-view>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import { httpGet } from '@/api';
import { errorMessage, getRouterParams, hasPermission } from '@/utils/publicMethods';
import { defineComponent } from 'vue';
import BaseInfo from './components/BaseInfo';
import BaseInfoForm from './components/BaseInfoForm';
import StatusInfo from './components/StatusInfo';
import StatusInfoFrom from './components/StatusInfoFrom';
import PaymentColumn from './components/PaymentColumn';
import GatheringBar from './components/GatheringBar';
import CardInfo from './components/CardInfo';
import CardInfoFrom from './components/CardInfoForm.vue';
import BillingWay from './components/BillingWay.vue';
import BillingWayForm from './components/BillingWayForm.vue';
import InspectView from './components/inspectView.vue';
const STATUS = [1, 2, 3, 4, 5, 6, 7];
export default defineComponent({
  components: {
    BaseInfo,
    BaseInfoForm,
    StatusInfo,
    StatusInfoFrom,
    PaymentColumn,
    CardInfo,
    CardInfoFrom,
    GatheringBar,
    BillingWay,
    BillingWayForm,
    InspectView,
  },
  data() {
    return {
      limtEdit: false, // 限制编辑状态
      loadStatus: false,
      status: STATUS, // 显示值控制参数 默认给大点的数字
      activeName: '1',
      isEdit: false, // 是否进入编辑态
      statusEdit: false, // 是否进入编辑态
      detailsData: {
        // 订单详情信息
        agencyName: null,
        businessContractNo: null,
        category: null,
        categoryDisplay: null,
        clearanceProgress: {
          description: null,
          id: 0,
          isSettled: false,
          isSettledDisplay: null,
          lastOperator: null,
          performDate: null,
          updateTime: null,
          version: 0,
        },
        consigneeShortName: null,
        containerNo: null,
        containerType: null,
        containerTypeDisplay: null,
        copyDocProgress: {
          description: null,
          id: 0,
          isSettled: false,
          isSettledDisplay: null,
          lastOperator: null,
          performDate: null,
          updateTime: null,
          version: 0,
        },
        country: null,
        countryDisplay: null,
        customerId: 0,
        customerName: null,
        declarationKeyElements: null,
        declarationProgress: {
          description: null,
          id: 0,
          isSettled: false,
          isSettledDisplay: null,
          lastOperator: null,
          performDate: null,
          updateTime: null,
          version: 0,
        },
        depositRate: 0,
        depositRateDisplay: null,
        id: 0,
        incoterm: null,
        incotermDisplay: null,
        isNeedJr: '', // 是否提供供应链金融服务
        ladingBillNo: null,
        orderId: 0,
        orderVersion: 0,
        originalDocProgress: {
          description: null,
          id: 0,
          isSettled: false,
          isSettledDisplay: null,
          lastOperator: null,
          performDate: null,
          updateTime: null,
          version: 0,
        },
        portName: null,
        produceDate: null,
        productList: [
          {
            actualAmount: 0,
            actualAmountDisplay: null,
            actualWeight: 0,
            actualWeightDisplay: null,
            category: null,
            contractAmount: 0,
            contractAmountDisplay: null,
            contractWeight: 0,
            contractWeightDisplay: null,
            country: null,
            elecInfoStatus: null,
            elecInfoStatusDisplay: null,
            healthCertNo: null,
            labelId: 0,
            nameCn: null,
            nameEn: null,
            packageCount: 0,
            packageCountDisplay: null,
            plantNo: null,
            tradeCurrency: null,
            tradeCurrencyDisplay: null,
            unitPrice: 0,
            unitPriceDisplay: null,
            varietyName: null,
          },
        ],
        remark: null,
        returnBoxProgress: {
          description: null,
          id: 0,
          isSettled: false,
          isSettledDisplay: null,
          lastOperator: null,
          performDate: null,
          updateTime: null,
          version: 0,
        },
        shippingEndDate: null,
        shippingInfo: null,
        shippingStartDate: null,
        status: null,
        statusDisplay: null,
        supplierContractNo: null,
        supplierId: 0,
        supplierName: null,
        userIdList: [0],
        userIdMerged: null,
        userNameMerged: null,
        isNewOrder: false,
        customerContractNo: null,
        version: 0,
      },
    };
  },
  methods: {
    hasPermission(bindPermissions) {
      return hasPermission(bindPermissions);
    },
    tabEdit(status) {
      // 切换编辑，可以通用
      this.loadStatus = false;
      if (typeof status === 'number') {
        this.status = [status];
      } else {
        if (status === 'limtEdit') {
          this.status = [1];
          this.limtEdit = true; // 限制编辑状态
        }
      }
      this.isEdit = true;
      setTimeout(() => {
        this.loadStatus = true;
      }, 10);
    },
    back() {
      // 详细信息返回
      this.status = STATUS;
      this.isEdit = false;
    },
    editBack(status, reach) {
      // 表单编辑返回，可以通用
      this.limtEdit = false;
      this.loadStatus = false;
      if (status === 2 || status === 1 || status === 4 || status === 6) {
        // 这个方法不要改，其他模块全是一致的
        this.status = STATUS;
        this.getOrderDetails(this.detailsData.orderId, true);
      }
      if (reach) {
        const { orderId } = getRouterParams('OrderDetail');
        this.getOrderDetails(orderId, false);
      }
    },
    /**
     * 获取订单详情
     * @param orderId 订单id
     * @param name 判断是否刷新显示tab true 刷新 false不刷新
     */
    async getOrderDetails(orderId, status) {
      // 判断是否状态列表刷新
      const res = await httpGet(`/malicrm/order/queryOrderQhdcDetailById/${orderId}`);
      if (res.code === 200) {
        this.detailsData = Object.freeze(res.result);
        this.status = STATUS;
        this.loadStatus = true;
        if (status) {
          this.isEdit = false;
        }
      } else {
        errorMessage(res.message);
        this.status = STATUS;
        this.loadStatus = true;
        if (status) {
          this.isEdit = false;
        }
      }
    },
  },
  created() {
    const { orderId } = getRouterParams('OrderDetail');
    this.getOrderDetails(orderId);
  },
});
</script>

<style lang="scss" scoped></style>
