<template>
  <div class="progress-history">
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">状态记录（{{ title }}）</div>
      <div class="erp-form-bar">
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <el-table :data="data" border class="egrid erp-table__list erp-view__table tb-pd line-height1">
      <el-table-column align="center" :label="type === 3 ? '日期' : '日期'" prop="performDate" show-overflow-tooltip :width="106">
        <template #default="scope">
          {{ filterName(scope.row.performDate) }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="状态" prop="isSettledDesc" show-overflow-tooltip :width="type === 3 ? 136 : 118">
        <template #default="scope">
          {{ filterName(scope.row.isSettledDesc) }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="放货方式" prop="releaseTypeDesc" show-overflow-tooltip width="100" v-if="type == 2">
        <template #default="scope">
          {{ filterName(scope.row.releaseTypeDesc) }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="快递公司" prop="companyName" show-overflow-tooltip width="100" v-if="type == 2 || type == 3">
        <template #default="scope">
          {{ filterName(scope.row.companyName) }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="正本快递单号" prop="trackingNumber" show-overflow-tooltip width="140" v-if="type == 2">
        <template #default="scope">
          {{ filterName(scope.row.trackingNumber) }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="寄件人/收件人手机号后四位" prop="trackingPhone" show-overflow-tooltip width="120" v-if="type == 2">
        <template #default="scope">
          {{ filterName(scope.row.trackingPhone) }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="报关资料快递单号" prop="trackingNumber" show-overflow-tooltip width="160" v-if="type == 3">
        <template #default="scope">
          {{ filterName(scope.row.trackingNumber) }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="寄件人/收件人手机号后四位" prop="trackingPhone" show-overflow-tooltip width="120" v-if="type == 3">
        <template #default="scope">
          {{ filterName(scope.row.trackingPhone) }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="描述" prop="description" show-overflow-tooltip>
        <template #default="scope">
          {{ filterName(scope.row.description) }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="更新人" prop="lastOperator" show-overflow-tooltip width="120"> </el-table-column>
      <el-table-column align="center" label="更新时间" prop="updateTime" show-overflow-tooltip width="170"> </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { httpPost } from '@/api';
import { filterName } from '@/utils/publicMethods';

export default {
  components: {},
  props: ['title', 'orderId', 'type'],
  data() {
    return {
      data: [],
    };
  },
  created() {
    this.initData();
  },
  watch: {},
  computed: {},
  methods: {
    filterName(val) {
      return filterName(val);
    },
    showFile(name) {
      this.$refs[name].showView('view');
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    async initData() {
      const res = await httpPost('/malicrm/order/queryOrderQhdcProgressHistory', {
        orderId: this.orderId,
        pageNum: 1,
        pageSize: 999,
        progressType: this.type,
      });
      if (res.code === 200) {
        this.data = res.result.list;
      } else {
        this.errorTip(res.message);
      }
    },
    back() {
      this.$emit('cancel', false);
    },
  },
};
</script>
<style lang="scss" scoped></style>
