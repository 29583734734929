<template>
  <div>
    <el-row :gutter="65" type="flex" class="op-16">
      <el-col :span="12" style="width: 48%">
        <div>
          <div v-if="formData.isNeedHalfQhdc">
            <div class="erp-form__title f16">半期货服务费</div>
            <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
              <el-col :span="12">
                <erp-view label="服务费(元/柜)" :value="formData.halfQhdcServiceFeeDesc" :type="type">
                  <el-input :model-value="formData.halfQhdcServiceFee" :disabled="true" v-if="type === 'edit'"></el-input>
                </erp-view>
              </el-col>
            </el-row>
          </div>
          <div class="erp-form__title f16" :class="{ 'mt-50': formData.isNeedHalfQhdc }">代采费</div>
          <template v-for="(item, index) in formData.cgFeeList">
            <el-row :key="index + 'cgFee'" :gutter="30" type="flex" class="pl-12 flex-warp" v-if="item.category === formData.category">
              <el-col :span="12">
                <erp-view label="大类" :value="item.categoryDesc" :type="type">
                  <el-input :model-value="item.categoryDesc" :disabled="true" v-if="type === 'edit'"></el-input>
                </erp-view>
              </el-col>
              <el-col :span="12">
                <erp-view :label="'代采费(' + item.agencyFeeUnitDesc + ')'" :value="item.agencyFee" :type="type">
                  <el-input
                    v-model="item.agencyFee"
                    :maxlength="5"
                    clearable
                    :placeholder="'请输入代采费(' + item.agencyFeeUnitDesc + ')'"
                    @blur="valFeeInteger($event, 'cgFeeList', 'agencyFee', index)"
                    v-if="type === 'edit'"
                  ></el-input>
                </erp-view>
              </el-col>
            </el-row>
          </template>
          <div class="erp-form__title f16" style="margin-top: 50px">多品名费用</div>
          <el-row :gutter="0" type="flex">
            <el-table :data="formData.multiProductFeeConfigList" border class="egrid erp-table__list erp-view__table margin__top">
              <el-table-column label="收费标准" prop="feeTypeDesc" align="center"></el-table-column>
              <el-table-column label="最小值" prop="configMinDisplay" align="center"></el-table-column>
              <el-table-column label="最大值" prop="configMaxDisplay" align="center"></el-table-column>
              <el-table-column label="费用(元)" prop="feeDisplay" align="center"></el-table-column>
            </el-table>
          </el-row>
          <div class="erp-form__title f16" style="margin-top: 50px">多次提货费</div>
          <el-row :gutter="0" type="flex">
            <el-table :data="formData.multiPickupFeeConfigList" border class="egrid erp-table__list erp-view__table margin__top">
              <el-table-column label="最小值" prop="configMinDisplay" align="center"></el-table-column>
              <el-table-column label="最大值" prop="configMaxDisplay" align="center"></el-table-column>
              <el-table-column label="费用(元/次)" prop="feeDisplay" align="center"></el-table-column>
            </el-table>
          </el-row>
        </div>
      </el-col>
      <el-col :span="12" style="width: 48%">
        <div>
          <div class="erp-form__title f16">代理费</div>
          <template v-for="(item, index) in formData.agencyFeeList">
            <el-row :key="index + 'cgFee'" :gutter="30" type="flex" class="pl-12 flex-warp" v-if="item.category === formData.category">
              <el-col :span="12">
                <erp-view label="大类" :value="item.categoryDesc" :type="type">
                  <el-input :model-value="item.categoryDesc" :disabled="true" v-if="type === 'edit'"></el-input>
                </erp-view>
              </el-col>
              <el-col :span="12">
                <erp-view label="大柜最低吨数" :value="item.clearanceFeeLargeMinWeight" :type="type">
                  <el-input
                    @blur="valFeeInteger($event, 'agencyFeeList', 'clearanceFeeLargeMinWeight', index)"
                    :maxlength="2"
                    v-model="item.clearanceFeeLargeMinWeight"
                    v-if="type === 'edit'"
                    clearable
                  ></el-input>
                </erp-view>
              </el-col>
              <el-col :span="12">
                <erp-view :label="'大柜代理费  (' + item.clearanceFeeLargeUnitDesc + ')'" :value="item.clearanceFeeLarge" :type="type">
                  <el-input
                    v-model="item.clearanceFeeLarge"
                    :maxlength="5"
                    clearable
                    :placeholder="'请输入大柜代理费  (' + item.clearanceFeeLargeUnitDesc + ')'"
                    v-if="type === 'edit'"
                    @blur="valFeeInteger($event, 'agencyFeeList', 'clearanceFeeLarge', index)"
                  ></el-input>
                </erp-view>
              </el-col>
              <el-col :span="12">
                <erp-view :label="'小柜代理费  (元/柜)'" :value="item.clearanceFeeSmall" :type="type">
                  <el-input
                    v-model="item.clearanceFeeSmall"
                    :maxlength="5"
                    clearable
                    placeholder="请输入小柜代理费  (元/柜)"
                    @blur="valFeeInteger($event, 'agencyFeeList', 'clearanceFeeSmall', index)"
                    v-if="type === 'edit'"
                  ></el-input>
                </erp-view>
              </el-col>
            </el-row>
          </template>
          <div class="erp-form__title f16" style="margin-top: 50px">保险费</div>
          <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
            <el-col :span="12">
              <erp-view label="保险费率(‰)" :value="formData.insuranceRate" :type="type">
                <el-input v-model="formData.insuranceRate" @blur="changeNumber(formData.insuranceRate)" :disabled="false" v-if="type === 'edit'"></el-input>
              </erp-view>
            </el-col>
          </el-row>
          <div class="erp-form__title f16" style="margin-top: 50px">多品类费用</div>
          <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
            <el-col :span="12">
              <erp-view label="开始收费的品种数" :value="formData.multiVarietyFeeStartCount" :type="type">
                <el-input :model-value="formData.multiVarietyFeeStartCount" :disabled="true" v-if="type === 'edit'"></el-input>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="费用(元/品种)" :value="formData.multiVarietyFee" :type="type">
                <el-input :model-value="formData.multiVarietyFee" :disabled="true" v-if="type === 'edit'"></el-input>
              </erp-view>
            </el-col>
          </el-row>
          <div class="erp-form__title f16" style="margin-top: 50px">利率</div>
          <el-row :gutter="0" type="flex">
            <el-table :data="formData.interestRateConfigList" border class="egrid erp-table__list erp-view__table margin__top">
              <el-table-column label="最小天数" prop="configMinDisplay" align="center"></el-table-column>
              <el-table-column label="最大天数" prop="configMaxDisplay" align="center"></el-table-column>
              <el-table-column label="利率(年化%)" prop="rate" align="center" v-if="type === 'edit'" class-name="no-pd">
                <template v-slot="scope">
                  <el-input
                    v-model="scope.row.rate"
                    placeholder="请输入利率(年化%)"
                    :maxlength="5"
                    clearable
                    @blur="valRateFee($event, 'rate', scope.$index)"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column label="利率(年化%)" prop="rate" align="center" v-else></el-table-column>
              <el-table-column label="最小使用天数" prop="minUseDaysDisplay" align="center"></el-table-column>
            </el-table>
          </el-row>
          <template v-if="formData.dayOtherFeeList && formData.dayOtherFeeList.length > 0">
            <div class="erp-form__title f16" style="margin-top: 50px">其他费用 (按天计费)</div>
            <el-row :gutter="0" type="flex">
              <el-table :data="formData.dayOtherFeeList" border class="egrid erp-table__list erp-view__table margin__top">
                <el-table-column label="费用名称" align="center">
                  <div>操作费<span class="feeNameTip">（垫资的情况下才收取此费用）</span></div>
                  <!-- <template v-slot="scope">
                    <span>{{ filterName(scope.row.feeName) }}</span>
                  </template> -->
                </el-table-column>
                <el-table-column label="收费标准" prop="feeStandard" align="center">
                  <template v-slot="scope">
                    <span>{{ filterName(scope.row.feeStandard) }}元/吨</span>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </template>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    type: {
      type: [String],
      default: '',
    },
    billData: {
      // 获取传递过来的详情
      type: [Object],
      default: function () {
        return {};
      },
    },
  },
  filters: {
    appendUnit(val) {
      if (val) {
        return val + '%';
      } else {
        return '-';
      }
    },
  },
  data() {
    return {
      formData: {},
    };
  },
  created() {
    this.formData = Object.assign({}, this.billData);
  },
  methods: {
    //处理字符
    changeNumber(e) {
      let value = e.slice(0, 100);
      if (value) {
        let reg = null;
        //   // 校验小数位
        reg = new RegExp(`^(-)*(\\d+)\\.(\\d{0,2}).*$`);
        let value = e.replace(/[^\d.-]/g, ''); // 清除“数字”和“.”以外的字符
        value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
        value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
        value = value.replace(reg, '$1$2.$3'); //控制小数位
        this.formData.insuranceRate = value; //赋值
      }
    },
    validForm() {
      let res = true;
      const category = this.formData.category;
      const cgFeeList = this.formData.cgFeeList.filter((item) => {
        return item.category === category;
      });
      const cgFeeItem = cgFeeList[0];

      const agencyFeeList = this.formData.agencyFeeList.filter((item) => {
        return item.category === category;
      });
      const agencyFeeItem = agencyFeeList[0];
      // 大柜代理费
      if (this.isNummOrEmpty(agencyFeeItem.clearanceFeeLarge)) {
        res = false;
      }
      // 小柜代理费
      if (this.isNummOrEmpty(agencyFeeItem.clearanceFeeSmall)) {
        res = false;
      }
      // 代采费
      if (this.isNummOrEmpty(cgFeeItem.agencyFee)) {
        res = false;
      }
      // 利率1
      if (this.isNummOrEmpty(this.formData.interestRateConfigList[0].rate)) {
        res = false;
      }
      // 利率2
      if (this.isNummOrEmpty(this.formData.interestRateConfigList[1].rate)) {
        res = false;
      }
      // 利率3
      if (this.isNummOrEmpty(this.formData.interestRateConfigList[2].rate)) {
        res = false;
      }
      if (res) {
        return this.formData;
      } else {
        return false;
      }
    },
    isNummOrEmpty(val) {
      if (val === '' || val === null || val === undefined) {
        return true;
      } else {
        return false;
      }
    },
    /*
     * 校验 正整数
     */
    valInteger(val, prop) {
      let value = val.target.value;
      value = value.replace(/[^\d]/g, '');
      value = value.replace(/^0{2,}|[^\d]+/g, '');
      if (value.length >= 2) {
        value = parseInt(value);
      }
      this.formData[prop] = value;
    },
    /*
     * 校验 正整数
     * @param listProp cgFeeList
     * @param itemProp cgFeeList中属性名
     * @param index 索引
     */
    valFeeInteger(val, listProp, itemProp, index) {
      let value = val.target.value;
      value = value.replace(/[^\d]/g, '');
      value = value.replace(/^0{2,}|[^\d]+/g, '');
      if (value.length >= 2) {
        value = parseInt(value);
      }
      this.formData[listProp][index][itemProp] = value;
    },
    valRateFee(e, prop, index) {
      let value = e.target.value;
      value = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      value = value.replace(/^(\d+)\.(\d\d).*$/, '$1.$2'); // eslint-disable-line
      if (value.indexOf('.') < 0 && value !== '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (value.substr(0, 1) === 0) {
          value = value.substr(1);
        }
      }
      if (value.indexOf('.') === 0) {
        value = '0' + value.substr(0, 3);
      }
      if (value.lastIndexOf('.') === value.length - 1) {
        value = value.replace('.', '');
      }
      if (!isNaN(parseFloat(value))) {
        if (!~value.indexOf('.')) {
          value = parseFloat(value);
        } else {
          value = parseFloat(value).toFixed(2);
        }
      }
      if (value > 99.99) {
        value = 99.99;
      }
      this.formData.interestRateConfigList[index][prop] = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.margin__top {
  margin-top: 11px;
}
.count-value {
  font-size: 14px;
  color: #303133;
}
.count-name {
  font-size: 14px;
  color: #606266;
}
.mt-50 {
  margin-top: 50px;
}
</style>
