<template>
  <div class="order-base__warp">
    <div class="erp-form-bar bar-sticky">
      <div class="erp-primary__btn" @click="saveFormData">保存</div>
      <div class="erp-default__btn" @click="back">返回</div>
    </div>
    <el-row type="flex" class="op-16">
      <el-col :span="12" style="margin-right: 80px">
        <div class="erp-form__title f16">订单信息</div>
        <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
          <el-col :span="12">
            <erp-view label="供应商合同号" type="edit">
              <el-input :model-value="!formData.supplierContractNo ? '-' : formData.supplierContractNo" :disabled="true"></el-input>
            </erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="客户" type="edit">
              <el-input v-model="formData.customerName" :disabled="true"></el-input>
            </erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="抬头" type="edit">
              <el-input :model-value="!formData.consigneeShortName ? '-' : formData.consigneeShortName" :disabled="true"></el-input>
            </erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="到港日" type="edit">
              <el-input :model-value="!formData.shippingEndDate ? '-' : formData.shippingEndDate" :disabled="true"></el-input>
            </erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="柜号" type="edit">
              <el-input :model-value="!formData.containerNo ? '-' : formData.containerNo" :disabled="true"></el-input>
            </erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="提单号" type="edit">
              <el-input :model-value="!formData.ladingBillNo ? '-' : formData.ladingBillNo" :disabled="true"></el-input>
            </erp-view>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <!--订单证件展示-->
    <div style="margin-top: 50px; margin-bottom: 12px" class="order-title__bar op-16">
      <div class="erp-form__title f16">证件</div>
    </div>
    <el-table
      :data="attachmentArrays"
      border
      class="egrid erp-table__list erp-view__table no-hover"
      style="width: 1381px; margin-left: 16px; margin-bottom: 30px"
      :span-method="spanMethods"
      v-if="status"
    >
      <el-table-column label="阶段" prop="stage" align="center" width="85"></el-table-column>
      <el-table-column label="证件类型" prop="attachmentTypeDesc" align="center" width="140" class-name="no-pd ">
        <template v-slot="scope">
          <el-input
            v-model="scope.row.type"
            placeholder
            v-if="(scope.$index == attachmentArrays.length - 1 && attachmentArrays.length < 31) || scope.$index == 20"
            :maxlength="12"
          >
          </el-input>
          <div :class="{ req: scope.row.required }" v-else class="line-cell">
            {{ scope.row.type }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="文件名" prop="name" align="center" show-overflow-tooltip>
        <template v-slot="scope" class="custom">
          <div v-if="scope.row.attachmentFiles && scope.row.attachmentFiles.length > 0" class="flex-link">
            <div v-for="(item, index) in scope.row.attachmentFiles" :key="index" :title="item.name.length >= 17 ? item.name : ''" class="show-link">
              {{ subUrl(item.name) }}
              <span class="el-icon-error" @click="delFile(scope.$index, index)"></span>
            </div>
          </div>
          <div v-else class="no-upload">未上传</div>
        </template>
      </el-table-column>
      <el-table-column label="上传人" prop="creator" align="center" show-overflow-tooltip width="140"></el-table-column>
      <el-table-column label="上传时间" prop="createdTime" align="center" show-overflow-tooltip width="170"></el-table-column>
      <el-table-column label="操作" prop align="center" width="105">
        <template v-slot="scope">
          <el-button
            type="primary"
            size="mini"
            plain
            v-if="scope.row.attachmentFiles && scope.row.attachmentFiles.length > 0"
            @click="upLoadFile(scope.row, scope.$index, true)"
            >编辑
          </el-button>
          <el-button size="mini" type="primary" plain v-else @click="upLoadFile(scope.row, scope.$index, false)">上传</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog v-model="uploadVisable" title="上传文件" width="1000px" @close="closeDialog" custom-class="upload-dialog">
      <SingleFileContent
        :fromType="1"
        @success="getContractUrls"
        :queryNum="0"
        :max="10"
        :fileList="currentFileArrays"
        class="line-box"
        v-if="uploadLoad"
      ></SingleFileContent>
      <template #footer>
        <div class="upload-dialog__footer">
          <el-button type="primary" size="small" @click="setUploadFile" :disabled="disabled">上传</el-button>
          <el-button size="small" @click="closeDialog">关闭</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import SingleFileContent from '@/components/FileContent/FileContent.vue';
import dayjs from 'dayjs';
import { errorTip, showFiles, successTip } from '@/utils/publicMethods';
import { httpPost } from '@/api';
import { store } from '@/store';
export default {
  components: {
    SingleFileContent,
  },
  props: {
    data: {
      // 获取传递过来的详情
      type: [Object],
      default: function () {
        return {
          id: 0,
          ladingBillNo: null, // 提单号
          orderId: 0,
          orderVersion: 0,
          shippingEndDate: null, // 到港日
          supplierContractNo: null, // 供应商合同号
          isNeedJr: '', // 是否提供供应链金融服务
          version: 0,
          isNewOrder: false, // 新旧订单
        };
      },
    },
  },
  data() {
    return {
      fromType: 1, // 组件展示类型,
      attachmentType: {
        // 选择类型
        label: null,
        value: null,
      },
      formData: {
        id: 0,
        ladingBillNo: null, // 提单号
        orderId: 0,
        orderVersion: 0,
        shippingEndDate: null, // 到港日
        supplierContractNo: null, // 供应商合同号
        attachmentType: null, // 证件类型
        name: null,
        url: null,
        version: 0,
        isNewOrder: false,
        customerContractNo: null,
      },
      dataQueryForm: {
        pageSize: 10,
        pageNum: 1,
        orderId: this.data.orderId,
      },
      attachmentList: [],
      uploadLoad: true,
      status: false,
      colArrays: {},
      colNames: ['stage'],
      formCount: 0, // 总文件下载数
      count: 0, /// / 下载文件临时下标
      downLock: false, // 多文件下载锁
      downloadFiles: [], // 下载文件临时数组
      showIndex: 0, // 当前点击的索引项
      currentType: 0,
      currentTypeValue: null, // 当前上传的证件类型中文名
      currentStage: null, // 当前上传的阶段
      currentFileArrays: [],
      tmpArrays: [],
      uploadVisable: false,
      disabled: false,
      resultArrays: null, // 保留接口返回的文件信息
      attachmentArrays: [],
    };
  },
  created() {
    this.formData = Object.assign({}, this.data);
    this.getOrderQhdcAttachmentList();
  },
  methods: {
    upLoadFile(row, index, isEdit) {
      this.currentTypeValue = row.type;
      this.currentStage = row.stage;
      if (this.currentTypeValue && this.currentTypeValue !== '') {
        if (isEdit === true) {
          this.currentFileArrays = JSON.parse(JSON.stringify(row.attachmentFiles));
        } else {
          this.currentFileArrays = null;
        }
        this.currentType = row.typeIndex;
        this.showIndex = index;
        this.uploadLoad = true;
        this.uploadVisable = true;
        this.disabled = true;
      } else {
        errorTip('请填写证件类型名称');
      }
    },
    delFile(rowIndex, index) {
      this.attachmentArrays[rowIndex].attachmentFiles.splice(index, 1);
      if (this.attachmentArrays[rowIndex].attachmentFiles && this.attachmentArrays[rowIndex].attachmentFiles.length <= 0) {
        this.attachmentArrays[rowIndex].creator = null;
        this.attachmentArrays[rowIndex].createdTime = null;
      }
    },
    setColArrays(props) {
      const dot = {};
      props.forEach((v) => {
        dot[v] = 0;
      });
      this.attachmentArrays.forEach((v, i) => {
        if (i === 0) {
          props.forEach((item) => {
            this.colArrays[item] = [];
            this.colArrays[item].push(1);
          });
        } else {
          props.forEach((prop) => {
            if (v[prop] === this.attachmentArrays[i - 1][prop] && v.stage === this.attachmentArrays[i - 1].stage) {
              // 必须是同柜的
              this.colArrays[prop][dot[prop]] += 1;
              this.colArrays[prop].push(0);
            } else {
              this.colArrays[prop].push(1);
              dot[prop] = i;
            }
          });
        }
      });
    },
    spanMethods({ column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        const prop = column.property; // 动态字段名
        let params = {
          rowspan: 1,
          colspan: 1,
        };
        if (this.colNames && this.colNames.length > 0) {
          this.colNames.forEach((v) => {
            if (v === prop) {
              const _row = this.colArrays[v][rowIndex];
              const _col = _row > 0 ? 1 : 0;
              params = {
                rowspan: _row,
                colspan: _col,
              };
            }
          });
        }

        return params;
      }
    },
    showFiles(name, url) {
      if (/\.(gif|jpg|jpeg|png|GIF|JPG|PNG|pdf|bmp|xlsx|xls|ppt|pptx|doc|docx)$/.test(url)) {
        showFiles(name, url);
      } else {
        errorTip('该文件不支持在线查看，请点击右方下载查看');
      }
    },
    createForm(url, name) {
      const Form = document.createElement('form');
      const NameInput = document.createElement('input');
      const FileInput = document.createElement('input');
      NameInput.name = 'fileName';
      FileInput.name = 'filePath';
      NameInput.value = name;
      FileInput.value = url;
      NameInput.type = 'hidden';
      FileInput.type = 'hidden';
      Form.appendChild(NameInput);
      Form.appendChild(FileInput);
      if (url.indexOf('fastdfs.maat.link') > -1) {
        // 根据不同的服务器路径切换不同域名配置
        Form.action = 'http://upload.maat.link/fastdfs/download/file';
      } else {
        if (url.indexOf('malleeglobal.com') > -1) {
          Form.action = 'https://upload.malleeglobal.com/fastdfs/download/file?time=' + new Date();
          Form.method = 'GET';
        } else {
          Form.action = process.env.ZIP_API_ROOT + '/fastdfs/download/file?time=' + new Date().getDate();
        }
      }
      Form.method = 'GET';
      document.body.appendChild(Form);
      Form.submit();
      this.count++;
      setTimeout(() => {
        document.body.removeChild(Form);
        if (this.count <= this.formCount - 1) {
          this.createForm(this.downloadFiles[this.count].url, this.downloadFiles[this.count].name);
        } else {
          this.downLock = false;
          this.formCount = 0;
          this.count = 0;
          this.downloadFiles = [];
        }
      }, 500);
    },
    downloadLoads(arrays) {
      if (this.downLock === false) {
        this.downLock = true;
        this.downloadFiles = arrays;
        this.formCount = arrays.length;
        this.createForm(arrays[0].url, arrays[0].name);
      } else {
        errorTip('正在下载文件');
      }
    },
    intAttachmentArrays(data) {
      const {
        attachment1 = {}, // 外商合同SC
        attachment2 = {}, // 形式发票PI
        attachment3 = {}, // 内外标签
        attachment13 = {}, // 正本-卫生证
        attachment14 = {}, // 正本-产地证
        attachment15 = {},
        attachment16 = {},
        attachment17 = {},
        attachment18 = {},
        attachment19 = {},
        attachment20 = {},
        attachment21 = {},
        attachment22 = {},
        attachment23 = {},
        attachment24 = {},
        attachmentVoList25,
        attachment26 = {},
        attachment28 = {},
        attachment29 = {},
        attachment31 = {}, // 正本-正本SC
        attachment32 = {}, // 正本-全套正本文件
        attachmentVoList30,
      } = data;

      let arrays = [
        {
          stage: '接单',
          type: '外商合同SC',
          required: false,
          typeIndex: 1,
          attachmentFiles: attachment1 && attachment1.attachmentList ? attachment1.attachmentList : null,
          createdTime: attachment1 && attachment1.createdTime ? attachment1.createdTime : '-',
          creator: attachment1 && attachment1.creator ? attachment1.creator : '-',
        },
        {
          stage: '接单',
          type: '形式发票PI',
          required: true,
          typeIndex: 2,
          attachmentFiles: attachment2 && attachment2.attachmentList ? attachment2.attachmentList : null,
          createdTime: attachment2 && attachment2.createdTime ? attachment2.createdTime : '-',
          creator: attachment2 && attachment2.creator ? attachment2.creator : '-',
        },
        {
          stage: '接单',
          type: '内外标签',
          typeIndex: 3,
          required: true,
          attachmentFiles: attachment3 && attachment3.attachmentList ? attachment3.attachmentList : null,
          createdTime: attachment3 && attachment3.createdTime ? attachment3.createdTime : '-',
          creator: attachment3 && attachment3.creator ? attachment3.creator : '-',
        },

        {
          stage: '副本',
          type: '副本文件',
          required: true,
          typeIndex: 26,
          attachmentFiles: attachment26 && attachment26.attachmentList ? attachment26.attachmentList : null,
          createdTime: attachment26 && attachment26.createdTime ? attachment26.createdTime : '-',
          creator: attachment26 && attachment26.creator ? attachment26.creator : '-',
        },
        {
          stage: '正本',
          type: '卫生证',
          typeIndex: 8,
          required: true,
          attachmentFiles: attachment13 && attachment13.attachmentList ? attachment13.attachmentList : null,
          createdTime: attachment13 && attachment13.createdTime ? attachment13.createdTime : '-',
          creator: attachment13 && attachment13.creator ? attachment13.creator : '-',
        },
        {
          stage: '正本',
          type: '产地证',
          typeIndex: 7,
          required: true,
          attachmentFiles: attachment14 && attachment14.attachmentList ? attachment14.attachmentList : null,
          createdTime: attachment14 && attachment14.createdTime ? attachment14.createdTime : '-',
          creator: attachment14 && attachment14.creator ? attachment14.creator : '-',
        },
        {
          stage: '正本',
          type: '提单',
          typeIndex: 10,
          required: true,
          attachmentFiles: attachment15 && attachment15.attachmentList ? attachment15.attachmentList : null,
          createdTime: attachment15 && attachment15.createdTime ? attachment15.createdTime : '-',
          creator: attachment15 && attachment15.creator ? attachment15.creator : '-',
        },
        {
          stage: '正本',
          type: '装箱单',
          typeIndex: 5,
          required: true,
          attachmentFiles: attachment16 && attachment16.attachmentList ? attachment16.attachmentList : null,
          createdTime: attachment16 && attachment16.createdTime ? attachment16.createdTime : '-',
          creator: attachment16 && attachment16.creator ? attachment16.creator : '-',
        },
        {
          stage: '正本',
          type: '发票',
          typeIndex: 4,
          required: true,
          attachmentFiles: attachment17 && attachment17.attachmentList ? attachment17.attachmentList : null,
          createdTime: attachment17 && attachment17.createdTime ? attachment17.createdTime : '-',
          creator: attachment17 && attachment17.creator ? attachment17.creator : '-',
        },
        {
          stage: '正本',
          type: '正本SC',
          typeIndex: 22,
          required: true,
          attachmentFiles: attachment31 && attachment31.attachmentList ? attachment31.attachmentList : null,
          createdTime: attachment31 && attachment31.createdTime ? attachment31.createdTime : '-',
          creator: attachment31 && attachment31.creator ? attachment31.creator : '-',
        },
        {
          stage: '正本',
          type: '批次信息',
          typeIndex: 6,
          required: true,
          attachmentFiles: attachment18 && attachment18.attachmentList ? attachment18.attachmentList : null,
          createdTime: attachment18 && attachment18.createdTime ? attachment18.createdTime : '-',
          creator: attachment18 && attachment18.creator ? attachment18.creator : '-',
        },

        {
          stage: '正本',
          type: '清真证',
          typeIndex: 9,
          required: false,
          attachmentFiles: attachment19 && attachment19.attachmentList ? attachment19.attachmentList : null,
          createdTime: attachment19 && attachment19.createdTime ? attachment19.createdTime : '-',
          creator: attachment19 && attachment19.creator ? attachment19.creator : '-',
        },
        {
          stage: '正本',
          type: '无木证明',
          typeIndex: 11,
          required: false,
          attachmentFiles: attachment20 && attachment20.attachmentList ? attachment20.attachmentList : null,
          createdTime: attachment20 && attachment20.createdTime ? attachment20.createdTime : '-',
          creator: attachment20 && attachment20.creator ? attachment20.creator : '-',
        },
        {
          stage: '正本',
          type: '保险单',
          typeIndex: 12,
          required: false,
          attachmentFiles: attachment21 && attachment21.attachmentList ? attachment21.attachmentList : null,
          createdTime: attachment21 && attachment21.createdTime ? attachment21.createdTime : '-',
          creator: attachment21 && attachment21.creator ? attachment21.creator : '-',
        },
        {
          stage: '正本',
          type: '全套正本文件',
          typeIndex: 23,
          required: true,
          attachmentFiles: attachment32 && attachment32.attachmentList ? attachment32.attachmentList : null,
          createdTime: attachment32 && attachment32.createdTime ? attachment32.createdTime : '-',
          creator: attachment32 && attachment32.creator ? attachment32.creator : '-',
        },
        {
          stage: '报关行返回件',
          type: '报关单',
          typeIndex: 13,
          required: true,
          attachmentFiles: attachment22 && attachment22.attachmentList ? attachment22.attachmentList : null,
          createdTime: attachment22 && attachment22.createdTime ? attachment22.createdTime : '-',
          creator: attachment22 && attachment22.creator ? attachment22.creator : '-',
        },
        {
          stage: '报关行返回件',
          type: '检疫证',
          typeIndex: 14,
          required: true,
          attachmentFiles: attachment23 && attachment23.attachmentList ? attachment23.attachmentList : null,
          createdTime: attachment23 && attachment23.createdTime ? attachment23.createdTime : '-',
          creator: attachment23 && attachment23.creator ? attachment23.creator : '-',
        },
        {
          stage: '报关行返回件',
          type: '税单',
          typeIndex: 15,
          required: true,
          attachmentFiles: attachment24 && attachment24.attachmentList ? attachment24.attachmentList : null,
          createdTime: attachment24 && attachment24.createdTime ? attachment24.createdTime : '-',
          creator: attachment24 && attachment24.creator ? attachment24.creator : '-',
        },
        {
          stage: '抗疫',
          type: '核酸检测',
          typeIndex: 19,
          required: false,
          attachmentFiles: attachment28 && attachment28.attachmentList ? attachment28.attachmentList : null,
          createdTime: attachment28 && attachment28.createdTime ? attachment28.createdTime : '-',
          creator: attachment28 && attachment28.creator ? attachment28.creator : '-',
        },
        {
          stage: '抗疫',
          type: '消杀证明',
          typeIndex: 20,
          required: false,
          attachmentFiles: attachment29 && attachment29.attachmentList ? attachment29.attachmentList : null,
          createdTime: attachment29 && attachment29.createdTime ? attachment29.createdTime : '-',
          creator: attachment29 && attachment29.creator ? attachment29.creator : '-',
        },
        {
          stage: '抗疫',
          type: attachmentVoList30 ? attachmentVoList30[0].attachmentTypeDisplay : '',
          typeIndex: 21,
          required: false,
          attachmentFiles: attachmentVoList30 && attachmentVoList30[0].attachmentList ? attachmentVoList30[0].attachmentList : null,
          createdTime: attachmentVoList30 && attachmentVoList30[0].createdTime ? attachmentVoList30[0].createdTime : '-',
          creator: attachmentVoList30 && attachmentVoList30[0].creator ? attachmentVoList30[0].creator : '-',
        },
      ];

      const lastObj = {
        stage: '其他',
        type: null,
        typeIndex: 17,
        required: false,
        attachmentFiles: null,
        createdTime: '-',
        creator: '-',
      };

      if (attachmentVoList25 && attachmentVoList25.length > 0) {
        const otherArrays = [];
        attachmentVoList25.forEach((v) => {
          const obj = {
            stage: '其他',
            typeIndex: 17,
            type: v.attachmentTypeDisplay,
            required: false,
            attachmentFiles: v.attachmentList ? v.attachmentList : null,
            createdTime: v.createdTime ? v.createdTime : '-',
            creator: v.creator ? v.creator : '-',
          };
          otherArrays.push(obj);
        });
        if (otherArrays.length < 9) {
          otherArrays.push(lastObj);
        }

        arrays = arrays.concat(otherArrays);
      } else {
        arrays.push(lastObj);
      }
      this.attachmentArrays = arrays;
      this.status = true;
    },
    async getOrderQhdcAttachmentList() {
      const res = await httpPost('/malicrm/order/queryOrderAttchement', {
        orderId: this.data.orderId,
      });
      if (res.code === 200) {
        this.resultArrays = res.result;
        this.intAttachmentArrays(res.result);
        this.setColArrays(this.colNames);
      } else {
        errorTip(res.message);
      }
    },
    closeDialog() {
      this.currentFileArrays = [];
      setTimeout(() => {
        this.uploadLoad = false;
      }, 300);

      this.uploadVisable = false;
    },

    findChart(str, cha) {
      const strIndexArr = [];
      for (let i = 0; i < str.length; i++) {
        if (cha === str[i]) {
          strIndexArr.push(i);
        }
      }
      const len = strIndexArr.length;
      if (len >= 2) {
        return strIndexArr[len - 2];
      } else {
        return -1;
      }
    },
    fileRule(fileData, item) {
      const data = JSON.parse(JSON.stringify(fileData));
      let businessContractNo = this.formData.businessContractNo; // 销售合同号
      const supplierContractNo = this.formData.supplierContractNo; // 供应商合同号
      const customerContractNo = this.formData.customerContractNo; // 销售合同号客户编号+合同编号
      const type = item.type;
      const stage = item.stage;
      // 1.如果是旧的订单，则#销售合同号尾号(例如721-3)                     #-#供应商合同号#-#阶段#-#证件类型#
      // 2.如果是新的订单，则#销售合同号客户编号+合同编号(例如6003-0003)     #-#供应商合同号#-#阶段#-#证件类型#
      const { isNewOrder } = this.data;
      let index;
      if (isNewOrder) {
        // 新订单
        index = this.findChart(customerContractNo, '-', 1);
        businessContractNo = customerContractNo;
      } else {
        // 旧订单
        index = this.findChart(businessContractNo, '-', 1);
      }
      if (index > -1) {
        businessContractNo = businessContractNo.substring(index + 1);
        let name = businessContractNo;
        if (supplierContractNo && businessContractNo !== '') {
          // 有销售合同号
          name += '-' + supplierContractNo;
        }
        if (stage === '正本') {
          name += '-' + stage;
        }
        name += '-' + type;
        data.forEach((v, i) => {
          // 获取文件后缀名
          const suffix = v.url.substring(v.url.lastIndexOf('.') + 1);
          v.name = name + 0 + (i + 1) + '.' + suffix;
        });
        return data;
      } else {
        // 如果销售合同号不符合规则就直接返回
        return data;
      }
    },
    setUploadFile() {
      const creator = store.state.user.userName;
      const createdTime = dayjs().format('YYYY-MM-DD HH:mm');

      this.attachmentArrays[this.showIndex].attachmentFiles = this.fileRule(this.tmpArrays, this.attachmentArrays[this.showIndex]);
      this.attachmentArrays[this.showIndex].creator = this.tmpArrays && this.tmpArrays.length > 0 ? creator : null;
      this.attachmentArrays[this.showIndex].createdTime = this.tmpArrays && this.tmpArrays.length > 0 ? createdTime : null;
      this.disabled = true;
      this.currentFileArrays = [];
      this.tmpArrays = [];
      this.currentType = 0;
      if (this.showIndex === this.attachmentArrays.length - 1) {
        // 上传其他
        const lastObj = {
          stage: '其他',
          type: null,
          typeIndex: 17,
          required: false,
          attachmentFiles: null,
          createdTime: '-',
          creator: '-',
        };
        if (this.attachmentArrays.length <= 28) {
          this.status = false;
          setTimeout(() => {
            this.attachmentArrays.push(lastObj);
            this.setColArrays(this.colNames);
            this.status = true;
          }, 0);
        }
      }
      this.closeDialog();
    },
    openUploadFileDialog() {
      this.uploadVisable = true;
    },

    getContractUrls(data) {
      // 获取到上传的文件
      let resource = null;
      if (this.currentStage === '正本') {
        resource = 2;
      }
      if (this.currentStage === '副本') {
        resource = 1;
      }
      const attachmentList = [];
      data.forEach((v) => {
        const data = {
          attachmentValue: this.currentTypeValue,
          attachmentType: this.currentType,
          resource: resource,
          name: v.name,
          url: v.url,
        };
        attachmentList.push(data);
      });
      this.tmpArrays = attachmentList;
      this.disabled = false;
    },
    /**
     * 保存编辑信息
     */
    async saveFormData() {
      let list = [];
      this.attachmentArrays.forEach((v) => {
        if (v.attachmentFiles && v.attachmentFiles.length > 0 && v.type && v.type !== '') {
          list = list.concat(v.attachmentFiles);
        }
      });
      const res = await httpPost('/malicrm/order/uploadOrderQhdcAttachment', {
        attachmentList: list,
        orderId: this.data.orderId,
      });
      if (res.code === 200) {
        successTip('保存成功');
        this.back();
      } else {
        errorTip(res.message);
      }
    },
    back() {
      // 返回详情页
      this.$emit('back', 4);
    },
    subUrl(val) {
      const suffix = val.substr(val.lastIndexOf('.'));
      let str = val.replace(suffix, '');
      if (str.length <= 40) {
        return val;
      } else {
        str = str.substr(0, 40) + '..' + suffix;
        return str;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.line-box {
  padding: 15px;
  border: 1px dashed #dcdfe6;
  margin: 0 15px 15px 15px;
}
.line-cell {
  line-height: 1.25;
}
.card-upload__btns {
  display: flex;
  align-items: center;
  > * {
    margin-right: 8px;
  }
}
.flex-link {
  display: flex;
  flex-wrap: wrap;
  padding: 7px 0 5px 0;
}
.show-link {
  color: #303133;
  margin-right: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  &:hover {
    color: #2878ff;
  }
  .el-icon-error {
    color: #c1c4cc;
    position: relative;
    top: 1px;
    left: 3px;
  }
}
.req {
  &::after {
    content: '*';
    color: #ff2619;
    margin-left: 3px;
  }
}
</style>
