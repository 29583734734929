<template>
  <div class="order-base__warp">
    <div class="erp-form-bar bar-sticky">
      <div
        class="erp-primary__btn"
        @click="editCardInfo"
        v-if="
          ((data.status == 1 || data.status == 4) && hasPermission('erp:order:saveOrderQhdcBaseInfo')) ||
          ((data.status == 1 || data.status == 4) && hasPermission('erp:order:saveOrderQhdcBaseInfoAll'))
        "
      >
        编辑
      </div>
      <div class="erp-primary__btn plain" @click="finishOrder" v-if="data.status == 1 && hasPermission('erp:order:closeOpenOrderQhdc')">完结订单</div>
      <div class="erp-primary__btn plain" @click="closeOrder(1)" v-if="data.status == 4 && hasPermission('erp:order:closeOpenOrderQhdc')">关闭订单</div>
      <div
        class="erp-primary__btn plain"
        @click="reStoreOrder(data.status)"
        v-if="(data.status == 2 || data.status == 3) && hasPermission('erp:order:closeOpenOrderQhdc')"
      >
        还原
      </div>
      <div class="erp-default__btn" @click="back">返回</div>
    </div>
    <!-- 55等分布局 -->
    <el-row type="flex" class="op-16">
      <el-col :span="12" style="margin-right: 80px">
        <div class="erp-form__title f16">订单信息</div>
        <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
          <el-col :span="12">
            <erp-view label="供应商合同号" :value="data.supplierContractNo"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="客户" :value="data.customerName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="抬头" :value="data.consigneeShortName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="到港日" :value="data.shippingEndDate"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="柜号" :value="data.containerNo"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="提单号" :value="data.ladingBillNo"></erp-view>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="12"></el-col>
    </el-row>
    <div style="margin-top: 50px" class="order-title__bar op-16">
      <div class="erp-form__title f16">证件</div>
    </div>
    <el-table
      :data="attachmentArrays"
      border
      class="egrid erp-table__list erp-view__table"
      style="width: 1378px; margin-left: 16px; margin-bottom: 30px"
      :span-method="spanMethods"
    >
      <el-table-column label="阶段" prop="stage" align="center" width="85"></el-table-column>
      <el-table-column label="证件类型" prop="attachmentTypeDisplay" align="center" width="140" class-name="line-cell">
        <template v-slot="scope">
          <div :class="{ req: scope.row.required }" class="line-cell">
            {{ scope.row.type }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="文件名" prop="name" align="center" width="740">
        <template v-slot="scope" class="custom">
          <div v-if="scope.row.attachmentFiles && scope.row.attachmentFiles.length > 0" class="flex-link">
            <div
              v-for="(item, index) in scope.row.attachmentFiles"
              :key="index"
              @click="showFiles(item.name, item.url)"
              class="show-link"
              :title="item.name.length >= 17 ? item.name : ''"
            >
              {{ subUrl(item.name) }}
            </div>
          </div>
          <div v-else class="no-upload">未上传</div>
        </template>
      </el-table-column>
      <el-table-column label="上传人" prop="creator" align="center" show-overflow-tooltip width="140"></el-table-column>
      <el-table-column label="上传时间" prop="createdTime" align="center" show-overflow-tooltip width="170"> </el-table-column>
      <el-table-column label="操作" prop="createdTime" align="center" show-overflow-tooltip width="102">
        <template v-slot="scope">
          <el-button
            type="primary"
            size="mini"
            @click="downloadLoads(scope.row.attachmentFiles, scope.row)"
            plain
            v-if="scope.row.attachmentFiles && scope.row.attachmentFiles.length > 0"
            >下载
          </el-button>
          <div v-else>-</div>
        </template>
      </el-table-column>
    </el-table>
    <InputDialog ref="inputDialog" width="360px"></InputDialog>
  </div>
</template>

<script>
import InputDialog from '@/components/InputDialog/InputDialog.vue';
import {
  erpConfirm,
  successTip,
  errorTip,
  hasPermission,
  getRouterParams,
  showFiles,
  handleBatchDownload,
  successMessage,
  handleTableData,
} from '@/utils/publicMethods';
import { httpPost } from '@/api';

export default {
  components: {
    InputDialog,
  },
  props: {
    data: {
      // 获取传递过来的详情
      type: [Object],
      default: function () {
        return {
          id: 0,
          ladingBillNo: null, // 提单号
          orderId: 0,
          orderVersion: 0,
          shippingEndDate: null, // 到港日
          supplierContractNo: null, // 供应商合同号
          version: 0,
        };
      },
    },
  },
  data() {
    return {
      dataQueryForm: {
        pageSize: 10,
        pageNum: 1,
        orderId: this.data.orderId,
      },
      attachmentList: [],
      isShow: false,

      status: false,
      colArrays: {},
      colNames: ['stage'],
      formCount: 0, // 总文件下载数
      count: 0, /// / 下载文件临时下标
      downLock: false, // 多文件下载锁
      downloadFiles: [], // 下载文件临时数组
      attachmentArrays: [],
    };
  },
  created() {
    this.getOrderQhdcAttachmentList();
  },
  methods: {
    hasPermission(bindPermissions) {
      return hasPermission(bindPermissions);
    },
    setColArrays(props) {
      const dot = {};
      props.forEach((v) => {
        dot[v] = 0;
      });
      this.attachmentArrays.forEach((v, i) => {
        if (i === 0) {
          props.forEach((item) => {
            this.colArrays[item] = [];
            this.colArrays[item].push(1);
          });
        } else {
          props.forEach((prop) => {
            if (v[prop] === this.attachmentArrays[i - 1][prop] && v.stage === this.attachmentArrays[i - 1].stage) {
              // 必须是同柜的
              this.colArrays[prop][dot[prop]] += 1;
              this.colArrays[prop].push(0);
            } else {
              this.colArrays[prop].push(1);
              dot[prop] = i;
            }
          });
        }
      });
    },

    spanMethods({ column, rowIndex, columnIndex }) {
      // if (columnIndex === 0) {
      //   const prop = column.property; // 动态字段名
      //   let params = {
      //     rowspan: 1,
      //     colspan: 1,
      //   };
      //   if (this.colNames && this.colNames.length > 0) {
      //     this.colNames.forEach((v) => {
      //       if (v === prop) {
      //         const _row = this.colArrays[v][rowIndex];
      //         const _col = _row > 0 ? 1 : 0;
      //         params = {
      //           rowspan: _row,
      //           colspan: _col,
      //         };
      //       }
      //     });
      //   }
      //   return params;
      // }
      let data = handleTableData(this.attachmentArrays, 'stage', 0, rowIndex, columnIndex);
      return data;
    },
    showFiles(name, url) {
      if (/\.(gif|jpg|jpeg|png|GIF|JPG|PNG|pdf|PDF|bmp|xlsx|xls|ppt|pptx|doc|docx|DOC|DOCX)$/.test(url)) {
        showFiles(name, url);
      } else {
        errorTip('该文件不支持在线查看，请点击右方下载查看');
      }
    },
    createForm(url, name) {
      showFiles(name, url, false);
    },
    downloadLoads(arrays, row) {
      this.downloadFiles = arrays;
      this.formCount = arrays.length;
      // 少于两个还是继续用这个下载
      if (arrays.length < 2) {
        this.createForm(arrays[0].url, arrays[0].name);
      } else {
        this.handleBatchDownload(arrays, row);
      }
    },
    handleBatchDownload(selectImgList, row) {
      // 多个文件，生成文件下载包
      const filesArrays = [];
      selectImgList.forEach((v) => {
        const files = {
          name: v.name,
          url: v.url,
        };
        filesArrays.push(files);
      });
      const zipName = row.stage + '-' + row.type + '.zip';
      successMessage('正在下载，请稍后');
      handleBatchDownload(filesArrays, zipName);
    },
    intAttachmentArrays(data) {
      const {
        attachment1 = {},
        attachment2 = {},
        attachment3 = {},
        attachment13 = {},
        attachment14 = {},
        attachment15 = {},
        attachment16 = {},
        attachment17 = {},
        attachment18 = {},
        attachment19 = {},
        attachment20 = {},
        attachment21 = {},
        attachment22 = {},
        attachment23 = {},
        attachment24 = {},
        attachmentVoList25,
        attachment26,

        attachment28 = {},
        attachment29 = {},
        attachment31 = {}, // 正本-正本SC
        attachment32 = {}, // 正本-全套正本文件
        attachmentVoList30,
      } = data;
      let arrays = [
        {
          stage: '接单',
          type: '外商合同SC',
          required: false,
          attachmentFiles: attachment1 && attachment1.attachmentList ? attachment1.attachmentList : null,
          createdTime: attachment1 && attachment1.createdTime ? attachment1.createdTime : '-',
          creator: attachment1 && attachment1.creator ? attachment1.creator : '-',
        },
        {
          stage: '接单',
          type: '形式发票PI',
          required: true,
          attachmentFiles: attachment2 && attachment2.attachmentList ? attachment2.attachmentList : null,
          createdTime: attachment2 && attachment2.createdTime ? attachment2.createdTime : '-',
          creator: attachment2 && attachment2.creator ? attachment2.creator : '-',
        },
        {
          stage: '接单',
          type: '内外标签',
          required: true,
          attachmentFiles: attachment3 && attachment3.attachmentList ? attachment3.attachmentList : null,
          createdTime: attachment3 && attachment3.createdTime ? attachment3.createdTime : '-',
          creator: attachment3 && attachment3.creator ? attachment3.creator : '-',
        },
        {
          stage: '副本',
          type: '副本文件',
          required: false,
          attachmentFiles: attachment26 && attachment26.attachmentList ? attachment26.attachmentList : null,
          createdTime: attachment26 && attachment26.createdTime ? attachment26.createdTime : '-',
          creator: attachment26 && attachment26.creator ? attachment26.creator : '-',
        },

        {
          stage: '正本',
          type: '卫生证',
          required: true,
          attachmentFiles: attachment13 && attachment13.attachmentList ? attachment13.attachmentList : null,
          createdTime: attachment13 && attachment13.createdTime ? attachment13.createdTime : '-',
          creator: attachment13 && attachment13.creator ? attachment13.creator : '-',
        },
        {
          stage: '正本',
          type: '产地证',
          required: true,
          attachmentFiles: attachment14 && attachment14.attachmentList ? attachment14.attachmentList : null,
          createdTime: attachment14 && attachment14.createdTime ? attachment14.createdTime : '-',
          creator: attachment14 && attachment14.creator ? attachment14.creator : '-',
        },
        {
          stage: '正本',
          type: '提单',
          required: true,
          attachmentFiles: attachment15 && attachment15.attachmentList ? attachment15.attachmentList : null,
          createdTime: attachment15 && attachment15.createdTime ? attachment15.createdTime : '-',
          creator: attachment15 && attachment15.creator ? attachment15.creator : '-',
        },
        {
          stage: '正本',
          type: '装箱单',
          required: true,
          attachmentFiles: attachment16 && attachment16.attachmentList ? attachment16.attachmentList : null,
          createdTime: attachment16 && attachment16.createdTime ? attachment16.createdTime : '-',
          creator: attachment16 && attachment16.creator ? attachment16.creator : '-',
        },
        {
          stage: '正本',
          type: '发票',
          required: true,
          attachmentFiles: attachment17 && attachment17.attachmentList ? attachment17.attachmentList : null,
          createdTime: attachment17 && attachment17.createdTime ? attachment17.createdTime : '-',
          creator: attachment17 && attachment17.creator ? attachment17.creator : '-',
        },
        {
          stage: '正本',
          type: '正本SC',
          typeIndex: 22,
          required: true,
          attachmentFiles: attachment31 && attachment31.attachmentList ? attachment31.attachmentList : null,
          createdTime: attachment31 && attachment31.createdTime ? attachment31.createdTime : '-',
          creator: attachment31 && attachment31.creator ? attachment31.creator : '-',
        },
        {
          stage: '正本',
          type: '批次信息',
          required: true,
          attachmentFiles: attachment18 && attachment18.attachmentList ? attachment18.attachmentList : null,
          createdTime: attachment18 && attachment18.createdTime ? attachment18.createdTime : '-',
          creator: attachment18 && attachment18.creator ? attachment18.creator : '-',
        },

        {
          stage: '正本',
          type: '清真证',
          required: false,
          attachmentFiles: attachment19 && attachment19.attachmentList ? attachment19.attachmentList : null,
          createdTime: attachment19 && attachment19.createdTime ? attachment19.createdTime : '-',
          creator: attachment19 && attachment19.creator ? attachment19.creator : '-',
        },

        {
          stage: '正本',
          type: '无木证明',
          required: false,
          attachmentFiles: attachment20 && attachment20.attachmentList ? attachment20.attachmentList : null,
          createdTime: attachment20 && attachment20.createdTime ? attachment20.createdTime : '-',
          creator: attachment20 && attachment20.creator ? attachment20.creator : '-',
        },
        {
          stage: '正本',
          type: '保险单',
          required: false,
          attachmentFiles: attachment21 && attachment21.attachmentList ? attachment21.attachmentList : null,
          createdTime: attachment21 && attachment21.createdTime ? attachment21.createdTime : '-',
          creator: attachment21 && attachment21.creator ? attachment21.creator : '-',
        },
        {
          stage: '正本',
          type: '全套正本文件',
          typeIndex: 23,
          required: true,
          attachmentFiles: attachment32 && attachment32.attachmentList ? attachment32.attachmentList : null,
          createdTime: attachment32 && attachment32.createdTime ? attachment32.createdTime : '-',
          creator: attachment32 && attachment32.creator ? attachment32.creator : '-',
        },
        {
          stage: '报关行返回件',
          type: '报关单',
          required: true,
          attachmentFiles: attachment22 && attachment22.attachmentList ? attachment22.attachmentList : null,
          createdTime: attachment22 && attachment22.createdTime ? attachment22.createdTime : '-',
          creator: attachment22 && attachment22.creator ? attachment22.creator : '-',
        },
        {
          stage: '报关行返回件',
          type: '检疫证',
          required: true,
          attachmentFiles: attachment23 && attachment23.attachmentList ? attachment23.attachmentList : null,
          createdTime: attachment23 && attachment23.createdTime ? attachment23.createdTime : '-',
          creator: attachment23 && attachment23.creator ? attachment23.creator : '-',
        },
        {
          stage: '报关行返回件',
          type: '税单',
          required: true,
          attachmentFiles: attachment24 && attachment24.attachmentList ? attachment24.attachmentList : null,
          createdTime: attachment24 && attachment24.createdTime ? attachment24.createdTime : '-',
          creator: attachment24 && attachment24.creator ? attachment24.creator : '-',
        },
        {
          stage: '抗疫',
          type: '核酸检测',
          typeIndex: 19,
          required: true,
          attachmentFiles: attachment28 && attachment28.attachmentList ? attachment28.attachmentList : null,
          createdTime: attachment28 && attachment28.createdTime ? attachment28.createdTime : '-',
          creator: attachment28 && attachment28.creator ? attachment28.creator : '-',
        },
        {
          stage: '抗疫',
          type: '消杀证明',
          typeIndex: 20,
          required: true,
          attachmentFiles: attachment29 && attachment29.attachmentList ? attachment29.attachmentList : null,
          createdTime: attachment29 && attachment29.createdTime ? attachment29.createdTime : '-',
          creator: attachment29 && attachment29.creator ? attachment29.creator : '-',
        },
        // {
        //   stage: '抗疫',
        //   type: attachmentVoList30 ? attachmentVoList30[0].attachmentTypeDisplay : '',
        //   typeIndex: 21,
        //   required: false,
        //   attachmentFiles:
        //     attachmentVoList30 && attachmentVoList30[0].attachmentList
        //       ? attachmentVoList30[0].attachmentList
        //       : null,
        //   createdTime:
        //     attachmentVoList30 && attachmentVoList30[0].createdTime
        //       ? attachmentVoList30[0].createdTime
        //       : '-',
        //   creator:
        //     attachmentVoList30 && attachmentVoList30[0].creator ? attachmentVoList30[0].creator : '-'
        // }
      ];

      // 12-17 正本未分类不上传显示隐藏
      if (attachmentVoList25 && attachmentVoList25.length > 0) {
        const otherArrays = [];
        attachmentVoList25.forEach((v) => {
          const obj = {
            stage: '其他',
            type: v.attachmentTypeDisplay,
            required: false,
            attachmentFiles: v.attachmentList ? v.attachmentList : null,
            createdTime: v.createdTime ? v.createdTime : '-',
            creator: v.creator ? v.creator : '-',
          };
          otherArrays.push(obj);
        });
        arrays = arrays.concat(otherArrays);
      }
      const customKY = {
        stage: '抗疫',
        type: attachmentVoList30 ? attachmentVoList30[0].attachmentTypeDisplay : '',
        typeIndex: 21,
        required: false,
        attachmentFiles: attachmentVoList30 && attachmentVoList30[0].attachmentList ? attachmentVoList30[0].attachmentList : null,
        createdTime: attachmentVoList30 && attachmentVoList30[0].createdTime ? attachmentVoList30[0].createdTime : '-',
        creator: attachmentVoList30 && attachmentVoList30[0].creator ? attachmentVoList30[0].creator : '-',
      };
      // 如果抗疫自定义项有值，则加入列表
      if (attachmentVoList30 !== null) {
        const kyIndex = arrays.findIndex((item) => item.type === '消杀证明');
        arrays.splice(kyIndex + 1, 0, customKY);
      }
      this.attachmentArrays = arrays;
      this.status = true;
    },
    async getOrderQhdcAttachmentList() {
      const res = await httpPost('/malicrm/order/queryOrderAttchement', {
        orderId: this.data.orderId,
      });
      if (res.code === 200) {
        this.intAttachmentArrays(res.result);
        console.log('this.attachmentArrays', this.attachmentArrays);
        this.setColArrays(this.colNames);
      } else {
        errorTip(res.message);
        this.intAttachmentArrays({});
        this.setColArrays(this.colNames);
      }
    },
    finishOrder() {
      this.$refs.inputDialog.init('提示', '确定需要完结当前订单吗？完结后的订单不可再做修改。').then(async (val) => {
        const res = await httpPost('/malicrm/order/finishOrder', {
          orderId: this.data.orderId,
          finishDate: val,
        });
        if (res.code === 200) {
          this.$emit('back', 1);
          successTip('已完结订单');
        } else {
          errorTip(res.message);
        }
      });
    },
    reOpenOrder() {
      erpConfirm('确定要恢复当前订单状态为进行中吗？').then(async () => {
        const res = await httpPost('/malicrm/order/reOpenOrder', {
          orderId: this.data.orderId,
        });
        if (res.code === 200) {
          this.$emit('back', 1);
          successTip('已恢复订单');
        } else {
          errorTip(res.message);
        }
      });
    },
    reStoreOrder(status) {
      // 还原判断
      erpConfirm(status === 2 ? '确定要将当前订单还原为“已取消”的状态吗？' : '确定要将当前订单还原为“进行中”的状态吗？')
        .then(async () => {
          const url = status === 2 ? '/malicrm/order/closeOpenOrderQhdc' : '/malicrm/order/reOpenOrder';
          const data = {
            id: this.data.id,
            operType: status,
            orderId: this.data.orderId,
            orderVersion: this.data.orderVersion,
            version: this.data.version,
          };
          const res = await httpPost(url, data);
          if (res.code === 200) {
            successTip('已还原订单');
            this.$emit('back', 1);
          } else {
            errorTip(res.message);
          }
        })
        .catch(() => {
          return false;
        });
    },
    closeOrder(status) {
      // 关闭还原订单
      erpConfirm('确定需要关闭当前订单吗？关闭后的订单不可再做修改。')
        .then(async () => {
          const res = await httpPost('/malicrm/order/closeOpenOrderQhdc', {
            id: this.data.id,
            operType: status,
            orderId: this.data.orderId,
            orderVersion: this.data.orderVersion,
            version: this.data.version,
          });
          if (res.code === 200) {
            successTip('已关闭订单');
            this.$emit('back', 1);
          } else {
            errorTip(res.message);
          }
        })
        .catch(() => {
          return false;
        });
    },
    back() {
      const { orderId, name, version } = getRouterParams('OrderDetail');
      this.$router.push({
        name: name,
        params: {
          id: orderId,
          version: version,
        },
      });
    },
    editCardInfo() {
      this.$emit('edit', 4);
    },
    subUrl(val) {
      const suffix = val.substr(val.lastIndexOf('.'));
      let str = val.replace(suffix, '');
      if (str.length <= 20) {
        return val;
      } else {
        str = str.substr(0, 20) + '..' + suffix;
        return str;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.line-cell {
  line-height: 1.25;
}
.order-title__bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 11px;
}
.count-value {
  font-size: 14px;
  color: #303133;
}
.count-name {
  font-size: 14px;
  color: #606266;
}
.flex-link {
  display: flex;
  flex-wrap: wrap;
  padding: 7px 0 5px 0;
}
.show-link {
  text-decoration: underline;
  color: #2878ff;
  margin-right: 30px;
  cursor: pointer;
  margin-bottom: 5px;
}
</style>
