<template>
  <div class="order-base__warp">
    <div class="erp-form-bar bar-sticky">
      <div
        class="erp-primary__btn"
        @click="editBaseInfo"
        v-if="
          ((data.status == 1 || data.status == 4) && hasPermission('erp:order:saveOrderQhdcBaseInfo')) ||
          ((data.status == 1 || data.status == 4) && hasPermission('erp:order:saveOrderQhdcBaseInfoAll'))
        "
      >
        编辑
      </div>
      <div class="erp-primary__btn plain" @click="finishOrder" v-if="data.status == 1 && hasPermission('erp:order:closeOpenOrderQhdc')">完结订单</div>
      <div class="erp-primary__btn plain" @click="closeOrder(1)" v-if="data.status == 4 && hasPermission('erp:order:closeOpenOrderQhdc')">关闭订单</div>
      <div
        class="erp-primary__btn plain"
        @click="reStoreOrder(data.status)"
        v-if="(data.status == 2 || data.status == 3) && hasPermission('erp:order:closeOpenOrderQhdc')"
      >
        还原
      </div>
      <div class="erp-default__btn" @click="back">返回</div>
    </div>
    <!-- 55等分布局 -->

    <el-row type="flex" justify="space-between" class="op-16" :gutter="80">
      <el-col :span="12">
        <div class="erp-form__title f16">订单信息</div>
        <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
          <el-col :span="12">
            <erp-view label="销售合同号" :value="data.businessContractNo"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="客户" :value="data.customerName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="签约主体" :value="data.contractPrincipalName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="订单状态" :value="data.statusDisplay"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="供应商合同号" :value="data.supplierContractNo"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="供应商" :value="data.supplierName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="发票号" :value="data.invoiceNo"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="大类" :value="data.categoryDisplay"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="国家" :value="data.countryDisplay"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="条款" :value="data.incotermDisplay"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="保证金比例(%)" :value="data.depositRateDisplay"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="口岸" :value="data.portName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="资金方" :value="data.fundingShortName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="抬头" :value="data.consigneeShortName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="是否代采" :value="data.isNeedCgDesc"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="柜型" :value="data.containerTypeDisplay"></erp-view>
          </el-col>

          <el-col :span="12">
            <erp-view label="是否买保险" :value="data.isNeedInsuranceDesc" class="insurance">
              <template #btn>
                <span @click="policyDialog" class="custom-btn" v-if="data.isNeedInsuranceDesc === '是'">保单</span>
              </template>
            </erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="付款方式" :value="data.payTypeDesc"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="客户指定汇率" :value="data.prescribedRate"></erp-view>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="12">
        <div class="erp-form__title f16">物流信息</div>
        <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
          <el-col :span="12">
            <erp-view label="启运日" :value="data.shippingStartDate"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="到港日" :value="data.shippingEndDate"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="起运港" :value="data.portLoading"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="投保日" :value="data.insureTime"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="船公司" :value="data.companyDesc">
              <a :href="data.url" class="record__link link-ellipsis" style="" target="_target" :title="data.url"> {{ filterName(data.companyDesc) }}</a>
            </erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="船名航次" :value="data.shippingInfo"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="柜号" :value="data.containerNo"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="提单号" :value="data.ladingBillNo"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="生产日期" :value="data.produceDate"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="代理" :value="data.agencyName"></erp-view>
          </el-col>
          <el-col :span="24">
            <erp-view label="物流描述" :value="data.logisticsDesc"></erp-view>
          </el-col>
        </el-row>
        <div class="erp-form__title f16" style="margin-top: 50px">负责人</div>
        <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
          <el-col :span="12">
            <erp-view label="单证跟单员" :value="data.userNameDocMerged"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="资管跟单员" :value="data.userNameFundMerged"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="客户经理" :value="data.clientManager"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="采购" :value="data.purchase"></erp-view>
          </el-col>
        </el-row>
      </el-col>
    </el-row>

    <!--订单产品信息展示-->
    <div style="margin-top: 50px" class="order-title__bar op-16">
      <div class="erp-form__title f16">产品信息</div>
      <el-button type="primary" @click="tabDownload">标签下载</el-button>
      <div class="erp-product__count">
        <span class="count-name">件数合计：</span>
        <span class="count-value" style="margin-right: 16px">{{ totalCount }}</span>
        <span class="count-name">实际净重合计(kg)：</span>
        <span class="count-value" style="margin-right: 16px">{{ totalWeight }}</span>
        <span class="count-name">实际货值合计：</span>
        <span class="count-value" style="margin-right: 16px">{{ totalAmount }}</span>
        <span class="count-name">产品数：</span>
        <span class="count-value">{{ _thousandBitSeparator(data.productList.length) }}</span>
        <span class="export-btn" @click="exportFn"> <img class="export-icon" src="../../../../../assets/images/export.png" />导出</span>
      </div>
    </div>
    <el-table :data="data.productList" border class="egrid erp-table__list erp-view__table">
      <el-table-column label="厂号" prop="plantNo" align="center" show-overflow-tooltip minWidth="120"></el-table-column>
      <el-table-column label="中文品名" prop="nameCn" align="center" show-overflow-tooltip minWidth="190"></el-table-column>
      <el-table-column label="英文品名" prop="nameEn" align="center" show-overflow-tooltip minWidth="300"></el-table-column>
      <el-table-column label="标签" align="center" show-overflow-tooltip minWidth="78">
        <template #default="scope" class="img-center">
          <img
            src="../../../../../assets/images/img_icon_biaoqian_s.png"
            v-if="scope.row.labelId && scope.row.labelUrl && scope.row.labelUrl !== ''"
            @click="clickTag(scope.row.labelUrl)"
          />
          <img src="../../../../../assets/images/img_icon_biaoqian.png" alt v-if="scope.row.labelId && !scope.row.labelUrl" />
        </template>
      </el-table-column>
      <el-table-column label="品种" prop="varietyName" align="center" show-overflow-tooltip minWidth="180">
        <template #default="scope">
          <div>{{ filterName(scope.row.varietyName) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="单价" prop="unitPriceDisplay" align="center" show-overflow-tooltip minWidth="100">
        <template #default="scope">
          <div>{{ filterName(scope.row.unitPriceDisplay) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="合同重量(kg)" prop="contractWeightDisplay" align="center" show-overflow-tooltip minWidth="130">
        <template #default="scope">
          <div>{{ filterName(scope.row.contractWeightDisplay) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="合同货值" prop="contractAmountDisplay" align="center" show-overflow-tooltip minWidth="100">
        <template #default="scope">
          <div>{{ filterName(scope.row.contractAmountDisplay) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="交易币种" prop="tradeCurrencyDisplay" align="center" show-overflow-tooltip minWidth="100">
        <template #default="scope">
          <div>{{ filterName(scope.row.tradeCurrencyDisplay) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="件数" prop="packageCountDisplay" align="center" show-overflow-tooltip minWidth="100">
        <template #default="scope">
          <div>{{ filterName(scope.row.packageCountDisplay) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="实际净重(kg)" prop="actualWeightDisplay" align="center" show-overflow-tooltip minWidth="130">
        <template #default="scope">
          <div>{{ filterName(scope.row.actualWeightDisplay) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="实际货值" prop="actualAmountDisplay" align="center" show-overflow-tooltip minWidth="140">
        <template #default="scope">
          {{ showActualAmountDisplay(scope.row.unitPrice, scope.row.actualWeight) }}
        </template>
      </el-table-column>
      <el-table-column label="卫生证号" prop="healthCertNo" align="center" show-overflow-tooltip minWidth="140">
        <template #default="scope">
          <div>{{ filterName(scope.row.healthCertNo) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="电子信息" prop="elecInfoStatusDisplay" align="center" show-overflow-tooltip minWidth="130">
        <template #default="scope">{{ filterName(scope.row.elecInfoStatusDisplay) }}</template>
      </el-table-column>
      <el-table-column label="电子信息ok日期" prop="digitalInfoDate" align="center" show-overflow-tooltip minWidth="140">
        <template #default="scope">{{ filterName(scope.row.digitalInfoDate) }}</template>
      </el-table-column>
    </el-table>
    <!--申报要素  -->
    <div class="op-16">
      <div class="erp-form__title f16" style="margin-top: 30px">申报要素</div>
      <erp-view type="txt" :value="data.declarationKeyElements"></erp-view>
      <div class="erp-form__title f16" style="margin-top: 30px">订单备注</div>
      <erp-view type="txt" :value="data.remark" style="margin-bottom: 30px"></erp-view>
    </div>
  </div>
  <InputDialog ref="inputDialog" width="360px"></InputDialog>
</template>

<script>
import erpView from '@/components/ErpView/ErpView.vue';
import InputDialog from '@/components/InputDialog/InputDialog.vue';
import {
  erpConfirm,
  errorTip,
  getRouterParams,
  successTip,
  _formatFloat,
  _thousandBitSeparator,
  hasPermission,
  exportFile,
  filterName,
} from '@/utils/publicMethods';
import { httpGet, httpPost } from '@/api';
export default {
  components: {
    erpView,
    InputDialog,
  },
  props: {
    data: {
      // 获取传递过来的详情
      type: [Object],
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      totalWeight: 0, // 实际净重合计
      totalAmount: 0, // 实际货值
      totalCount: 0,
    };
  },
  methods: {
    tabDownload() {
      const dtos = this.data.productList.map((v) => {
        return {
          nameCn: v.nameCn,
          nameEn: v.nameEn,
          url: v.labelUrl,
          varietyName: v.varietyName,
        };
      });
      const data = {
        dtos,
        businessContractNo: this.data.businessContractNo,
      };
      exportFile({
        type: 'POST',
        url: '/malicrm/order/batchDownLoadLabel',
        data,
      });
    },
    _thousandBitSeparator(str) {
      return _thousandBitSeparator(str);
    },
    hasPermission(bindPermissions) {
      return hasPermission(bindPermissions);
    },
    // 导出产品信息
    exportFn() {
      const { businessContractNo, orderId } = this.data;
      exportFile({
        type: 'POST',
        url: '/malicrm/order/exportQhdcOrderProduct',
        data: {
          businessContractNo,
          orderId,
        },
      });
    },
    // 新增状态
    finishOrder() {
      this.$refs.inputDialog.init('提示', '确定需要完结当前订单吗？完结后的订单不可再做修改。').then(async (val) => {
        const res = await httpPost('/malicrm/order/finishOrder', {
          orderId: this.data.orderId,
          finishDate: val,
        });
        if (res.code === 200) {
          this.$emit('back', 1);
          successTip('已完结订单');
        } else {
          errorTip(res.message);
        }
      });
      // erpConfirm('确定需要完结当前订单吗？完结后的订单不可再做修改。').then(async () => {
      //   const res = await httpPost('/malicrm/order/finishOrder', {
      //     orderId: this.data.orderId,
      //   });
      //   if (res.code === 200) {
      //     this.$emit('back', 1);
      //     successTip('已完结订单');
      //   } else {
      //     errorTip(res.message);
      //   }
      // });
    },
    reOpenOrder() {
      erpConfirm('确定要恢复当前订单状态为进行中吗？').then(async () => {
        const res = await httpPost('/malicrm/order/reOpenOrder', {
          orderId: this.data.orderId,
        });
        if (res.code === 200) {
          this.$emit('back', 1);
          successTip('已恢复订单');
        } else {
          errorTip(res.message);
        }
      });
    },
    reStoreOrder(status) {
      // 还原判断
      erpConfirm(status === 2 ? '确定要将当前订单还原为“已取消”的状态吗？' : '确定要将当前订单还原为“进行中”的状态吗？')
        .then(async () => {
          const url = status === 2 ? '/malicrm/order/closeOpenOrderQhdc' : '/malicrm/order/reOpenOrder';
          const data = {
            id: this.data.id,
            operType: status,
            orderId: this.data.orderId,
            orderVersion: this.data.orderVersion,
            version: this.data.version,
          };
          const res = await httpPost(url, data);
          if (res.code === 200) {
            successTip('已还原订单');
            this.$emit('back', 1);
          } else {
            errorTip(res.message);
          }
        })
        .catch(() => {
          return false;
        });
    },
    closeOrder(status) {
      // 关闭还原订单
      erpConfirm('确定需要关闭当前订单吗？关闭后的订单不可再做修改。')
        .then(async () => {
          const res = await httpPost('/malicrm/order/closeOpenOrderQhdc', {
            id: this.data.id,
            operType: status,
            orderId: this.data.orderId,
            orderVersion: this.data.orderVersion,
            version: this.data.version,
          });
          if (res.code === 200) {
            successTip('已关闭订单');
            this.$emit('back', 1);
          } else {
            errorTip(res.message);
          }
        })
        .catch(() => {
          return false;
        });
    },
    showActualAmountDisplay(price, weight) {
      if (price === null || price === '' || weight === null || weight === '') {
        return null;
      } else {
        const total = _formatFloat(Number(price) * Number(weight), 2);
        return filterName(_thousandBitSeparator(total));
      }
    },
    getAmount() {
      const productList = this.data.productList;
      const totalWeight = productList.reduce((total, item) => {
        let value = item.actualWeight;
        if (item.actualWeight === null || item.actualWeight === '') {
          value = 0;
        }
        return _formatFloat(total + Number(value), 3);
      }, 0);
      const totalAmount = productList.reduce((total, item) => {
        let value = Number(item.actualWeight) * Number(item.unitPrice);
        if (item.actualAmount === null || item.actualAmount === '' || item.unitPrice === null || item.unitPrice === '') {
          value = 0;
        }
        return _formatFloat(total + Number(value), 2);
      }, 0);
      const totalCount = productList.reduce((total, item) => {
        let value = item.packageCount;
        if (item.packageCount === null || item.packageCount === '') {
          value = 0;
        }
        return total + Number(value);
      }, 0);

      this.totalWeight = _thousandBitSeparator(totalWeight);
      this.totalAmount = _thousandBitSeparator(totalAmount);
      this.totalCount = _thousandBitSeparator(totalCount);
    },

    clickTag(url) {
      if (url) {
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.click();
      }
    },
    back() {
      const { orderId, name, version } = getRouterParams('OrderDetail');
      this.$router.push({
        name: name,
        params: {
          id: orderId,
          version: version,
        },
      });
    },
    async editBaseInfo() {
      const res = await httpGet(`/malicrm/order/checkOrderDeliverStatus/${this.data.orderId}`);
      if (res.code === 200) {
        // 通知tab页切换显示 编辑页面
        this.$emit('edit', 1);
      } else {
        this.$emit('edit', 'limtEdit');
      }
    },
    // 保单
    policyDialog() {
      const id = this.data.orderId;
      erpConfirm('确定导出保单文件吗？')
        .then(() => {
          this.exportStatus = false;
          exportFile({
            type: 'POST',
            url: '/malicrm/order/exportOrderInsurance',
            data: { id },
          });
        })
        .catch(() => {
          return false;
        });
    },
    filterName(val) {
      return filterName(val);
    },
  },
  created() {
    this.getAmount(); // 获取合计值
  },
};
</script>

<style lang="scss" scoped>
.order-title__bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 11px;
}
.count-value {
  font-size: 14px;
  color: #303133;
}
.count-name {
  font-size: 14px;
  color: #606266;
}
.export-btn {
  color: #1378f2;
  cursor: pointer;
  .export-icon {
    margin-left: 20px;
    position: relative;
    top: 2px;
    margin-right: 2px;
  }
}
.erp-view__inner.insurance {
  position: relative;
  .custom-btn {
    width: 68px;
    height: 28px;
    line-height: 28px;
    background: #2878ff;
    border-radius: 2px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    position: absolute;
    right: 0;
    top: 12px;
    cursor: pointer;
  }
}
</style>
