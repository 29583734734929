<template>
  <div class="order-base__warp">
    <div class="erp-form-bar bar-sticky">
      <div
        class="erp-primary__btn"
        @click="editBaseInfo"
        v-if="
          ((data.status == 1 || data.status == 4) && hasPermission('erp:order:saveOrderQhdcBaseInfo')) ||
          ((data.status == 1 || data.status == 4) && hasPermission('erp:order:saveOrderQhdcBaseInfoAll'))
        "
      >
        编辑
      </div>
      <div
        class="erp-primary__btn plain"
        @click="finishOrder"
        v-if="(data.status == 1 && hasPermission('erp:order:closeOpenOrderQhdc')) || (data.status == 1 && hasPermission('erp:order:closeOpenOrderQhdc'))"
      >
        完结订单
      </div>
      <div class="erp-primary__btn plain" @click="closeOrder(1)" v-if="data.status == 4 && hasPermission('erp:order:closeOpenOrderQhdc')">关闭订单</div>
      <div
        class="erp-primary__btn plain"
        @click="reStoreOrder(data.status)"
        v-if="(data.status == 2 || data.status == 3) && hasPermission('erp:order:closeOpenOrderQhdc')"
      >
        还原
      </div>
      <div class="erp-default__btn" @click="back">返回</div>
    </div>
    <!-- 55等分布局 -->
    <el-row :gutter="80" type="flex" class="op-16">
      <el-col :span="12">
        <div class="erp-form__title f16">订单信息</div>
        <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
          <el-col :span="12">
            <erp-view label="供应商合同号" :value="formData.supplierContractNo"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="客户" :value="formData.customerName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="抬头" :value="formData.consigneeShortName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="到港日" :value="formData.shippingEndDate"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="柜号" :value="formData.containerNo"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="提单号" :value="formData.ladingBillNo"></erp-view>
          </el-col>
        </el-row>
        <div class="erp-form__title f16" style="margin-top: 50px">
          正本文件
          <div @click="showProgressHis(2, '正本文件')" class="his-title">
            <img src="../../../../../assets/images/icon-edit.png" alt="" />
            状态记录
          </div>
        </div>
        <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
          <el-col :span="12">
            <erp-view label="日期" :value="formData.originalDocProgress.performDate"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="状态" :value="formData.originalDocProgress.isSettledDisplay"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="放货方式" :value="formData.originalDocProgress.releaseTypeName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="快递公司" :value="formData.originalDocProgress.trackingCompanyName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="正本快递单号" :value="formData.originalDocProgress.trackingNumber"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="寄件人/收件人手机号后四位" :value="formData.originalDocProgress.trackingPhoneLastFour"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="正本快递进度">
              <span class="express-status">{{ filterExpress(formData.originalDocProgress.expressStatus) }}</span>
              <tableFileContent
                fromWhere="express-status"
                v-if="load && formData.originalDocProgress.fileUrl"
                :readOnly="true"
                :fileUrlsArray="
                  formData.originalDocProgress.fileUrl && formData.originalDocProgress.fileUrl !== ''
                    ? [
                        {
                          id: 1,
                          name: 'show.jpg',
                          url: formData.originalDocProgress.fileUrl,
                        },
                      ]
                    : null
                "
                key-name="name"
                key-url="url"
                key-id="id"
                class="margin-upload"
              ></tableFileContent>
              <span
                v-if="load && !formData.originalDocProgress.fileUrl && zhengBenDeliveryMessages && zhengBenDeliveryMessages.length > 0"
                class="express-view-status"
                @click="openExpressDialog(zhengBenDeliveryMessages)"
                >查看</span
              >
            </erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="更新人 " :value="formData.originalDocProgress.lastOperator"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="更新时间 " :value="formData.originalDocProgress.updateTime"></erp-view>
          </el-col>
          <el-col :span="24">
            <erp-view label="描述" :value="formData.originalDocProgress.description"></erp-view>
          </el-col>
        </el-row>
        <!--临时隐藏改功能-->

        <!-- todolist -->
        <div class="erp-form__title f16" style="margin-top: 50px">提箱中转</div>
        <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
          <el-col :span="12">
            <!-- diading -->
            <erp-view label="提箱日期 " :value="formData.packingContainerInfo && formData.packingContainerInfo.packingContainerDate"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="消杀日期" :value="formData.packingContainerInfo && formData.packingContainerInfo.xsDate"></erp-view>
          </el-col>
          <el-col :span="24">
            <erp-view label="提箱描述" :value="formData.packingContainerInfo && formData.packingContainerInfo.packingContainerDescription"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="中转日期 " :value="formData.packingContainerInfo && formData.packingContainerInfo.transferDate"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="中转库 " :value="formData.packingContainerInfo && formData.packingContainerInfo.transferStorage"></erp-view>
          </el-col>
          <el-col :span="24">
            <erp-view label="中转描述" :value="formData.packingContainerInfo && formData.packingContainerInfo.transferDescription"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="更新人" :value="formData.packingContainerInfo && formData.packingContainerInfo.lastOperator"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="更新时间" :value="formData.packingContainerInfo && formData.packingContainerInfo.updateTime"></erp-view>
          </el-col>
        </el-row>
        <div class="erp-form__title f16" style="margin-top: 50px">送货</div>
        <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
          <el-col :span="12">
            <erp-view label="送货日期" :value="formData.clearanceProgress.deliverDate"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="送货方式" :value="formData.clearanceProgress.deliverModeDisplay"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="更新人" :value="formData.clearanceProgress.lastOperator"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="更新时间 " :value="formData.clearanceProgress.updateTime"></erp-view>
          </el-col>
          <el-col :span="24">
            <erp-view label="送货问题" :value="formData.clearanceProgress.deliverIssue"></erp-view>
          </el-col>
        </el-row>

        <div style="margin-bottom: 30px" v-if="formData.isCustomerGoodsPayment">
          <div class="erp-form__title f16" style="margin-top: 50px">贸易商付工厂货款</div>
          <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
            <el-col :span="12">
              <erp-view label="贸易商付预付款日期" :value="formData.foreignRatioProgress ? formData.foreignRatioProgress.performDate : null"></erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="贸易商付尾款日期" :value="formData.foreignTailProgress ? formData.foreignTailProgress.performDate : null"></erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="预付款状态 " :value="formData.foreignRatioProgress ? formData.foreignRatioProgress.isSettledDisplay : null"></erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="尾款状态" :value="formData.foreignTailProgress ? formData.foreignTailProgress.isSettledDisplay : null"></erp-view>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="erp-form__title f16">
          副本文件
          <div @click="showProgressHis(1, '副本文件')" class="his-title">
            <img src="../../../../../assets/images/icon-edit.png" alt="" />
            状态记录
          </div>
        </div>
        <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
          <el-col :span="12">
            <erp-view label="日期" :value="formData.copyDocProgress.performDate"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="状态" :value="formData.copyDocProgress.isSettledDisplay"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="更新人" :value="formData.copyDocProgress.lastOperator"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="更新时间" :value="formData.copyDocProgress.updateTime"></erp-view>
          </el-col>
          <el-col :span="24">
            <erp-view label="描述" :value="formData.copyDocProgress.description"></erp-view>
          </el-col>
        </el-row>
        <div class="erp-form__title f16" style="margin-top: 50px">
          交报关行
          <div @click="showProgressHis(3, '交报关行')" class="his-title">
            <img src="../../../../../assets/images/icon-edit.png" alt="" />
            状态记录
          </div>
        </div>
        <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
          <el-col :span="12">
            <erp-view label="日期" :value="formData.declarationProgress.performDate"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="状态" :value="formData.declarationProgress.isSettledDisplay"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="快递公司" :value="formData.declarationProgress.trackingCompanyName"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="报关资料快递单号" :value="formData.declarationProgress.trackingNumber"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="寄件人/收件人手机号后四位" :value="formData.declarationProgress.trackingPhoneLastFour"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="报关资料快递进度">
              <span class="express-status">{{ filterExpress(formData.declarationProgress.expressStatus) }}</span>
              <tableFileContent
                class="margin-upload"
                v-if="load && formData.declarationProgress.fileUrl"
                :readOnly="true"
                :fileUrlsArray="
                  formData.declarationProgress.fileUrl && formData.declarationProgress.fileUrl != ''
                    ? [
                        {
                          id: 1,
                          name: 'show.jpg',
                          url: formData.declarationProgress.fileUrl,
                        },
                      ]
                    : null
                "
                key-name="name"
                key-url="url"
                key-id="id"
              ></tableFileContent>
              <span
                v-if="load && !formData.originalDocProgress.fileUrl && jiaoBaoGuanDeliveryMessages && jiaoBaoGuanDeliveryMessages.length > 0"
                class="express-view-status"
                @click="openExpressDialog(jiaoBaoGuanDeliveryMessages)"
                >查看</span
              >
            </erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="更新人 " :value="formData.declarationProgress.lastOperator"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="更新时间 " :value="formData.declarationProgress.updateTime"></erp-view>
          </el-col>
          <el-col :span="24">
            <erp-view label="描述" :value="formData.declarationProgress.description"></erp-view>
          </el-col>
        </el-row>

        <div class="erp-form__title f16" style="margin-top: 50px">还箱信息</div>
        <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
          <el-col :span="12">
            <!-- diading -->
            <erp-view label="还箱日期 " :value="formData.returnBoxProgress.performDate"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="免箱期" :value="formData.returnBoxProgress.boxFreePeriod"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="更新人 " :value="formData.returnBoxProgress.lastOperator"></erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="更新时间 " :value="formData.returnBoxProgress.updateTime"></erp-view>
          </el-col>
          <el-col :span="24">
            <erp-view label="还箱地址" :value="formData.returnBoxProgress.boxRetunAddress"></erp-view>
          </el-col>
          <el-col :span="24">
            <erp-view label="描述" :value="formData.returnBoxProgress.description"></erp-view>
          </el-col>
        </el-row>
        <div v-if="!formData.isNeedJr && formData.isCustomerConsignee" style="margin-bottom: 30px">
          <div class="erp-form__title f16" style="margin-top: 50px">付外商货款</div>
          <el-row :gutter="30" type="flex" class="pl-12 flex-warp">
            <el-col :span="12">
              <erp-view label="付预付款日期" :value="formData.ratioProgress ? formData.ratioProgress.performDate : null"> </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="付尾款日期" :value="formData.tailProgress ? formData.tailProgress.performDate : null"></erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="预付款状态 " :value="formData.ratioProgress ? formData.ratioProgress.isSettledDisplay : null"> </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="尾款状态" :value="formData.tailProgress ? formData.tailProgress.isSettledDisplay : null"> </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="预付凭据">
                <tableFileContent
                  class="margin-upload"
                  v-if="load"
                  :readOnly="true"
                  :fileUrlsArray="formData.ratioProgress && formData.ratioProgress.attachmentVos ? formData.ratioProgress.attachmentVos : null"
                  key-name="name"
                  key-url="url"
                  key-id="id"
                >
                </tableFileContent>
              </erp-view>
            </el-col>
            <el-col :span="12">
              <erp-view label="尾款凭据">
                <tableFileContent
                  class="margin-upload"
                  v-if="load"
                  :readOnly="true"
                  :fileUrlsArray="formData.tailProgress && formData.tailProgress.attachmentVos ? formData.tailProgress.attachmentVos : null"
                  key-name="name"
                  key-url="url"
                  key-id="id"
                >
                </tableFileContent>
              </erp-view>
            </el-col>
          </el-row>
        </div>
        <div class="erp-form__title f16" style="margin-top: 50px">结算开票</div>
        <el-row :gutter="30" type="flex" class="pl-12 flex-warp" style="margin-bottom: 30px">
          <el-col :span="12">
            <erp-view label="客户是否结清" :value="formData.settleProgress ? formData.settleProgress.isSettledDisplay : ''"> </erp-view>
          </el-col>
          <el-col :span="12">
            <erp-view label="开票状态" :value="formData.invoiceProgress ? formData.invoiceProgress.isSettledDisplay : ''"> </erp-view>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <erp-drawer :visible="visible" @close="closeDialog" erp class="fix-top-header" width="1210">
      <progressHistory :title="title" :type="historyType" :orderId="orderId" @cancel="closeDialog"></progressHistory>
    </erp-drawer>
    <el-dialog title="查看快递信息" v-model="isShowExpressDialog" width="612px" custom-class="p-30 express" @close="isShowExpressDialog = false">
      <div>
        <express-progress :expressData="subscribeResult"></express-progress>
      </div>
      <template #footer>
        <div class="upload-dialog__footer">
          <el-button size="small" @click="isShowExpressDialog = false">取消</el-button>
        </div>
      </template>
    </el-dialog>
    <InputDialog ref="inputDialog" width="360px"></InputDialog>
  </div>
</template>

<script>
import erpView from '@/components/ErpView/ErpView.vue';
import tableFileContent from '@/components/TableFileContent/TableFileContent.vue';
import progressHistory from './progressHistory.vue';
import ExpressProgress from './expressProgress.vue';
import { erpConfirm, successTip, errorTip, hasPermission, getRouterParams } from '@/utils/publicMethods';
import InputDialog from '@/components/InputDialog/InputDialog.vue';
import { httpPost } from '@/api';
export default {
  components: {
    erpView,
    tableFileContent,
    progressHistory,
    ExpressProgress,
    InputDialog,
  },
  props: {
    data: {
      // 获取传递过来的详情
      type: [Object],
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      copydata: {
        boxFreePeriod: null,
        boxRetunAddress: null,
        description: null,
        id: null,
        isSettled: null,
        isSettledDisplay: null,
        lastOperator: null,
        otherParam: null,
        performDate: null,
        updateTime: '',
        version: null,
      },
      formData: {},
      load: false,
      visible: false,
      historyType: 0,
      title: '',
      orderId: '',
      isShowExpressDialog: false,
      subscribeResult: [],
      zhengBenDeliveryMessages: [],
      jiaoBaoGuanDeliveryMessages: [],
    };
  },

  watch: {
    data: {
      handler() {
        this.updata();
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.updata();
  },

  methods: {
    hasPermission(bindPermissions) {
      return hasPermission(bindPermissions);
    },
    filterExpress(val) {
      // 0在途，1揽收，2疑难，3签收，4退签，5派件，6退回，7转单，10待清关，11清关中，12已清关，13清关异常，14收件人拒签
      const status = [
        { key: 0, value: '在途' },
        { key: 1, value: '揽收' },
        { key: 2, value: '疑难' },
        { key: 3, value: '签收' },
        { key: 4, value: '退签' },
        { key: 5, value: '派件' },
        { key: 6, value: '退回' },
        { key: 7, value: '转单' },
        { key: 10, value: '待清关' },
        { key: 11, value: '清关中' },
        { key: 12, value: '已清关' },
        { key: 13, value: '清关异常' },
        { key: 14, value: '收件人拒签' },
      ];
      if (val) {
        const statusItem = status.filter((item) => {
          return item.key === Number(val);
        });
        if (statusItem && statusItem.length > 0) {
          return statusItem[0].value;
        }
      } else {
        return '-';
      }
    },
    showProgressHis(type, title) {
      this.historyType = type;
      this.title = title;
      this.orderId = this.data.orderId;
      this.visible = true;
    },
    closeDialog() {
      this.visible = false;
    },
    updata() {
      this.load = false;
      this.formData = JSON.parse(JSON.stringify(this.data));
      if (!this.formData.declarationProgress) {
        this.formData.declarationProgress = this.copydata;
      }
      if (!this.formData.clearanceProgress) {
        this.formData.clearanceProgress = this.copydata;
      }
      if (!this.formData.copyDocProgress) {
        this.formData.copyDocProgress = this.copydata;
      }
      if (!this.formData.originalDocProgress) {
        this.formData.originalDocProgress = this.copydata;
      }
      if (!this.formData.returnBoxProgress) {
        this.formData.returnBoxProgress = this.copydata;
      }
      let zhenBenRes;
      if (this.formData.originalDocProgress.deliveryMessages) {
        zhenBenRes = JSON.parse(this.formData.originalDocProgress.deliveryMessages);
        if (zhenBenRes && zhenBenRes.lastResult) {
          this.zhengBenDeliveryMessages = zhenBenRes.lastResult.data;
          this.formData.originalDocProgress.expressStatus = zhenBenRes.lastResult.state;
        }
      }
      let jiaoBaoGuanRes;
      if (this.formData.declarationProgress.deliveryMessages) {
        jiaoBaoGuanRes = JSON.parse(this.formData.declarationProgress.deliveryMessages);
        if (jiaoBaoGuanRes && jiaoBaoGuanRes.lastResult) {
          this.jiaoBaoGuanDeliveryMessages = jiaoBaoGuanRes.lastResult.data;
          this.formData.declarationProgress.expressStatus = jiaoBaoGuanRes.lastResult.state;
        }
      }
      this.load = true;
    },
    finishOrder() {
      this.$refs.inputDialog.init('提示', '确定需要完结当前订单吗？完结后的订单不可再做修改。').then(async (val) => {
        const res = await httpPost('/malicrm/order/finishOrder', {
          orderId: this.data.orderId,
          finishDate: val,
        });
        if (res.code === 200) {
          this.$emit('back', 1);
          successTip('已完结订单');
        } else {
          errorTip(res.message);
        }
      });
    },
    reOpenOrder() {
      erpConfirm('确定要恢复当前订单状态为进行中吗？').then(async () => {
        const res = await httpPost('/malicrm/order/reOpenOrder', {
          orderId: this.data.orderId,
        });
        if (res.code === 200) {
          this.$emit('back', 1);
          successTip('已恢复订单');
        } else {
          errorTip(res.message);
        }
      });
    },
    reStoreOrder(status) {
      // 还原判断
      erpConfirm(status === 2 ? '确定要将当前订单还原为“已取消”的状态吗？' : '确定要将当前订单还原为“进行中”的状态吗？')
        .then(async () => {
          const url = status === 2 ? '/malicrm/order/closeOpenOrderQhdc' : '/malicrm/order/reOpenOrder';
          const data = {
            id: this.data.id,
            operType: status,
            orderId: this.data.orderId,
            orderVersion: this.data.orderVersion,
            version: this.data.version,
          };
          const res = await httpPost(url, data);
          if (res.code === 200) {
            successTip('已还原订单');
            this.$emit('back', 1);
          } else {
            errorTip(res.message);
          }
        })
        .catch(() => {
          return false;
        });
    },
    closeOrder(status) {
      // 关闭还原订单
      erpConfirm('确定需要关闭当前订单吗？关闭后的订单不可再做修改。')
        .then(async () => {
          const res = await httpPost('/malicrm/order/closeOpenOrderQhdc', {
            id: this.data.id,
            operType: status,
            orderId: this.data.orderId,
            orderVersion: this.data.orderVersion,
            version: this.data.version,
          });
          if (res.code === 200) {
            successTip('已关闭订单');
            this.$emit('back', 1);
          } else {
            errorTip(res.message);
          }
        })
        .catch(() => {
          return false;
        });
    },
    /**
     * 打开快递进度对话弹窗
     */
    openExpressDialog(data) {
      this.subscribeResult = data;
      this.isShowExpressDialog = true;
    },
    editBaseInfo() {
      // 通知tab页切换显示 编辑页面
      this.$emit('edit', 2);
    },
    back() {
      const { orderId, name, version } = getRouterParams('OrderDetail');
      this.$router.push({
        name: name,
        params: {
          id: orderId,
          version: version,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.erp-form__title {
  display: flex;
  justify-content: space-between;
  .his-title {
    color: #2878ff;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    img {
      margin-right: 3px;
    }
  }
}
.express-status {
  position: relative;
  // top: 3px;
  font-size: 14px;
  color: #303133;
}
.express-view-status {
  color: #2878ff;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  // top: 3px;
  margin-left: 10px;
}
</style>
