<template>
  <div class="express-progress-page">
    <div
      v-for="(item, index) in expressData"
      :key="index"
      class="express-row"
      :class="{ last: isLastRow(index), current: isCurrentRow(index) }"
    >
      <div class="express-l">
        <div class="time top">{{ getDate(item.ftime) }}</div>
        <div class="time">{{ getTime(item.ftime) }}</div>
      </div>
      <div class="express-r">
        <div class="express-content">
          {{ item.context }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    expressData: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  methods: {
    isLastRow(index) {
      return index === this.expressData.length - 1;
    },
    isCurrentRow(index) {
      return index === 0;
    },
    getDate(value) {
      let arr;
      let res;
      if (value) {
        arr = value.split(' ');
        if (arr[0]) {
          res = arr[0];
        }
      }
      return res;
    },
    getTime(value) {
      let arr;
      let res = value;
      if (value) {
        arr = value.split(' ');
        if (arr[1]) {
          res = arr[1];
        }
      }
      return res;
    },
  },
};
</script>
<style lang="scss" scoped>
.express-progress-page {
  max-height: 570px;
  overflow-y: scroll;
  padding-top: 2px;
  padding-left: 30px;
  .express-row {
    display: flex;
    justify-content: flex-start;
    .express-l {
      flex-basis: 87px;
      text-align: right;
      padding-right: 20px;
      margin-left: 10px;
      .status {
        font-size: 16px;
        color: #303133;
        margin-bottom: 0px;
        margin-top: -4px;
      }
      .time {
        font-size: 13px;
        color: #909399;
        text-align: right;
        margin-bottom: -2px;
      }
      .top {
        margin-top: -3px;
      }
    }
    .express-r {
      flex-basis: 430px;
      border-left: 2px solid #d9e1ec;
      padding-bottom: 21px;
      padding-left: 26px;
      position: relative;
      &:after {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 100%;
        background-color: #d9e1ec;
        position: absolute;
        top: 0;
        left: -7px;
      }
      .express-content {
        width: 400px;
        background: #ffffff;
        box-shadow: 0px 0px 10px 0px rgba(35, 100, 209, 0.12);
        border-radius: 4px;
        box-sizing: border-box;
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 20px;
        padding-bottom: 25px;
        line-height: 24px;
        color: #303133;
        font-size: 14px;
        text-align: justify;
      }
    }
  }
  .current {
    .express-l {
      .status {
        color: #2878ff;
      }
      .time {
        color: #2878ff;
      }
    }
    .express-r {
      &:after {
        background-color: #2878ff;
      }
    }
  }
  .last {
    .express-r {
      border-color: #fff;
    }
  }
}
</style>
