<template>
  <div class="mini-warp">
    <div class="bg-warp"></div>
    <div class="inner__warp">
      <div class="status-card-warp">
        <div class="goods-name">
          <img src="../../../../../assets/images/prview/icon-name.png" alt="" />
          {{ data.goodsName }}
        </div>
        <div class="goods-plantNo">{{ data.countryDesc }}{{ data.plantNo }}厂</div>
        <div class="code-img__warp">
          <img :src="data.codeUrl ? data.codeUrl : 'https://minio.malleegroup.com/template/rBEABl-8qaGAI00OAAAyeMXsHmc694.png'" class="svg-img" />
        </div>
        <div class="no-exp">
          <img src="../../../../../assets/images/prview/icon-success.png" />
          未见异常
        </div>
        <div class="exp-info">
          <img src="../../../../../assets/images/icon-tips.png" alt="" style="width: 14px; height: 14px" />
          根据目前掌握的疫情防控信息，暂未发现该批冻品存在与防疫相关的异常情况。
        </div>
      </div>
      <!-- 海外信息 -->
      <div class="info-warp">
        <div class="info-title">海外物流信息</div>
        <div class="logistics-list">
          <div class="logistics-item">
            <div class="logistics-left" style="width: 80px">
              {{ filterName(data.shippingStartDate) }}
            </div>
            <div class="logistics-right">
              <div>
                {{ data.qhdcPortLoading ? '启运:' + data.qhdcPortLoading : '启运' }}
              </div>
              <div class="inner" v-if="data.containerNo">柜号{{ filterName(data.containerNo) }}</div>
            </div>
          </div>
          <div class="logistics-item">
            <div class="logistics-left" style="width: 80px">
              {{ filterName(data.shippingEndDate) }}
            </div>
            <div class="logistics-right">
              <div>到港:{{ data.qhdcPortArrival }}</div>
            </div>
          </div>
        </div>
        <div class="chain-code" @click="showChain">区块链标识：{{ chainCode(data.aliChainHash) }}</div>
      </div>
      <!-- 报关清关信息 -->
      <div class="info-warp">
        <div class="info-title">报关清关信息</div>
        <div class="logistics-list">
          <div class="logistics-item" v-if="data.declareDate">
            <div class="logistics-left">{{ data.declareDate }}</div>
            <div class="logistics-right">
              <div>已申报</div>
            </div>
          </div>
          <div class="logistics-item" v-if="data.releaseDate">
            <div class="logistics-left">{{ data.releaseDate }}</div>
            <div class="logistics-right">
              <div>放行</div>
            </div>
          </div>
        </div>
        <div class="file-prview">
          <div class="file-item" v-if="data.declareFiles && data.declareFiles.length > 0" @click="showFiles(data.declareFiles)">
            <div class="file-box">
              <img src="../../../../../assets/images/prview/pic1.png" alt="" />
            </div>
            <div class="file-type">报关单</div>
          </div>
          <div class="file-item" v-if="data.inspectFiles && data.inspectFiles.length > 0" @click="showFiles(data.inspectFiles)">
            <div class="file-box">
              <img src="../../../../../assets/images/prview/pic2.png" alt="" />
            </div>
            <div class="file-type">检疫证</div>
          </div>
        </div>
        <div class="chain-code" @click="showChain">区块链标识：{{ chainCode(data.aliChainHash) }}</div>
      </div>
      <!-- 国内物流信息 -->
      <div class="info-warp">
        <div class="info-title">国内物流信息</div>
        <div class="logistics-list">
          <div class="logistics-item" v-if="data.instoreDate">
            <div class="logistics-left">{{ data.instoreDate }}</div>
            <div class="logistics-right">
              <div>{{ data.deliverStatus == 4 ? '已提货' : '已入库' }}{{ data.storageName }}</div>
              <div
                v-if="data.storageInspectUrl"
                @click="
                  showFiles([
                    {
                      name: data.storageInspectName,
                      url: data.storageInspectUrl,
                    },
                  ])
                "
                class="blue-file"
              >
                《冷库防疫措施说明》
              </div>
            </div>
          </div>
          <template v-if="data.outStoreVos && data.outStoreVos.length > 0">
            <div class="logistics-item" v-for="(item, index) in data.outStoreVos" :key="index">
              <div class="logistics-left">{{ item.outStoreDate }}</div>
              <div class="logistics-right">
                <div>已出库{{ item.storageName }}</div>
              </div>
            </div>
          </template>
        </div>
        <div class="file-prview">
          <div class="file-item" v-if="data.acidReports" @click="showFiles(data.acidReports)">
            <div class="file-box">
              <img src="../../../../../assets/images/prview/pic4.png" alt="" />
            </div>
            <div class="file-type">核酸检测报告</div>
          </div>
          <div class="file-item" v-if="data.killProves && data.killProves.length > 0" @click="showFiles(data.killProves)">
            <div class="file-box">
              <img src="../../../../../assets/images/prview/pic5.png" alt="" />
            </div>
            <div class="file-type">消杀证明</div>
          </div>
          <div class="file-item" v-if="data.others && data.others.length > 0" @click="showFiles(data.others)">
            <div class="file-box">
              <img src="../../../../../assets/images/prview/pic2.png" alt="" />
            </div>
            <div class="file-type">{{ data.others[0].attachmentValue }}</div>
          </div>
        </div>
        <div class="chain-code" @click="showChain">区块链标识：{{ chainCode(data.aliChainHash) }}</div>
      </div>
      <!-- 产品认证信息 -->
      <div class="info-warp">
        <div class="info-title">产品认证信息</div>
        <div class="logistics-list">
          <div class="logistics-item__product" v-for="(item, index) in data.goodsVos" :key="index">
            <div class="logistics-left">{{ item.nameCn }}</div>
            <div class="logistics-right" v-if="item.labelUrl" @click="showFiles([{ name: item.labelUrl, url: item.labelUrl }])">
              <img src="../../../../../assets/images/label-icon.png" alt="" style="width: 17px; height: 17px; font-size: 0" />
              标签
            </div>
          </div>
        </div>

        <!-- 海外供应链 -->
      </div>
      <div class="info-warp">
        <div class="info-title">海外工厂验证信息</div>
        <div class="logistics-list">
          <div class="logistics-item no-dot" v-if="data.approvalVo">
            <div class="logistics-left w124">工厂名（厂号）</div>
            <div class="logistics-right">
              <div>{{ data.approvalVo.plantName }}({{ data.approvalVo.plantNo }})</div>
            </div>
          </div>
          <div class="logistics-item no-dot" v-if="data.approvalVo">
            <div class="logistics-left w124">国家</div>
            <div class="logistics-right">
              <div>{{ data.approvalVo.countryDesc }}</div>
              <div v-if="data.epidemicMeasures && data.epidemicMeasures.length" @click="showFiles(data.epidemicMeasures)" class="blue-file">
                《海外企业防疫措施说明》
              </div>
            </div>
          </div>
        </div>
        <div class="file-prview">
          <div class="file-item" v-if="data.epidemicPictures && data.epidemicPictures.length > 0" @click="showFiles(data.epidemicPictures)">
            <div class="file-box">
              <img :src="data.epidemicPictures[0].url" alt="" />
            </div>
            <div class="file-type">防疫图片</div>
          </div>
          <div class="file-item" @click="showFiles(data.epidemicVideos)" v-if="data.epidemicVideos && data.epidemicVideos.length > 0">
            <div class="file-box">
              <img :src="data.epidemicVideos[0].shortUrl ? data.epidemicVideos[0].shortUrl : '../../../../../assets/images/prview/pic7.png'" alt="" />
              <div class="video-player" v-if="data.epidemicVideos[0].shortUrl"></div>
            </div>
            <div class="file-type">防疫视频</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { httpGet } from '@/api';
import { errorTip } from '@/utils/publicMethods';
export default {
  components: {},
  props: ['id', 'showType'],
  data() {
    return {
      data: {
        acidReports: [
          {
            attachmentType: '',
            attachmentValue: '',
            createdTime: '',
            creator: '',
            id: 0,
            name: '',
            suffix: '',
            url: '',
          },
        ],
        aliChainHash: '',
        approvalVo: {
          country: '',
          countryDesc: '',
          plantName: '',
          plantNo: '',
        },
        codeUrl: '',
        containerNo: '',
        country: '',
        countryDesc: '',
        declareDate: '',
        declareFiles: [
          {
            attachmentType: '',
            attachmentValue: '',
            createdTime: '',
            creator: '',
            id: 0,
            name: '',
            suffix: '',
            url: '',
          },
        ],
        epidemicMeasures: [
          {
            attachmentType: '',
            attachmentValue: '',
            createdTime: '',
            creator: '',
            id: 0,
            name: '',
            suffix: '',
            url: '',
          },
        ],
        epidemicPictures: [
          {
            attachmentType: '',
            attachmentValue: '',
            createdTime: '',
            creator: '',
            id: 0,
            name: '',
            suffix: '',
            url: '',
          },
        ],
        epidemicVideos: [
          {
            attachmentType: '',
            attachmentValue: '',
            createdTime: '',
            creator: '',
            id: 0,
            name: '',
            suffix: '',
            url: '',
          },
        ],
        goodsName: '',
        goodsVos: [
          {
            actualAmount: 0,
            actualAmountDisplay: '',
            actualWeight: 0,
            actualWeightDisplay: '',
            category: '',
            contractAmount: 0,
            contractAmountDisplay: '',
            contractWeight: 0,
            contractWeightDisplay: '',
            country: '',
            digitalInfoDate: '',
            elecInfoStatus: '',
            elecInfoStatusDisplay: '',
            healthCertNo: '',
            labelId: 0,
            labelUrl: '',
            nameCn: '',
            nameEn: '',
            packageCount: 0,
            packageCountDisplay: '',
            plantNo: '',
            tradeCurrency: '',
            tradeCurrencyDisplay: '',
            unitPrice: 0,
            unitPriceDisplay: '',
            varietyName: '',
          },
        ],
        id: 0,
        inspectFiles: [
          {
            attachmentType: '',
            attachmentValue: '',
            createdTime: '',
            creator: '',
            id: 0,
            name: '',
            suffix: '',
            url: '',
          },
        ],
        instoreDate: '',
        killProves: [
          {
            attachmentType: '',
            attachmentValue: '',
            createdTime: '',
            creator: '',
            id: 0,
            name: '',
            suffix: '',
            url: '',
          },
        ],
        outStoreVos: [
          {
            outStoreDate: '',
            storageName: '',
          },
        ],
        plantNo: '',
        qhdcPortArrival: '',
        qhdcPortLoading: '',
        releaseDate: '',
        shippingEndDate: '',
        shippingInfo: '',
        shippingStartDate: '',
        storageId: 0,
        storageInspectName: '',
        storageInspectUrl: '',
        storageName: '',
        supplierId: 0,
      },
      hashCode: null,
    };
  },
  created() {
    this.initData();
  },

  watch: {
    showType: {
      handler() {
        this.initData();
      },
    },
  },
  computed: {},
  filters: {},
  methods: {
    chainCode(value) {
      let str = '';
      if (value) {
        str = value.substr(0, 17) + '...' + value.substr(value.length - 3, 3);
      } else {
        str = 'd837b8499587388486...678';
      }
      return str;
    },
    showChain() {
      if (this.hashCode) {
        this.$emit('showChain', this.hashCode);
      }
    },
    async initData() {
      const res = await httpGet(`/malicrm/wechat/lm/order/securityOrderDetail/${this.id}`);
      if (res.code === 200) {
        this.data = res.result;
        if (res.result.aliChainHash) {
          this.getHashCode(res.result.aliChainHash);
        }
      } else {
        errorTip(res.message);
      }
    },
    async getHashCode(code) {
      const res = await httpGet(`/malicrm/wechat/lm/order/aliChainDetail/${code}`);
      if (res.code === 200) {
        this.hashCode = res.result;
      } else {
        errorTip(res.message);
      }
    },
    showFiles(item) {
      if (item.length > 1) {
        this.$emit('change', item);
      } else {
        this.$showFiles(item[0].name, item[0].url);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.status-card-warp {
  background: #fff;
  border-radius: 6px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 15px;
  box-shadow: 0px 0px 15px 0px rgba(23, 119, 255, 0.15);
}
.bg-warp {
  height: 106px;
  background: url('../../../../../assets/images/prview/warp-bg.png');
  background-size: 100% 106px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.inner__warp {
  position: relative;
  z-index: 2;
}
.mini-warp {
  margin-top: 20px;
  padding: 10px 15px;
  background: #f6f6f6;
  width: 100%;
  margin: 0 auto;
}
.goods-name {
  font-size: 15px;
  color: #333;
  font-weight: 500;
  margin-top: 25px;
  margin-bottom: 13px;
  display: flex;
  align-items: center;
  img {
    width: 17px;
    height: 17px;
    margin-right: 10px;
  }
}
.goods-plantNo {
  font-size: 14px;
  color: #333;
  font-weight: 500;
  margin-bottom: 12px;
}
.code-img__warp {
  width: 140px;
  height: 140px;
}
.no-exp {
  font-size: 16px;
  color: #3da357;
  margin: 13px 0 20px;
  display: flex;
  align-items: center;
  img {
    width: 22px;
    height: 22px;
    margin-right: 11px;
  }
}
.exp-info {
  border-top: 1px dashed #ebeef5;
  font-size: 14px;
  color: #666;
  line-height: 1.25;
  padding: 15px 0;
}
.info-warp {
  width: 100%;
  padding: 0 15px;
  margin-top: 15px;
  background: #ffffff;
  box-shadow: 0px 0px 15px 0px rgba(23, 119, 255, 0.15);
  border-radius: 6px;
  overflow: hidden;
}
.info-title {
  margin-bottom: 28px;
  font-weight: bold;
  font-size: 16px;
  margin-top: 24px;
  color: #333;
}

.logistics-item {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
  position: relative;

  &::before {
    content: '';
    width: 10px;
    height: 10px;
    display: block;
    background: url('../../../../../assets/images/blue-dot.png') 10px 10px;
    background-size: 10px 10px;
    margin-right: 10px;
    position: relative;
    top: 3px;
    z-index: 99;
  }
  &::after {
    display: block;
    content: '';
    width: 0.5px;
    border: 1px dashed rgba($color: #1777ff, $alpha: 0.6);
    position: absolute;
    top: 12px;
    left: 4px;
    bottom: -3px;
    z-index: 3;
  }

  &:last-child::after {
    display: none;
  }
}
.logistics-item.no-dot::after {
  display: none;
}
.logistics-item.no-dot::before {
  display: none;
}
.chain-code {
  border-radius: 30px;
  width: 100%;
  padding: 10px 13px;
  border: 1px solid #1777ff;
  border-radius: 5px;
  font-size: 14px;
  color: #1777ff;
  background: #e7f1ff;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  &::before {
    content: '';
    width: 14px;
    height: 14px;
    display: block;
    background: url('../../../../../assets/images/chian-code.png') center center;
    background-size: 14px 14px;
    margin-right: 5px;
  }
}
.file-prview {
  display: flex;
}
.file-box {
  width: 95px;
  height: 65px;
  position: relative;
  border: 1px solid #d8d8d8;
  img {
    width: 95px;
    height: 65px;
  }
}
.file-item {
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;
  cursor: pointer;
}
.file-type {
  margin-top: 6px;
  font-size: 13px;
  color: #333;
}
.logistics-item__product {
  display: flex;
}
.logistics-left {
  font-size: 15px;
  color: #666666;
  margin-right: 32px;
}
.logistics-right {
  flex: 1;
  > * {
    font-size: 15px;
    color: #333;
    text-align: left;
    overflow: hidden;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-all;
    text-overflow: ellipsis;
  }
  .inner {
    margin-top: 12px;
  }
}
.logistics-item__product {
  margin-bottom: 30px;
  .logistics-left {
    color: #333;
  }
  .logistics-right {
    text-align: right;
    color: #1777ff;
    font-size: 15px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }
}
.logistics-left.w124 {
  width: 124px;
  margin-right: 0;
}
.blue-file {
  color: #1777ff;
  font-size: 15px;
  position: relative;
  left: -8px;
  cursor: pointer;
  margin-top: 13px;
}
.svg-img {
  width: 140px;
  height: 140px;
}
.video-player {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 22;
  margin-left: -16px;
  margin-top: -16px;
  background-image: url('../../../../../assets/images/prview/player.png');
  background-size: 32px 32px;
}
</style>
