<template>
  <div class="order-base__warp">
    <div class="erp-form-bar bar-sticky">
      <div class="erp-primary__btn" @click="saveFormData">保存</div>
      <div class="erp-default__btn" @click="back">返回</div>
    </div>
    <div class="billing-method__title">
      <img src="../../../../../assets/images/icon-tips.png" alt />
      <div class="billing-method__name">
        计费方式&nbsp;
        <el-select v-model="formData.type" style="width: 120px" size="small">
          <el-option v-for="item in billingTypeData" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>
    </div>
    <!-- 按天计费数据展示 -->
    <billing-type ref="billingType" :billData="detailData" type="edit" v-if="formData.type === 1 || formData.type === 3"></billing-type>
    <!-- 包干价 -->
    <div v-else>
      <el-row :gutter="80" type="flex" class="op-16">
        <el-col :span="12">
          <div class="erp-form__title f16">计费信息</div>

          <el-table :data="lumpDtos" border class="egrid erp-table__list erp-view__table" style="margin-top: 11px">
            <el-table-column align="center" class-name="no-pd ">
              <template v-slot:header>
                <span class="erp-req">自支付尾款之日起至甲方付款提货之日止</span>
              </template>
              <template v-slot="scope">
                {{ scope.row.label }}
              </template>
            </el-table-column>
            <el-table-column align="center" class-name="no-pd">
              <template v-slot:header>
                <span class="erp-req">服务费(元/公斤)</span>
              </template>
              <template v-slot="scope">
                <el-input v-model="scope.row.fee" placeholder :maxlength="5" @blur="jrRateFormat($event, scope.row, 'fee')"></el-input>
              </template>
            </el-table-column>
          </el-table>
          <div class="erp-form__title f16" style="margin-top: 30px" v-if="detailData.bagOtherFeeList && detailData.bagOtherFeeList.length > 0">
            其他费用 (包干价）
          </div>
          <el-table
            class="egrid erp-table__list erp-view__table margin__top"
            v-if="detailData.bagOtherFeeList.length > 0"
            style="margin-top: 11px"
            :data="detailData.bagOtherFeeList"
            border
          >
            <el-table-column label="费用名称" align="center">
              <template v-slot="scope">
                <span>{{ filterName(scope.row.feeName) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="收费标准" prop="feeStandard" align="center">
              <template v-slot="scope">
                <span>{{ filterName(scope.row.feeStandard) }}元/柜</span>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { httpPost } from '@/api';
import billingType from './billingType';
import { errorTip, successTip } from '@/utils/publicMethods';
export default {
  components: {
    billingType,
  },
  props: {
    limtEdit: [Boolean],
    data: {
      // 获取传递过来的详情
      type: [Object],
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      formData: {
        type: 1, // 1，按天计费 1.按天计费 2.包干价 3.按天计费(旧)(展示) ,
      },
      lumpDtos: [],
      billingTypeData: [
        {
          label: '按天计费',
          value: 1,
        },
        {
          label: '包干价',
          value: 2,
        },
        {
          label: '按天计费(旧)',
          value: 3,
        },
      ],
      detailData: {},
      contractId: null,
      businessId: null,
      orderId: null,
    };
  },
  created() {
    this.detailData = this.data.settleTypeVo;
    console.log('deta', this.detailData);
    const lumpVos = [
      {
        label: '1-30天',
        configMin: 1,
        configMax: 30,
        fee: null,
        contractId: this.data.contractId,
        orderId: this.data.orderId,
        id: null,
        businessId: this.data.baseBusinessId,
      },
      {
        label: '1-45天',
        configMin: 31,
        configMax: 45,
        fee: null,
        contractId: this.data.contractId,
        orderId: this.data.orderId,
        id: null,
        businessId: this.data.baseBusinessId,
      },
      {
        label: '1-60天',
        configMin: 46,
        configMax: 60,
        fee: null,
        contractId: this.data.contractId,
        orderId: this.data.orderId,
        id: null,
        businessId: this.data.baseBusinessId,
      },
      {
        label: '1-75天',
        configMin: 61,
        configMax: 75,
        fee: null,
        contractId: this.data.contractId,
        orderId: this.data.orderId,
        id: null,
        businessId: this.data.baseBusinessId,
      },
      {
        label: '1-90天',
        configMin: 76,
        configMax: 90,
        fee: null,
        contractId: this.data.contractId,
        orderId: this.data.orderId,
        id: null,
        businessId: this.data.baseBusinessId,
      },
    ];
    this.detailData.lumpVos.forEach((v) => {
      if (v.configMin >= 1 && v.configMax <= 30) {
        lumpVos[0].fee = v.fee;
        lumpVos[0].id = v.id ? v.id : null;
        lumpVos[0].contractId = v.contractId ? v.contractId : this.data.contractId;
        lumpVos[0].businessId = v.businessId ? v.businessId : this.data.baseBusinessId;
        lumpVos[0].orderId = v.orderId ? v.orderId : this.data.orderId;
      }
      if (v.configMin >= 31 && v.configMax <= 45) {
        lumpVos[1].fee = v.fee;
        lumpVos[1].id = v.id ? v.id : null;
        lumpVos[1].contractId = v.contractId ? v.contractId : this.data.contractId;
        lumpVos[1].businessId = v.businessId ? v.businessId : this.data.baseBusinessId;
        lumpVos[1].orderId = v.orderId ? v.orderId : this.data.orderId;
      }
      if (v.configMin >= 46 && v.configMax <= 60) {
        lumpVos[2].fee = v.fee;
        lumpVos[2].id = v.id ? v.id : null;
        lumpVos[2].contractId = v.contractId ? v.contractId : this.data.contractId;
        lumpVos[2].businessId = v.businessId ? v.businessId : this.data.baseBusinessId;
        lumpVos[2].orderId = v.orderId ? v.orderId : this.data.orderId;
      }
      if (v.configMin >= 61 && v.configMax <= 75) {
        lumpVos[3].fee = v.fee;
        lumpVos[3].id = v.id ? v.id : null;
        lumpVos[3].contractId = v.contractId ? v.contractId : this.data.contractId;
        lumpVos[3].businessId = v.businessId ? v.businessId : this.data.baseBusinessId;
        lumpVos[3].orderId = v.orderId ? v.orderId : this.data.orderId;
      }
      if (v.configMin >= 76 && v.configMax <= 90) {
        lumpVos[4].fee = v.fee;
        lumpVos[4].id = v.id ? v.id : null;
        lumpVos[4].contractId = v.contractId ? v.contractId : this.data.contractId;
        lumpVos[4].businessId = v.businessId ? v.businessId : this.data.baseBusinessId;
        lumpVos[4].orderId = v.orderId ? v.orderId : this.data.orderId;
      }
    });
    this.lumpDtos = lumpVos;
    this.initOptions();
    this.contractId = this.data.contractId;
    this.businessId = this.data.baseBusinessId;
    this.orderId = this.data.orderId;
  },
  methods: {
    initOptions() {
      const { isBag, isDay, settleType } = this.detailData;
      this.formData.type = settleType;
      if (isBag && isDay) {
        this.billingTypeData = [
          {
            label: '按天计费',
            value: 1,
          },
          {
            label: '包干价',
            value: 2,
          },
          {
            label: '按天计费(旧)',
            value: 3,
          },
        ];
      }
      if (isBag && !isDay) {
        this.billingTypeData = [
          {
            label: '包干价',
            value: 2,
          },
        ];
      }
      if (!isBag && isDay) {
        this.billingTypeData = [
          {
            label: '按天计费',
            value: 1,
          },
          {
            label: '按天计费(旧)',
            value: 3,
          },
        ];
      }
    },
    addXhfwJrTable() {
      if (this.lumpDtos.length < 20) {
        const data = {
          configMin: null,
          configMax: null,
          fee: null,
          orderId: this.orderId,
          businessId: this.businessId,
          contractId: this.contractId,
        };
        this.lumpDtos.push(data);
      }
    },
    deleteXhfwJrTable(index) {
      this.lumpDtos.splice(index, 1);
    },
    jrDayFormat(e, item, name) {
      let value = e.target.value;
      value = value.replace(/[^\d]/g, ''); // 清除“数字以外的字符
      value = Number(value);
      if (value === 0) {
        value = null;
      }
      item[name] = value;
    },
    testJrRules() {
      const data = this.lumpDtos;
      if (data.length > 0) {
        let status = true;
        if (data.length === 0) {
          // 一行数据都没有则提示
          errorTip('请填写计费信息');
          status = false;
          return false;
        }
        if (data.length === 1) {
          // 只有一行的情况
          const item = data[0];
          if (item.configMin !== 1) {
            errorTip('第一行最小天数必须是1');
            status = false;
            return false;
          }
          if (!item.configMin) {
            errorTip('请填写最小天数');
            status = false;
            return false;
          }
          if (!item.configMax) {
            // 判断最小天数是否填写
            errorTip('请填写最大天数');
            status = false;
            return false;
          }
          if (!item.fee) {
            errorTip('请填写服务费(元/公斤)');
            status = false;
            return false;
          }
          if (item.configMin >= item.configMax) {
            // 如果最小天数大于最大天数
            errorTip('最小天数不能大于等于最大天数');
            status = false;
            return false;
          }
        }
        if (data.length > 1) {
          // 多行校验规则
          if (data[0].configMin !== 1) {
            // 多行 第一行必须为1
            errorTip('第一行最小值必须是1');
            status = false;
            return false;
          }

          for (let i = 0; i < data.length; i++) {
            const item = data[i];
            const lastItem = data[i - 1]; // 上一行的对象
            if (!item.configMin) {
              // 判断最小天数是否填写
              errorTip('请填写最小天数');
              status = false;
              break;
            }
            if (!item.fee) {
              // 判断是否填写金融服务费
              errorTip('请填写服务费(元/公斤)');
              status = false;
              break;
            }
            // 判断是不是最后一行
            if (!item.configMax) {
              // 判断最小天数是否填写

              errorTip('请填写最大天数');
              status = false;
              break;
            }
            if (item.configMin >= item.configMax) {
              // 如果最小天数大于最大天数
              errorTip('最小天数不能大于等于最大天数');
              status = false;
              break;
            }
            if (i >= 1) {
              // 从第二行开始判断
              if (item.configMin !== Number(lastItem.configMax) + 1) {
                errorTip('计费信息每行的最小值必须是上一行最大值加1');
                status = false;
                break;
              }
            }
          }
        }
        return status;
      } else {
        errorTip('请填写计费信息');
        return false;
      }
    },
    saveFormData() {
      if (this.formData.type === 2) {
        const testJrRules = this.testJrRules();
        if (testJrRules) {
          const params = {
            lumpDtos: this.lumpDtos,
            orderId: this.data.orderId,
            settleType: this.formData.type,
            dayOtherFeeList: this.detailData.dayOtherFeeList ? this.detailData.dayOtherFeeList : [],
            bagOtherFeeList: this.detailData.bagOtherFeeList ? this.detailData.bagOtherFeeList : [],
            version: this.detailData.version,
          };
          this.submit(params);
        }
      } else {
        const dayRules = this.$refs.billingType.validForm();
        if (dayRules) {
          const params = {
            agencyFeeList: dayRules.agencyFeeList, // 代理费
            cgFeeList: dayRules.cgFeeList, // 代采费
            insuranceRate: dayRules.insuranceRate,
            interestRateConfigList: dayRules.interestRateConfigList,
            lumpDtos: dayRules.lumpDtos ? dayRules.lumpDtos : [],
            orderId: this.data.orderId,
            dayOtherFeeList: this.detailData.dayOtherFeeList ? this.detailData.dayOtherFeeList : [],
            bagOtherFeeList: this.detailData.bagOtherFeeList ? this.detailData.bagOtherFeeList : [],
            settleType: this.formData.type,
            version: this.detailData.version,
          };
          this.submit(params);
        } else {
          errorTip('请将输入框填写完整');
        }
      }
    },
    async submit(data) {
      const res = await httpPost('/malicrm/order/editSettleType', data);
      if (res.code === 200) {
        successTip('保存成功');
        this.$emit('back', 1, true);
      } else {
        errorTip(res.message);
      }
    },
    editBaseInfo() {
      // 通知tab页切换显示 编辑页面
      this.$emit('edit', 6);
    },
    back() {
      // 返回详情页
      this.$emit('back', 6);
    },
    jrRateFormat(e, item, name) {
      let value = e.target.value;
      value = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // eslint-disable-line
      if (value.indexOf('.') < 0 && value !== '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (value.substr(0, 1) === 0) {
          value = value.substr(1);
        }
      }
      if (value.lastIndexOf('.') === value.length - 1) {
        value = value.replace('.', '');
      }
      if (value > 99.99) {
        // 超过阈值控制为最大值
        const index = value.indexOf('.');
        if (index > -1) {
          const intStr = value.substr(0, index - 1);
          const dotStr = value.substr(index);
          value = intStr + dotStr;
        } else {
          value = value.substr(0, 2);
        }
      }
      if (value === '' || value === null) {
        value = '';
      } else {
        value = Number(value);
      }
      item[name] = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.order-title__bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 11px;
}
.count-value {
  font-size: 14px;
  color: #303133;
}
.count-name {
  font-size: 14px;
  color: #606266;
}
.name-close {
  position: absolute;
  cursor: pointer;
  right: 16px;
  top: 9px;
  color: #c0c4cc;
}
</style>
