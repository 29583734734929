<template>
  <div class="order-base__warp" style="padding-bottom: 50px">
    <div class="erp-form-bar bar-sticky">
      <div class="erp-default__btn" @click="back">返回</div>
    </div>
    <div class="flex-warp">
      <div class="device-ios">
        <div class="device-inner">
          <div class="phone-warp">
            <div class="wx-header">
              <div class=""></div>
              <div class="header-title">马力冻品绿码</div>
              <div class="wx-icon">
                <img src="../../../../../assets/images/prview/wx-icon.png" alt="" />
              </div>
            </div>
            <miniPrview :id="data.orderId" @change="getFiles" @showChain="getChainCode" :showType="showType"></miniPrview>
            <!-- 多文件查看 -->
            <div class="files-scroll__warp" v-if="visable">
              <div class="mask" @click="visable = false"></div>
              <div style="background: #fff">
                <div class="scroll-header">
                  <div class="scroll-header__name">文件列表</div>
                  <div @click="visable = false" class="scroll-close">
                    <i class="el-icon-close"></i>
                  </div>
                </div>
                <div class="warp__inner">
                  <div v-for="(item, index) in fileItems" :key="index" @click="$showFiles(item.name, item.url)" class="blue-file">
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </div>
            <chainView :aliChainData="hashCode" @back="chainVisble = false" v-if="chainVisble"></chainView>
          </div>
        </div>
      </div>
      <div>
        <div style="font-size: 16px; color: #333; margin-bottom: 20px">
          {{ statusInfo }}
        </div>
        <div class="erp-primary__btn" style="width: 100px" @click="finishOrder">
          {{ showType === 1 ? '关闭显示' : '开启显示' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import miniPrview from './miniPrview';
import chainView from './chainView';
import { ElLoading } from 'element-plus';
import { erpConfirm, getRouterParams } from '@/utils/publicMethods';
import { httpPost } from '@/api';
export default {
  components: {
    miniPrview,
    chainView,
  },
  props: {
    data: {
      // 获取传递过来的详情
      type: [Object],
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      showType: 2,
      fileItems: [],
      visable: false,
      hashCode: null,
      chainVisble: false,
      isAjaxLoading: false,
    };
  },
  mounted() {
    this.getinit();
  },
  computed: {
    statusInfo() {
      return this.showType === 2 ? '显示状态：未在小程序显示' : '显示状态：已在小程序显示';
    },
  },
  methods: {
    getChainCode(data) {
      this.hashCode = data;
      this.chainVisble = true;
    },
    getFiles(item) {
      // 获取多文件列表
      this.fileItems = [];
      this.fileItems = item;
      this.visable = true;
    },
    getinit() {
      this.showType = this.data.showType;
    },
    finishOrder() {
      const str = this.showType === 2 ? '是否打开小程序显示' : '是否关闭小程序显示';
      erpConfirm(str).then(async () => {
        const loadingInstance = ElLoading.service({
          text: '正在操作，请勿进行其他操作',
        });
        if (!this.isAjaxLoading) {
          this.isAjaxLoading = true;
          const res = await httpPost('/malicrm/order/saveWechatOrderInfo', {
            orderId: this.data.orderId,
            showType: this.showType === 2 ? 1 : 2,
          });
          if (res.code === 200) {
            this.showType = this.showType === 2 ? 1 : 2;
            this.successTip('操作成功');
            loadingInstance.close();
            this.isAjaxLoading = false;
          } else {
            this.errorTip(res.message);
            this.isAjaxLoading = false;
            loadingInstance.close();
          }
        }
      });
    },
    back() {
      const { orderId, name, version } = getRouterParams('OrderDetail');
      this.$router.push({
        name: name,
        params: {
          id: orderId,
          version: version,
        },
      });
    },
    editBaseInfo() {
      // 通知tab页切换显示 编辑页面
      this.$emit('edit', 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.wx-header {
  background: #fff;
  height: 44px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px;
  position: sticky;
  top: 0;
  z-index: 89;
  width: 100%;
  .el-icon-arrow-left {
    font-size: 25px;
    color: #333;
    cursor: pointer;
  }
  .header-title {
    margin-left: 87px;
    font-size: 18px;
    color: #333;
    font-weight: bold;
  }
}
.wx-icon {
  width: 84px;
  height: 28px;
  img {
    width: 84px;
    height: 28px;
  }
}
.order-title__bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 11px;
}
.count-value {
  font-size: 14px;
  color: #303133;
}
.count-name {
  font-size: 14px;
  color: #606266;
}
.phone-warp {
  width: 100%;
  height: 628px;
  overflow: auto;
  position: relative;
  &::-webkit-scrollbar {
    width: 4px;
    height: 10px;
    background-color: #eff1f6;
  }
  &::-webkit-resizer {
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #d9e1ec;
  }
}
.flex-warp {
  display: flex;
}
.not-show {
  font-size: 16px;
  color: #333;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 750px;
  line-height: 750px;
}
.files-scroll__warp {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0px;
  height: 100%;

  z-index: 500;
  display: flex;
  flex-direction: column;
  .mask {
    flex: 1;
    width: 100%;
    background: rgba($color: #000000, $alpha: 0.3);
  }
}
.blue-file {
  color: #1777ff;
  font-size: 15px;
  position: relative;
  cursor: pointer;

  padding: 10px 15px;
}
.warp__inner {
  overflow: auto;
}
.scroll-header {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  position: relative;
}
.scroll-header__name {
  font-weight: bold;
  color: #333;
  font-size: 16px;
}
.scroll-close {
  font-size: 20px;
  justify-self: end;
  margin-left: auto;
  position: absolute;
  right: 0;
  top: 10px;
}
.device-ios {
  width: 420px;
  height: 750px;
  background: #111;
  border-radius: 55px;
  box-shadow: 0px 0px 0px 2px #aaa;
  padding: 60px 20px;
  position: relative;
  margin-right: 80px;
  margin-left: 20px;
}

.device-ios:before {
  content: '';

  width: 60px;

  height: 10px;

  border-radius: 10px;

  position: absolute;

  left: 50%;

  margin-left: -30px;

  background: #333;

  top: 30px;
}

.device-ios:after {
  content: '';

  position: absolute;

  width: 50px;

  height: 50px;

  left: 50%;

  margin-left: -25px;

  bottom: 7px;

  border-radius: 100%;

  box-sizing: border-box;

  border: 5px solid #333;
}

.device-inner {
  background-color: #fff;
  height: 100%;
}
</style>
